import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer'

const CourierServiceAlappuzha = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title> Best Courier Service in Alappuzha | Velocity Express</title>
                <meta name="description" content="  Enjoy the best courier service in Alappuzha with Velocity Express. Fast, secure, and reliable deliveries across 150+ countries." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 200px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}
        .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        `}
            </style>

            <>
                <section className="call-to-action style-two" style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div class="col-md-9">
                                        <h1 class="mb-2">Best Courier Service in Alappuzha</h1>
                                        <p class="text-light">Velocity Express is a trusted courier service in Alappuzha, known for delivering secure, on-time shipments across domestic and international destinations. As a leading logistics company, Velocity Express combines expertise and advanced technology to ensure swift, precise handling of deliveries. Our fully automated processes enhance efficiency and accuracy, making each shipment reliable.
                                        </p>
                                    </div>

                                    <div className="col-md-3">
                                        <EnquiryForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{ marginTop: '-16px', zIndex: 99, background: '#fff', position: 'relative', padding: '12px 26px', borderRadius: '6px', boxShadow: '0px 2px 5px #d3d3d3' }}
                                    data-wow-delay="600ms"
                                >
                                    <li> <a href="#domestic-courier"><span> Domestic Courier </span></a></li>
                                    <li> <a href="#international-courier"><span> International Courier</span></a></li>
                                    <li> <a href="#air-freight"><span> Air Freight </span></a></li>
                                    <li> <a href="#ocean-freight"><span> Ocean Freight </span></a></li>
                                    <li> <a href="#ecommerce-shipment"><span> E-Commerce Shipment </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">

                                <p>Our network covers more than 150 countries. We have a skilled team and a large agent network. This ensures quick deliveries and great convenience for both businesses and individuals. Velocity Express offers specialized services, including temperature-controlled and time-sensitive deliveries, to handle packages with the utmost care.</p>
                                <p>We keep you informed on the latest industry trends, reflecting our commitment to meeting evolving logistics needs. Choose Velocity Express for logistics that go beyond speed—where seamless, secure service accelerates your business and delivers your parcels with precision, every time.</p>

                                <hr />

                                <div className="row py-4">
                                    <h2>Why Velocity Express Is Your Go-To Courier Delivery Service in Alappuzha</h2>
                                    <p>Velocity Express is a top choice for courier services in Alappuzha, providing fast, secure, and reliable delivery options for both domestic and international shipments. With advanced technology and a highly skilled team, Velocity Express ensures every delivery is handled with precision and care.</p>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Affordable Courier Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express offers competitive pricing, making quality courier service accessible without compromising on service standards.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">Door-to-Door Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Enjoy the convenience of seamless door-to-door service for your parcels, with reliable pickup and delivery, ensuring a hassle-free experience.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Secure Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Security is our top priority. Our fully automated systems and dedicated team make sure your shipments are handled with the utmost care, keeping your items safe from pickup to delivery.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Wide Network</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>With a network spanning over 150 countries, Velocity Express provides comprehensive coverage and the ability to reach distant destinations quickly and efficiently.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                                            <p className="pTagtitle mt-2 mb-1">Real-Time Tracking</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Our real-time tracking system gives you peace of mind by allowing you to monitor your shipment's journey at every stage.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                                            <p className="pTagtitle mt-2 mb-1">24/7 Customer Support</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express’s round-the-clock customer support team is always available to address inquiries and assist you with any questions regarding your shipments.</p>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2>Fast and Reliable Courier Service in Alappuzha</h2>
                                            <p>Alappuzha, also known as Alleppey, is a city located along the Laccadive Sea in the southern Indian state of Kerala. With a growing economy, the need for fast and reliable courier services in Alappuzha is more important than ever. Whether you need to send important documents, parcels, or other goods, efficient courier services are always in demand.</p>

                                            <p>At Velocity Express, we recognize the vital role of parcel delivery services in Alappuzha. Our domestic courier options are designed to meet the city's varied shipping needs. With our door-to-door service, we ensure your packages and documents reach their destination securely and on time.</p>

                                            <p>If you’re searching for "courier services near me" in Alappuzha, your search ends here. We are your trusted local courier experts, dedicated to providing smooth and convenient courier solutions. By choosing Velocity Express, you select fast, hassle-free courier services in Alappuzha, connecting you effortlessly with the rest of the country.</p>
                                            <p>Velocity provides courier services in multiple cities throughout Kerala and other locations across India—explore <a href="https://velexp.com/our-presence">our network!</a></p>
                                            <hr />
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h3 className="mb-2">Enjoy Our Courier Service Across Major Cities in Kerala</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Pathanamthitta</li>
                                                            <li>Courier Service in Kollam</li>
                                                            <li>Courier Service in Thiruvananthapuram</li>
                                                            <li>Courier Service in Wayanad</li>
                                                            <li>Courier Service in Kozhikode</li>
                                                            <li>Courier Service in Malappuram</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <h2 className="mt-4">Courier Services at Velocity Express</h2>
                                                <div className="feature-block col-lg-6 col-md-4 col-sm-12" id="domestic-courier" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">01</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Domestic Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: '0.83em' }}>The demand for local courier services is increasing every day. This rise is due to the rapid growth of e-commerce in the country. Understanding the needs of this sector is essential to meet various requirements and ensure a smooth delivery process.</p>
                                                                <p className="text" style={{ fontSize: '0.83em' }}>In addition to the e-commerce sector, individuals are increasingly demanding domestic courier services. Therefore, Velocity Express offers high-quality courier solutions to meet the growing needs of both individuals and organizations. Start shipping with our <a href="https://velexp.com/domestic">domestic courier service</a> today.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-6 col-md-4 col-sm-12" id="international-courier" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">02</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>International Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express is the perfect choice for a reliable and cost-effective international courier service in Alappuzha. We are committed to providing the best service to meet all your international shipping needs. Whether you need to send urgent medicines, important documents, or business consignments, you can count on us.</p>
                                                                <p className="text" style={{ fontSize: '0.83em' }}>With years of experience serving a diverse range of clients, we have built a vast network that spans over 150 countries. This extensive reach ensures that you can send parcels abroad conveniently and confidently with us. Initiate your booking with our <a href="https://velexp.com/international">international courier service.</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-light">
                    <div className="container pt-5">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">Freight Services at Velocity Express</h2>

                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="air-freight" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Air Freight Service</h3>
                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Velocity Express offers reliable air freight services that ensure safe and efficient transport for your time-sensitive and high-value cargo. Our air services are tailored to meet your specific needs, utilizing our controlled freighter network and strategic partnerships to guarantee quick and efficient delivery of your goods across the globe.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="ocean-freight" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Ocean Freight Service</h3>
                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Velocity Express provides a flexible range of sea freight options to meet your shipping needs worldwide. We offer both Less-Than-Container Load (LCL) and Full-Container Load (FCL) services, ensuring cost-effective and reliable transport solutions for businesses of all sizes. With a global network and local expertise, we handle your shipments efficiently and securely, connecting you to destinations across the globe.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0" id="ecommerce-shipment" style={{ scrollMarginTop: '140px' }}>
                    <div className="container pt-5 pb-2">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <h2>E-Commerce Shipment Delivery</h2>
                                <p>
                                    Velocity provides reliable e-commerce shipment delivery solutions within India and internationally. Our efficient shipping services ensure that your products reach their destination quickly and safely, whether you're shipping items globally or receiving products from overseas.
                                </p>

                                <p>
                                    With a simple online process, Velocity makes it easy to order and get your items delivered right to your doorstep. We prioritize timely delivery, so your orders arrive within days, wherever you are. Choose Velocity for a seamless e-commerce shipping experience!
                                </p>

                                <hr />

                                <h2>Bulk Shipment Delivery</h2>
                                <p>
                                    Our bulk shipment delivery service offers a cost-effective way to transport large quantities of goods for businesses of all sizes. Our bulk shipping solutions are tailored to meet diverse business needs, providing flexibility and reliability.
                                </p>
                                <p>
                                    With our extensive network and expertise, we ensure that your bulk shipments are delivered safely and on time, along with seamless tracking for your peace of mind. Trust Velocity for hassle-free bulk shipment delivery to help your business grow!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div>
                                <h2>Frequently Asked Questions</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            Which is the best courier service in Alappuzha?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    The best courier service in Alappuzha is Velocity Express, known for its fast and reliable deliveries, covering both domestic and international destinations.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            Does Velocity Express offer door-to-door service?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express offers seamless door-to-door service, ensuring convenient pickup and delivery for all your parcels.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            What types of courier services does Velocity Express provide?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express provides domestic, international, and e-commerce shipment delivery, air and sea freight services, and bulk shipment delivery.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Is there customer support available for my courier inquiries?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, our customer support team is available 24/7 to assist you with any inquiries or issues regarding your shipments.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div>
    )
}

export default CourierServiceAlappuzha
