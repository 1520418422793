import React,{useState} from 'react';
import axios from 'axios';

export default function Footer() {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // For loader visibility
    const [successMessage, setSuccessMessage] = useState("");
  
    const clearErrorAfterTimeout = (field) => {
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, [field]: "" }));
      }, 3000); // Clears the error after 3 seconds
    };
  
    const sendNewsLetter = async (event) => {
      event.preventDefault(); // Prevent default form submission
      let isValid = true; // Initialize validation flag
      setSuccessMessage(""); // Clear any success messages from previous attempts
      setIsLoading(true); // Show loader when submitting
  
      if (!email) {
        // Show "Email cannot be blank" inside the input as a placeholder
        setErrors((prev) => ({ ...prev, email: "Email is mandatory" }));
        clearErrorAfterTimeout('email');
        setIsLoading(false);
        isValid = false;
      } else if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)) {
        // Show "Invalid email format" below the input
        setErrors((prev) => ({ ...prev, email: "invalid" }));
        setIsLoading(false);
        clearErrorAfterTimeout('email');
        isValid = false;
      }
  
      if (isValid) {
        try {
          const response = await axios.post('http://192.168.1.222:8000/send-newsletter', { email });
  
          if (response.data.status === 200) {
            setTimeout(() => {
              setIsLoading(false);
              setSuccessMessage("Thank you for subscribing to our newsletter!");
              setEmail(""); // Clear email input after successful submission
              setTimeout(() => {
                setSuccessMessage(""); // Clear the success message after 2 seconds
              }, 2000);
            }, 2000);
          } else if (response.data.status === 400) {
            setTimeout(() => {
              setIsLoading(false);
              setSuccessMessage("Already subscribed!");
              setEmail(""); // Clear email input if already subscribed
              setTimeout(() => {
                setSuccessMessage(""); // Clear the success message after 2 seconds
              }, 2000);
            }, 2000);
          } else {
            setIsLoading(false); // Ensure loading is stopped for any other cases
          }
        } catch (error) {
          console.error("Error submitting the form:", error);
          setIsLoading(false);
        }
      }
    };
  return (
    <footer className="main-footer">
        <div className="bg-image" style={{backgroundImage: 'url(/images/background/5.jpg)' }}></div>
        <div className="anim-icons">
            <span className="icon icon-plane-3 bounce-x"></span>
        </div>

        <div className="contacts-outer">
            <div className="auto-container">
                <div className="row">
                    <div className="contact-info-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight">
                        <div className="inner-box">
                            <div className="icon-box"><i className="icon flaticon-international-shipping-2"></i></div>
                            <h4 className="title">Address</h4>
                            <div className="text">GF/ SF - Tejas, Opp Hotel Prasadalay, Shukrawar Peth - 411002</div>
                        </div>
                    </div>

                    
                    <div className="contact-info-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="300ms">
                        <div className="inner-box">
                            <div className="icon-box"><i className="icon flaticon-stock-1"></i></div>
                            <h4 className="title">Contact</h4>
                            <div className="text">
                                <a href="mailto:enquiry@velexp.com">enquiry@velexp.com</a><br />
                                <a href="tel:+92(8800)48720">+91 88929 17544</a>
                            </div>
                        </div>
                    </div>

                   
                    <div className="contact-info-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="600ms">
                        <div className="inner-box">
                            <div className="icon-box"><i className="icon flaticon-24-hours-2"></i></div>
                            <h4 className="title">Timing</h4>
                            <div className="text">Mon - Sat: 8 am - 5 pm,</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="widgets-section">
            <div className="auto-container">
                <div className="row">
                    <div className="footer-column col-xl-3 col-lg-12 col-md-6 col-sm-12">
                        <div className="footer-widget about-widget">
                            <div className="logo">
                                <a href="/"><img src="/imgs/vellocity-express-mobile.png" width="200" alt="Velocity Express - Accelerate Your Business" /></a>
                            </div>
                            <div className="text">Founded in 2010, Velocity Express has become India’s premier provider of Air Freight Services. We specialize in global, door-to-door courier and parcel delivery, serving over 200 countries from India. </div>
                            <a href="/about-us" className="theme-btn btn-style-one hvr-light small"><span className="btn-title">About</span></a>
                        </div>
                    </div>

                    
                    <div className="footer-column col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <div className="footer-widget">
                            <h3 className="widget-title">Services</h3>
                            <ul className="user-links">
                                <li><a href="/domestic">Domestic</a></li>
                                <li><a href="/international">International</a></li>
                            </ul>
                        </div>
                    </div>

                   
                    <div className="footer-column col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="footer-widget gallery-widget">
                            <h3 className="widget-title">Our Presence</h3>
                            <div className="widget-content">
                                <div className="outer clearfix">
                                    <figure className="image">
                                        <a href="/our-presence"><img src="/imgs/bangalore.png" alt="bangalore" /></a>
                                    </figure>

                                    <figure className="image">
                                        <a href="/our-presence"><img src="/imgs/delhi.png" alt="delhi" /></a>
                                    </figure>

                                    <figure className="image">
                                        <a href="/our-presence"><img src="/imgs/mumbai.png" alt="mumbai" /></a>
                                    </figure>

                                    <figure className="image">
                                        <a href="/our-presence"><img src="/imgs/hyderabad.png" alt="" /></a>
                                    </figure>

                                    <figure className="image">
                                        <a href="/our-presence"><img src="/imgs/pune.png" alt="" /></a>
                                    </figure>

                                    <figure className="image">
                                        <a href="#"><img src="/imgs/surat.png" alt="" /></a>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="footer-column col-xl-3 col-lg-5 col-md-6 col-sm-12">
                        <div className="footer-widget">
                            <h3 className="widget-title">Newsletter</h3>
                            <div className="widget-content">
                                <div className="subscribe-form">
                                    <div className="text">Subscribe our newsletter to get our latest update & news</div>
                                   
                                    <div className="form-group">
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder={errors.email || "Enter your email"}
                                            />
                                           {errors.email === "invalid" && (
                                                <div className=" mt-1" style={{ color: "white" }}>Enter valid email</div>
                                            )}
                                            <button
                                                type="button"
                                                className="theme-btn btn-style-one"
                                                onClick={sendNewsLetter}
                                                disabled={isLoading}
                                            >
                                                <span className="btn-title">
                                                    {isLoading ? (
                                                        <img src="/imgs/loader_1.gif" alt="loading" />
                                                    ) : (
                                                        <i className="fa fa-paper-plane"></i>
                                                    )}
                                                </span>
                                            </button>
                                            {successMessage && (
                                                <div className="mt-2" style={{ color: "white" }}>
                                                    {successMessage}
                                                </div>
                                            )}
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-bottom">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="copyright-text">
                            <p>&copy; Copyright 2024 by Velocity Express </p>
                        </div>

                        <ul className="social-icon-two">
                            <li>
                                <a href="#"><i className="fab fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </li>
                            <li>
                                <a href="#"><i className="fab fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
        </div>
    </footer>
  );
}