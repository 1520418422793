import React from 'react'

const ServicesForm = () => {
    return (
        <>
            <div className="contact-form wow fadeInLeft">
                {/* Contact Form */}
                <form method="post" id="contact-form">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label>Your Name</label>
                            <input type="text" name="full_name" id="name" placeholder="" required/>
                        </div>

                        <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                            <label>Your Email</label>
                            <input type="text"  name="Email" id="email"  placeholder=""  required />
                        </div>

                        <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                            <label>Phone No</label>
                            <input type="text" name="Phone"  id="phone"  placeholder=""  required  maxLength="10"
                            />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <button
                                className="theme-btn btn-style-two hvr-light"  type="submit" name="submit-form">
                                <span className="btn-title">Submit Request</span>
                            </button>
                        </div>

                        <div className="servicesMsg col-lg-12 col-md-12 col-sm-12"></div>
                    </div>
                </form>
            </div>


        </>
    )
}

export default ServicesForm
