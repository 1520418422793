import React from 'react';
import { useLocation } from 'react-router-dom';
import IndexHeader from './IndexHeader';


const HeaderTrack = () => {
  const { state } = useLocation();
  const trackingData = state?.trackingData?.details?.shipment;

  if (!trackingData) {
    return <div>No tracking data available</div>;
  }

  const { Parent, Child } = trackingData;

  return (
    <>
      <IndexHeader />
      <section className="page-title">
        <div className="auto-container">
          <div className="title-outer">
            <h1 className="title">Track</h1>
            <ul className="page-breadcrumb">
              <li><a href="/">Home</a></li>
              <li>Track</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="featured-section inner-page sec-padd-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="time-traking-box p-3 px-5 border-radius border">
                <h4 className="sub-title">Real Time <span>Tracking</span></h4>
                <div className="tracking-number-search">
                  <div className="border p-3">
                    <strong>Tracking ID :</strong>
                    <input
                      type="text"
                      className="border py-1 px-2"
                      name="tawbno"
                      id="tawbno"
                      placeholder="Tracking ID number"
                      value={state?.trackingData?.awbno || ""}
                      readOnly
                    />
                    <input type="hidden" name="trackby" id="trackby" value="" disabled />
                    <button id="trackgo" className="border py-1 px-2">Track your shipment</button>
                  </div>
                  <div className="level-bar"></div>
                  <div className="tracking-text">
                    <h6>Activity Details</h6>
                    <hr />
                    <div className="atl-module atl-md-panel atl-module atl-cat-default">
                      <div className="atl-md-shipment-overview">
                        <div className="content--header mb-2">
                          <div className="panel-title">Shipment Summary</div>
                          <h3 className="header">
                            {state?.trackingData?.awbno
                              ? `Travel History for AWB No: ${state.trackingData.awbno}`
                              : 'Travel History'}
                          </h3>
                        </div>
                      </div>
                      <div className="custom-height">
                      <table className="table table-bordered mt10 table-responsive">
                          {Parent && Parent.length > 0 ? (
                            Parent.map((data, index) => {
                              // Ensure `Child` is available and filter for the last 'SPD' status
                              const lastDeliveredHistory = Child?.filter(
                                history => history.Statuscode === 'SPD'
                              ).slice(-1)[0];

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td><strong>Consignee:</strong></td>
                                    <td>{data.Consignee}</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Delivery Date:</strong></td>
                                    <td>
                                      {lastDeliveredHistory ? (
                                        <span>
                                          {lastDeliveredHistory.Statusdate} at {lastDeliveredHistory.Statustime}
                                        </span>
                                      ) : (
                                        <span>Not Delivered</span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="3" align="center">
                                      All shipment travel activity is displayed in local time for location
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="3">No shipment summary available</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className="status-updates">
                      <h5>Status Updates:</h5>
                      {Child && Child.length > 0 ? (
                        <table className="table table-bordered mt-3">
                          <thead>
                            <tr>
                              <th>Date/Time</th>
                              <th>Status</th>
                              <th>Location</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Child.map((history, index) => (
                              <tr key={index}>
                                <td>{history.Statusdate} {history.Statustime}</td>
                                <td>{history.Remarks}</td>
                                <td>{history.Location}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>No status updates available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderTrack;
