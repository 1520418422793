import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer'

const CourierServiceKottayam = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title>Best Courier Service in Kottayam - Velocity Express</title>
                <meta name="description" content="Velocity Express offers the best courier service in Kottayam, with fast, reliable delivery options across India and worldwide. Enjoy secure, timely shipping." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 430px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}`}
            </style>

            <>
                <section
                    className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Best Courier Service in Kottayam</h1>
                                        <p className="text-light">
                                            Courier services in Kottayam are essential for individuals and businesses seeking secure delivery options.
                                            Whether you’re sending packages locally or shipping goods internationally, choosing a reliable courier service
                                            can significantly impact your experience.
                                        </p>
                                    </div>

                                    <div className="col-md-3">
                                        <EnquiryForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{
                                        marginTop: '-16px',
                                        zIndex: 99,
                                        background: '#fff',
                                        position: 'relative',
                                        padding: '12px 26px',
                                        borderRadius: '6px',
                                        boxShadow: '0px 2px 5px #d3d3d3',
                                    }}
                                    data-wow-delay="600ms"
                                >
                                    <li>
                                        <a href="#domestic-courier">
                                            <span>Domestic Courier</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#international-courier">
                                            <span>International Courier</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#air-freight">
                                            <span>Air Freight</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ocean-freight">
                                            <span>Ocean Freight</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ecommerce-shipment">
                                            <span>E-Commerce Shipment</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>At Velocity Express, we offer fast and surefire delivery solutions. Our expert team and cutting-edge technology ensure that your shipments arrive on time. With coverage of over 19,000 pin codes across India, sending and receiving packages nationwide is a breeze. We also have an extensive network that spans more than 150 countries, making global shipping simple and efficient.</p>

                                <p>One of the standout features of our service is our commitment to efficiency. By using automated processes, we improve accuracy and make operations smoother. This ensures that every delivery is handled with care.</p>

                                <p>As e-commerce continues to grow, the need for reliable courier services has surged. You can rely on us to boost your business with our speedy and efficient delivery solutions designed to fit your needs. Features like temperature-controlled shipping and a user-friendly pickup app make sending packages hassle-free.</p>

                                <p>Stay updated with the latest industry trends through our regular insights, helping you navigate the logistics landscape with confidence.</p>
                                <hr />

                                <h2 className="mb-0">Why Velocity Express Is Your Top Courier Service in Kottayam</h2>
                                <p>Velocity Express is your trusted partner for reliable parcel delivery from Kottayam to destinations across India and worldwide. We cater to both individuals and businesses, and here’s why we are the preferred courier service:</p>

                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Affordable Courier Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Send parcels from Kottayam to locations across India and worldwide at affordable rates. Our competitive pricing makes shipping accessible to everyone.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">Door-to-Door Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Experience the convenience of our doorstep pickup and delivery service, eliminating the need to visit a drop-off location. We ensure your parcel arrives smoothly and on time.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Secure Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Your parcel's security is our priority. We take all necessary precautions to keep your shipment safe from pickup to delivery, earning your trust with our reliable handling.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Wide Network</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>With a vast network covering over 19,000 pin codes in India and more than 150 international countries, we guarantee timely delivery of your parcels.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                                            <p className="pTagtitle mt-2 mb-1">Real-Time Tracking</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Our easy-to-use tracking feature allows you to monitor your parcel’s journey in real-time, keeping you informed about its location and estimated arrival.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                                            <p className="pTagtitle mt-2 mb-1">24/7 Customer Support</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Our dedicated support team is available around the clock to assist you with any questions or concerns, ensuring a seamless shipping experience.</p>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2>Fast and Reliable Courier Service in Kottayam</h2>
                                            <p>Kottayam, known for its rich cultural heritage and bustling economy, requires efficient courier services to meet the demands of its residents and businesses. When you need to send couriers from Kottayam to any destination, you can count on Velocity Express. We provide door-to-door courier services to over 19,000 pin codes across India, ensuring your package arrives quickly and safely.</p>
                                            <p>Your shipment is handled by skilled professionals who prioritize its security throughout the delivery process. If you’re searching for reliable courier services nearby, our team is ready to meet your needs and transport your packages at your convenience.</p>
                                            <p>We strive to offer affordable delivery rates, making shipping accessible for all. You can easily get a transparent estimate by entering your details on our website, allowing you to plan your deliveries confidently.</p>
                                            <p>Velocity Express provides courier services throughout Kerala and in various cities across India—discover our <a href="https://velexp.com/our-presence">extensive reach!</a></p>
                                            <hr />
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h2 className="mb-2">Our Range of Courier Services</h2>
                                                    <h3>Areas We Serve</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Pathanamthitta</li>
                                                            <li>Courier Service in Kollam</li>
                                                            <li>Courier Service in Alappuzha</li>
                                                            <li>Courier Service in Thiruvananthapuram</li>
                                                            <li>Courier Service in Wayanad</li>
                                                            <li>Courier Service in Kozhikode</li>
                                                            <li>Courier Service in Malappuram</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h3>Serving Key Cities Across India</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Andhra Pradesh</li>
                                                            <li>Courier Service in Assam</li>
                                                            <li>Courier Service in Bihar</li>
                                                            <li>Courier Service in Chhattisgarh</li>
                                                            <li>Courier Service in Delhi</li>
                                                            <li>Courier Service in Gujarat</li>
                                                            <li>Courier Service in Daman and Diu</li>
                                                            <li>Courier Service in Haryana</li>
                                                            <li>Courier Service in Himachal Pradesh</li>
                                                            <li>Courier Service in Jammu and Kashmir</li>
                                                            <li>Courier Service in Jharkhand</li>
                                                            <li>Courier Service in Karnataka</li>
                                                            <li>Courier Service in Kerala</li>
                                                            <li>Courier Service in Madhya Pradesh</li>
                                                            <li>Courier Service in Maharashtra</li>
                                                            <li>Courier Service in Goa</li>
                                                            <li>Courier Service in Manipur</li>
                                                            <li>Courier Service in Mizoram</li>
                                                            <li>Courier Service in Nagaland</li>
                                                            <li>Courier Service in Sikkim</li>
                                                            <li>Courier Service in Tripura</li>
                                                            <li>Courier Service in Arunachal Pradesh</li>
                                                            <li>Courier Service in Meghalaya</li>
                                                            <li>Courier Service in Odisha</li>
                                                            <li>Courier Service in Chandigarh</li>
                                                            <li>Courier Service in Punjab</li>
                                                            <li>Courier Service in Rajasthan</li>
                                                            <li>Courier Service in Tamil Nadu</li>
                                                            <li>Courier Service in Telangana</li>
                                                            <li>Courier Service in Uttar Pradesh</li>
                                                            <li>Courier Service in Uttarakhand</li>
                                                            <li>Courier Service in West Bengal</li>
                                                            <li>Courier Service in Andaman and Nicobar Islands</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <h2>Courier Services at Velocity Express</h2>

                                    <div className="feature-block col-lg-6 col-md-4 col-sm-12" id="domestic-courier" style={{ scrollMarginTop: "140px" }}>
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                <span className="count">01</span>
                                            </div>
                                            <div className="content-box">
                                                <h3>Domestic Courier Service</h3>
                                                <div className="text">
                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                        As e-commerce thrives in the country, the demand for reliable domestic courier services has surged. This growth extends to individuals seeking secure delivery options. Velocity Express offers top-quality domestic shipping services tailored to personal and organizational needs, ensuring smooth delivery processes.
                                                    </p>
                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                        We serve a diverse clientele, from large corporations to individuals. Our services are designed with a clear understanding of the unique challenges faced by our customers. We pride ourselves on delivering quality solutions at competitive prices without compromising security.
                                                    </p>
                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                        Our extensive offerings cover everything from small documents to larger parcels, all supported by a dedicated customer service team ready to address any queries. Choose Velocity Express for your <a href="https://velexp.com/domestic">domestic courier</a> needs and experience a reliable service that prioritizes your satisfaction. Start shipping nationwide with us today!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature-block col-lg-6 col-md-4 col-sm-12" id="international-courier" style={{ scrollMarginTop: "140px" }}>
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                                                <span className="count">02</span>
                                            </div>
                                            <div className="content-box">
                                                <h3>International Courier Service</h3>
                                                <div className="text">
                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                        Send parcels overseas easily with Velocity Express. We offer reliable and speedy courier delivery to numerous countries around the globe. We aim to provide excellent quality and affordability, ensuring you receive top-notch service at reasonable rates. We focus on making every customer feel valued through personalized delivery options tailored to your needs.
                                                    </p>
                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                        With years of industry experience, Velocity Express has built a strong reputation for international courier services. Our impressive track record of timely deliveries reinforces our commitment to customer satisfaction. We prioritize long-lasting relationships, ensuring your experience with us is smooth from start to finish.
                                                    </p>
                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                        Our services cater to both individuals and businesses, making it easy to meet your international shipping needs. Whether sending personal items or important documents, rely on us for effective and cost-efficient solutions. Choose Velocity Express for your international shipping needs and discover why we are a leader in the field. Book your shipment today with our trusted <a href="https://velexp.com/international">international courier service!</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-light">
                    <div className="container pt-5">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">Freight Services at Velocity Express</h2>

                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="air-freight" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Air Freight Service</h3>
                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Velocity Express offers a trusted air freight service to expedite your shipments worldwide. We cater to a wide range of freight needs, from essential documents to specialized cargo, ensuring timely delivery.
                                                        </p>

                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Our strong network of agents and partnerships with top airlines allows us to provide tailored shipping solutions. We offer flexible delivery options, customs clearance, and comprehensive documentation support for a seamless experience. Plus, our tracking system keeps you informed with regular updates, helping to streamline arrival times. Choose Velocity Express for fast, efficient air freight services that prioritize your satisfaction.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="ocean-freight" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Ocean Freight Service</h3>
                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Our ocean freight service is designed for businesses looking to transport large volumes of cargo while managing costs effectively. We offer reliable shipping solutions connecting you to major container ports worldwide, ensuring your goods arrive safely.
                                                        </p>

                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Whether you need a Full Container Load (FCL) or Less than Container Load (LCL), our experienced team is dedicated to meeting your shipping needs. With our strong partnerships with leading shipping lines, we provide customized solutions focused on optimal transit times and flexible scheduling.
                                                        </p>

                                                        <p style={{ fontSize: '0.80em' }}>
                                                            Our advanced tracking systems allow you to monitor your shipments, giving you confidence that your cargo will arrive as planned. Choose Velocity Express for cost-effective and efficient ocean freight services that support your business goals.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>



                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="">
                                <h2>Frequently Asked Questions</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            What makes Velocity Express the best courier service in Kottayam?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express is known for its fast, reliable, and affordable delivery options. It serves over 19,000 pin codes in India and more than 150 countries worldwide.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            How can I track my shipment with Velocity Express?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can easily track your shipment in real time using our user-friendly tracking feature on our website, which keeps you updated on its location and estimated arrival.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Do you offer international shipping?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express provides international courier services, making it simple to send parcels to numerous countries around the world with a commitment to timely delivery.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            What is the process for scheduling a pickup?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can schedule a pickup through our easy-to-use app or website, where you can enter your details and choose a convenient time for us to collect your package.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            Is there customer support available if I have questions?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Absolutely! Our dedicated customer support team is available 24/7 to assist with any questions or concerns you may have regarding your shipments.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div>
    )
}

export default CourierServiceKottayam
