import EnquiryForm from './EnquiryForm'
import React, { useEffect , useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer';


const CourierServiceAhmedabad = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
    script.async = true;
    document.body.appendChild(script);

    // Initialize intlTelInput once the script is loaded
    script.onload = () => {
      const phoneInput = document.querySelector("#phone");
      if (phoneInput) {
        intlTelInput(phoneInput, {
          // Your options here to customize the behavior of the phone number input field
        });
      }
    };

    // Cleanup the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
    };

  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  // Function to toggle the active state of an accordion item
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
  };

  return (

    <div>
      <IndexHeader />
      <Helmet>
        <title> Reliable Courier Service in Ahmedabad | Velocity Express</title>
        <meta name="description" content="Looking for a reliable courier service in Ahmedabad? Velocity Express offers secure, timely deliveries for domestic and international shipments." />
        <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
      </Helmet>

      <style>{`
       .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 540px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}`}
      </style>

      <>
        <section
          className="call-to-action style-two"
          style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}
        >
          <div className="container-fluid">
            <div className="outer-box">
              <div className="container">
                <div className="row">
                  <div class="col-md-9">
                    <h1 class="mb-2">Reliable Courier Service in Ahmedabad</h1>
                    <p class="text-light">Residents of Ahmedabad can now send couriers swiftly and effortlessly anywhere in India and abroad with Velocity Express. Our reliable courier service in Ahmedabad eases the process of shipping goods. We not only deliver individual consignments but also bulk orders across India and abroad.</p>
                  </div>

                  <div className="col-md-3">
                    <EnquiryForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="auto-container">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="bg-light d-flex justify-content-between align-item-center wow fadeInRight"
                  style={{ marginTop: '-16px', zIndex: 99, background: '#fff', position: 'relative', padding: '12px 26px', borderRadius: '6px', boxShadow: '0px 2px 5px #d3d3d3' }}
                  data-wow-delay="600ms"
                >
                  <li><a href="#domestic-express"><span>Domestic Express</span></a></li>
                  <li><a href="#international-express"><span>International Express</span></a></li>
                  <li><a href="#e-commerce"><span>E-commerce</span></a></li>
                  <li><a href="#bulk-cargo"><span>Bulk Cargo</span></a></li>
                  <li><a href="#air-and-sea-freight"><span>Air and Sea Freight</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </section>


        <section className="about-section pt-3 pb-0">
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                <p>
                  We offer e-commerce deliveries in addition to domestic, hyperlocal, and international courier services. We
                  provide premium courier service at competitive rates. Hence, we are the preferred choice for sending parcels
                  overseas and within India.
                </p>

                <p>
                  Velocity Express has a wide network with access to 150 countries. More than 700 professionals work tirelessly
                  across 50 offices to offer the best service to our customers. We ensure safe and timely delivery be it a
                  commercial package or a personal item.
                </p>

                <hr />

                <h2>Velocity Express: Fast, Efficient Courier Services in Ahmedabad</h2>
                <p>
                  Quick, stress-free, and on-time delivery make Velocity Express the best courier service in Ahmedabad. Customers
                  will experience peace of mind when sending packages with Velocity Express. With more than 200 domestic teams and
                  100 international teams working hard, you can trust that your delivery is safe.
                </p>
                <p>
                  At Velocity Express, we keep shipping simple and reliable. Book online, track your package, and get updates along
                  the way, so you’re always informed. With responsive customer support and an IVR system, help is always close at
                  hand. Trusted across Ahmedabad, Velocity Express is your go-to for fast, dependable delivery.
                </p>
                <hr />


                <h2>Our Services at Velocity Express</h2>

                <p>
                  Velocity Express’s exclusive services make global and local shipping seamless and effortless. Our services
                  include:
                </p>

                <div className="row py-4">
                  <div className="col-lg-3 col-md-3 col-sm-6" id="domestic-express" style={{ scrollMarginTop: "140px" }}>
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                      <p className="pTagtitle mt-2 mb-1">Domestic Express Delivery</p>
                      <p className="text" style={{ fontSize: "0.83em" }}>
                        Our domestic express delivery service from Ahmedabad gets your packages across India quickly and reliably.
                        With coverage in over 500 cities and 5,000+ pin codes, we make sure your shipments reach even the most remote
                        areas smoothly. Our advantageously placed warehouses help us move packages quickly. We keep deliveries on
                        schedule, no matter where they go.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6" id="international-express" style={{ scrollMarginTop: "140px" }}>
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                      <p className="pTagtitle mt-2 mb-1">International Express Delivery</p>
                      <p className="text" style={{ fontSize: "0.83em" }}>
                        Our International Express Delivery service makes international shipping from Ahmedabad smooth and
                        convenient. With one of the largest self-operated international networks, Velocity Express handles all
                        logistics and simplifies the shipping process. We also manage customs clearance and documentation, so your
                        packages reach across borders without delays. With our duty-paid delivery option, you won’t face unexpected
                        charges. This makes international shipping easy and stress-free.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6" id="e-commerce" style={{ scrollMarginTop: "140px" }}>
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                      <p className="pTagtitle mt-2 mb-1">E-commerce Deliveries</p>
                      <p className="text" style={{ fontSize: "0.83em" }}>
                        Velocity Express is here to make life easier for online retailers in Ahmedabad. We understand the unique
                        challenges of e-commerce. That's why we offer flexible payment options like prepaid and cash-on-delivery (COD)
                        to suit your customers' preferences. Returns are easy with our efficient reverse logistics service. This service
                        allows customers to send back items without hassle. With Velocity Express, you can count on a reliable partner
                        to support your e-commerce business and drive growth.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 mt-2" id="bulk-cargo" style={{ scrollMarginTop: "140px" }}>
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                      <p className="pTagtitle mt-2 mb-1">Bulk Cargo Deliveries</p>
                      <p className="text" style={{ fontSize: "0.83em" }}>
                        Our bulk cargo delivery service handles all aspects of large shipments, from industrial machinery to wholesale
                        inventory. We ensure secure packaging and efficient transportation for heavy-duty items, high-volume stock, or
                        large consignments. You can trust us to manage the logistics so your shipments arrive safely and on time. With
                        our experienced team handling every detail, you can focus on growing your business.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-3 col-sm-6 mt-2" id="air-and-sea-freight" style={{ scrollMarginTop: "140px" }}>
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="On-time Delivery" />
                      <p className="pTagtitle mt-2 mb-1">Air and Sea Freight</p>
                      <p className="text" style={{ fontSize: "0.83em" }}>
                        You can send parcels to over 50 countries with our Air Freight service. We take care of the logistics and
                        ensure your package arrives on time at the destination in perfect condition.
                      </p>
                      <p className="text" style={{ fontSize: "0.83em" }}>
                        For larger consignments, you can go with our sea freight service. The service includes Full Container Load (FCL)
                        and Less than Container Load (LCL). Our team will assist you in choosing the most efficient and cost-effective
                        shipping option.
                      </p>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="text">
                      <h2 className="product-description__title">What to Expect from Velocity Express</h2>
                      <p>
                        You will experience optimized and prompt delivery service with Velocity Express in Ahmedabad. With us, you can
                        expect:
                      </p>
                      <div className="row">
                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                              <span className="count">01</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Fast and Reliable Service</h5>
                              <div className="text">
                                We at Velocity Express in Ahmedabad understand the need for timely delivery. Our committed team and broad
                                network work hand in hand to ensure your shipments arrive at their destination on time and safely.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                              <span className="count">02</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Transparent Pricing</h5>
                              <div className="text">Our shipping rates are transparent and economical with no hidden charges.</div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Extensive Network Coverage" />
                              <span className="count">03</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Real-Time Tracking</h5>
                              <div className="text">
                                You can know the status of your shipment in real time using our tracking service. All you need to do is
                                enter your tracking ID and learn about the position of your parcel.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/real-time-tracking.svg" alt="Real-Time Tracking" />
                              <span className="count">04</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Door to Door Pickup and Delivery</h5>
                              <div className="text">
                                For the ease of customers, we offer doorstep pickup and delivery service. Working professionals,
                                businesses, and senior citizens can take advantage of this service.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/secure-service.svg" alt="Safe and Secure Service" />
                              <span className="count">05</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Same Day & Next Day Delivery</h5>
                              <div className="text">
                                This service is apt for shipments that need to be delivered on an urgent basis.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Temperature-Controlled Services" />
                              <span className="count">06</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Temperature-Controlled Services</h5>
                              <div className="text">
                                We understand how crucial it is to deliver temperature-sensitive goods. Thus, Velocity Express offers
                                temperature-controlled service for special items like medicines and perishables.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Free Packaging" />
                              <span className="count">07</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Free Packaging</h5>
                              <div className="text">
                                Proper packaging is essential for items to reach their destination in an intact condition. Hence we provide
                                a complimentary packaging service where our team will secure your parcel as per the shipping standards.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Helpful Customer Support" />
                              <span className="count">08</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Helpful Customer Support</h5>
                              <div className="text">
                                Our automated customer service and IVR options will resolve your queries and provide the necessary
                                information. Call +91 90 2132 2132 for inquiries regarding your shipment.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Careful Handling" />
                              <span className="count">09</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Careful Handling</h5>
                              <div className="text">
                                Be it a delicate item, a document, or any other goods, we handle every package with care.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Flexible Solutions" />
                              <span className="count">10</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Flexible Solutions</h5>
                              <div className="text">
                                Our shipping options are flexible. With us, you can send single packages or large shipments, without any
                                hesitation.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Pick and Pack Services" />
                              <span className="count">11</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Pick and Pack Services</h5>
                              <div className="text">
                                We simplify the shipping process by picking and carefully packing your items. This service saves you time
                                and effort.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                          <div className="inner-box">
                            <div className="icon-box">
                              <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Warehousing Solutions" />
                              <span className="count">12</span>
                            </div>
                            <div className="content-box">
                              <h5 className="mt-0 mb-1">Warehousing Solutions</h5>
                              <div className="text">
                                Our well-organized warehouses offer adequate storage space. So, your shipments will be safe until they
                                are shipped.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </section>



        <section className="services-details pt-0">
          <div className="container pb-2">
            <div className="row">
              {/* Start Services Details Content */}
              <div className="col-xl-12 col-lg-12">
                <div className="services-details__content">
                  <h3 className="mt-0">How to Book a Parcel with Velocity Express</h3>
                  <p>Sending a courier from Ahmedabad is straightforward. Follow these three simple and easy steps:</p>
                  <div className="content">
                    <div className="feature-list">
                      <div className="row clearfix">
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 column"
                          id="pack-your-parcel"
                          style={{ scrollMarginTop: '140px' }}
                        >
                          <div className="single-item" style={{ height: '150px' }}>
                            <div className="icon-box">
                              <i className="fas fa-check-circle"></i>
                            </div>
                            <h6 className="title">Pack Your Parcel</h6>
                            <p style={{ fontSize: '0.75em' }}>
                              Secure your items as per the shipping norms. Take advantage of our free packaging service to avoid any potential damage during transit.
                            </p>
                          </div>
                        </div>

                        <div
                          className="col-lg-4 col-md-6 col-sm-12 column"
                          id="schedule-pickup"
                          style={{ scrollMarginTop: '140px' }}
                        >
                          <div className="single-item" style={{ height: '150px' }}>
                            <div className="icon-box">
                              <i className="fas fa-check-circle"></i>
                            </div>
                            <h6 className="title">Schedule Your Pickup</h6>
                            <p style={{ fontSize: '0.75em' }}>Let us know the appropriate time and location to pick up your parcel.</p>
                          </div>
                        </div>

                        <div
                          className="col-lg-4 col-md-6 col-sm-12 column"
                          id="track-your-shipment"
                          style={{ scrollMarginTop: '140px' }}
                        >
                          <div className="single-item" style={{ height: '150px' }}>
                            <div className="icon-box">
                              <i className="fas fa-check-circle"></i>
                            </div>
                            <h6 className="title">Track Your Shipment</h6>
                            <p style={{ fontSize: '0.75em' }}>
                              Use our real-time tracking system to know the status of your shipment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="services-details pt-0 bg-gray">
      <div className="container pt-5">
        <div className="row">
          <div className="">
            <h2>Frequently Asked Questions (FAQs)</h2>
            <ul className="accordion-box wow fadeInRight">
              {/* Block 1 */}
              <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                  What areas do you serve in Ahmedabad?
                  <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Velocity Express provides courier services across Ahmedabad. We offer nationwide deliveries to over 500 cities and 5,000+ pin codes in India. We also cover international shipping to over 50 countries.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 2 */}
              <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                  How fast is your delivery service?
                  <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      We pride ourselves on fast and reliable delivery. We offer same-day, next-day, and standard delivery options to suit your needs.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 3 */}
              <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                  Do you offer international shipping?
                  <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Yes, Velocity Express offers international courier services from Ahmedabad. We handle all logistics, including customs clearance and documentation, to make your international shipments hassle-free.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 4 */}
              <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                  Can I send bulk shipments with Velocity Express?
                  <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Sure! Our bulk cargo service delivers industrial machinery, wholesale inventory, and large consignments safely and on time. We use secure packaging and efficient transportation.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 5 */}
              <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                  Do you ship delicate or fragile items?
                  <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Yes, at Velocity Express, we ship all items with great care. Whether it's fragile goods or important documents, we ensure that every package is handled with attention to detail.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 6 */}
              <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                  How do I track my shipment?
                  <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Once your parcel is picked up, you can easily track its progress using our real-time tracking system. Simply enter your tracking ID on our website to get up-to-date information on your shipment's location.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 7 */}
              <li className={`accordion block ${activeIndex === 6 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(6)}>
                  Do you offer packaging services?
                  <div className={`icon fa ${activeIndex === 6 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 6 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Yes! We offer free packaging to ensure your items are safely packed and comply with shipping standards. Our team will securely pack your items before sending them on their way.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 8 */}
              <li className={`accordion block ${activeIndex === 7 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(7)}>
                  Do you offer temperature-controlled shipping?
                  <div className={`icon fa ${activeIndex === 7 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 7 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      Yes, we offer temperature-controlled services for sensitive items such as medical supplies, medicines, and perishables. This service ensures items reach their destination in the right conditions.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 9 */}
              <li className={`accordion block ${activeIndex === 8 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(8)}>
                  How do I schedule a pickup for my parcel?
                  <div className={`icon fa ${activeIndex === 8 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 8 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      You can easily schedule a pickup through our website or by calling our customer support team. Just provide the location and time, and we’ll take care of the rest.
                    </div>
                  </div>
                </div>
              </li>

              {/* Block 10 */}
              <li className={`accordion block ${activeIndex === 9 ? 'active-block' : ''}`}>
                <div className="acc-btn" onClick={() => toggleAccordion(9)}>
                  What kind of support do you provide?
                  <div className={`icon fa ${activeIndex === 9 ? 'fa-minus' : 'fa-plus'}`}></div>
                </div>
                <div className={`acc-content ${activeIndex === 9 ? 'current' : ''}`}>
                  <div className="content">
                    <div className="text">
                      We offer helpful customer support through an automated service and IVR system. For any inquiries, you can also contact our team at +91 90 2132 2132, and we’ll assist you promptly.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Footer/>


        <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
      </>
    </div>
  )
}

export default CourierServiceAhmedabad
