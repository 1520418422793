import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer';

const CourierServiceAlappuzha = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title>Best Courier Service in Delhi - Velocity Express</title>
                <meta name="description" content=" Looking for the best courier service in Delhi? Velocity Express offers reliable, fast, and affordable delivery for both local and international shipments." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 480px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}`}
            </style>

            <>
                <section className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Best Courier Service in Delhi</h1>
                                        <p className="text-light">Velocity Express is proud to offer reliable, efficient courier
                                            service in Delhi, delivering a swift and seamless experience for all your local and
                                            international shipping needs. Our services cater to both individuals and businesses,
                                            ensuring every package is handled with the utmost care and precision. As one of the
                                            best courier services in Delhi, Velocity Express combines modern technology with a
                                            robust network to meet your delivery expectations, no matter the distance.</p>
                                    </div>

                                    <div className="col-md-3">
                                        <EnquiryForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{ marginTop: "-16px", zIndex: 99, background: "#fff", position: "relative", padding: "12px 26px", borderRadius: "6px", boxShadow: "0px 2px 5px #d3d3d3" }} data-wow-delay="600ms">
                                    <li><a href="#hyperlocal-courier"><span>Hyperlocal Courier</span></a></li>
                                    <li><a href="#domestic-courier"><span>Domestic Courier</span></a></li>
                                    <li><a href="#international-courier"><span>International Courier</span></a></li>
                                    <li><a href="#air-freight"><span>Air Freight</span></a></li>
                                    <li><a href="#ocean-freight"><span>Ocean Freight</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>




                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>
                                    Our extensive network across India and over 150 countries worldwide enables us to provide a truly comprehensive logistics solution. Whether it’s urgent express delivery or standard shipment, our team ensures your parcel reaches its destination on time. With a staff of 700+ logistics professionals operating from 50+ offices, we maintain a highly efficient operation that places your convenience and satisfaction at the core.
                                </p>

                                <p>
                                    At Velocity Express, we utilize advanced automation technology for efficient order processing and tracking, which allows customers to stay updated on their shipment status at every step. In addition to traditional courier services, we offer temperature-controlled shipping options, time-sensitive deliveries, and customizable solutions designed to meet the unique needs of various industries.
                                </p>

                                <p>
                                    For customers seeking the best courier service in Delhi, Velocity Express delivers unmatched convenience and reliability. Whether you need a quick delivery within Delhi or secure international shipping, our experienced team and advanced resources are here to provide top-notch courier service, making us a trusted partner for all your delivery needs.
                                </p>

                                <hr />
                                <h2 className="mb-0">Why Velocity Express Is Your Go-To Courier Delivery Service in Delhi</h2>
                                <p className="pt-2">
                                    Velocity Express combines reliable, fast, and customer-focused delivery services to ensure your packages reach their destination safely and on time. With a broad network and dedicated teams, we manage each shipment with precision and efficiency, making us a top choice for courier services in Delhi.
                                </p>

                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Affordable Courier Service</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                We provide cost-effective shipping options that cater to different needs, ensuring affordable rates without compromising on quality or service. Velocity Express aims to make premium courier solutions accessible to everyone.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">Door-to-Door Service</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Our convenient door-to-door service takes the hassle out of sending and receiving packages. We handle the entire process, from pickup at your doorstep to delivery at the destination, for an effortless experience.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Secure Service</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                At Velocity Express, we prioritize the security of your shipments. Every package is handled carefully and transported securely to prevent any damage or loss, giving you peace of mind.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Wide Network</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                With an extensive network that spans across India and over 150 countries, Velocity Express can deliver to numerous locations worldwide. Our well-connected reach ensures that your packages arrive smoothly, no matter the distance.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Real-Time Tracking</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Stay informed with our real-time tracking feature, which allows you to monitor the status and location of your shipment at every step. You’ll always know where your package is, giving you full visibility until it reaches its destination.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">24/7 Customer Support</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Our dedicated customer support team is available around the clock to assist with any inquiries or concerns. Whether you need help with tracking or have questions about our services, we’re here to support you every step of the way.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2>Fast and Reliable Courier Service in Delhi</h2>
                                            <p>
                                                Delhi, the bustling capital city of India, is a vibrant hub for business, culture, and commerce. With its fast-paced lifestyle and diverse population, the demand for fast, reliable courier services is ever-growing. Whether it's personal parcels, important documents, or business shipments, efficient courier services play a vital role in ensuring timely deliveries across the city.
                                            </p>
                                            <p>
                                                At Velocity Express, we recognize the unique challenges of navigating Delhi's busy streets and diverse needs. Our comprehensive courier solutions are tailored to meet the demands of this dynamic city, offering reliable, door-to-door service that ensures your deliveries are fast, secure, and hassle-free.
                                            </p>
                                            <p>
                                                If you're looking for secure and affordable courier services in Delhi, you’ve come to the right place. Velocity Express is your local expert in delivering seamless courier solutions, ensuring your shipments reach their destination quickly, no matter where you are in the city.
                                            </p>
                                            <hr />
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h2 className="mb-2">Our Range of Courier Services</h2>
                                                    <h3>Courier Services in Key Areas of Delhi</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in East Delhi</li>
                                                            <li>Courier Service in Shahdara</li>
                                                            <li>Courier Service in North East Delhi</li>
                                                            <li>Courier Service in New Delhi</li>
                                                            <li>Courier Service in North Delhi</li>
                                                            <li>Courier Service in North West Delhi</li>
                                                            <li>Courier Service in Central Delhi</li>
                                                            <li>Courier Service in South Delhi</li>
                                                            <li>Courier Service in South West Delhi</li>
                                                            <li>Courier Service in West Delhi</li>
                                                        </ul>
                                                    </div>
                                                    <hr />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h3 className="mb-2">Serving Key Regions Across India</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Andhra Pradesh</li>
                                                            <li>Courier Service in Assam</li>
                                                            <li>Courier Service in Bihar</li>
                                                            <li>Courier Service in Chhattisgarh</li>
                                                            <li>Courier Service in Delhi</li>
                                                            <li>Courier Service in Gujarat</li>
                                                            <li>Courier Service in Daman and Diu</li>
                                                            <li>Courier Service in Haryana</li>
                                                            <li>Courier Service in Himachal Pradesh</li>
                                                            <li>Courier Service in Jammu and Kashmir</li>
                                                            <li>Courier Service in Jharkhand</li>
                                                            <li>Courier Service in Karnataka</li>
                                                            <li>Courier Service in Kerala</li>
                                                            <li>Courier Service in Madhya Pradesh</li>
                                                            <li>Courier Service in Maharashtra</li>
                                                            <li>Courier Service in Goa</li>
                                                            <li>Courier Service in Manipur</li>
                                                            <li>Courier Service in Mizoram</li>
                                                            <li>Courier Service in Nagaland</li>
                                                            <li>Courier Service in Sikkim</li>
                                                            <li>Courier Service in Tripura</li>
                                                            <li>Courier Service in Arunachal Pradesh</li>
                                                            <li>Courier Service in Meghalaya</li>
                                                            <li>Courier Service in Odisha</li>
                                                            <li>Courier Service in Chandigarh</li>
                                                            <li>Courier Service in Punjab</li>
                                                            <li>Courier Service in Rajasthan</li>
                                                            <li>Courier Service in Tamil Nadu</li>
                                                            <li>Courier Service in Telangana</li>
                                                            <li>Courier Service in Uttar Pradesh</li>
                                                            <li>Courier Service in Uttarakhand</li>
                                                            <li>Courier Service in West Bengal</li>
                                                            <li>Courier Service in Andaman and Nicobar Islands</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h2>Courier Services at Velocity Express</h2>
                                            <div className="row">
                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="hyperlocal-courier" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">01</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Hyperlocal Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: "0.83em" }}>
                                                                    Velocity Express provides reliable and affordable hyperlocal courier services for fast deliveries within Delhi. Our efficient network ensures that orders are quickly delivered to their destinations with precision and care. We work with top-rated courier partners to tailor our service to your specific delivery needs. With 24/7 availability, we guarantee that your shipments reach their destination on time, whenever you need them.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="domestic-courier" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">02</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Domestic Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: "0.83em" }}>
                                                                    In today’s fast-paced world, reliable domestic courier services are more essential than ever, especially with the rise of e-commerce and the increasing need for timely deliveries. At Velocity Express, we provide top-notch courier solutions that cater to both individuals and businesses. Whether you're sending small documents or large parcels, our efficient and secure services ensure your items reach their destination on time, every time.
                                                                </p>
                                                                <p className="text" style={{ fontSize: "0.83em" }}>
                                                                    With competitive pricing and a commitment to quality, we strive to make your delivery experience smooth and hassle-free. Need a secure domestic courier service? Trust Velocity Express for fast, secure, and affordable deliveries. Contact us today to learn more!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="international-courier" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Extensive Network Coverage" />
                                                            <span className="count">03</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>International Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: "0.83em" }}>
                                                                    At Velocity Express, we offer reliable and efficient international courier services to destinations around the globe. With years of experience and a reputation for timely deliveries, we ensure your parcels are shipped securely and cost-effectively. Whether you're sending documents or packages, our professional team handles each shipment with care, making your international deliveries seamless and hassle-free.
                                                                </p>
                                                                <p className="text" style={{ fontSize: "0.83em" }}>
                                                                    We are committed to providing exceptional service with a focus on customer satisfaction. Looking for the best international courier service? Choose Velocity Express for fast, secure, and affordable shipping worldwide. Get in touch with us today!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-details pt-0 bg-light">
                    <div className="container pt-5">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">Freight Services at Velocity Express</h2>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div
                                                    className="col-lg-6 col-md-6 col-sm-12 column"
                                                    id="air-freight"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item setheight">
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h3 className="title">Air Freight Service</h3>
                                                        <p>
                                                            If you need fast, reliable shipping, our Air Freight Service is the
                                                            perfect solution. At Velocity Express, we offer quick and efficient
                                                            air freight options to major international destinations, ensuring
                                                            your goods reach their destination on time. Whether you’re shipping
                                                            documents, commercial goods, or specialized cargo, we provide
                                                            flexible solutions to suit your needs. With a vast network of
                                                            partners and airlines, we ensure smooth delivery, customs clearance,
                                                            and regular updates on your shipment’s progress.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-lg-6 col-md-6 col-sm-12 column"
                                                    id="ocean-freight"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item setheight">
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h3 className="title">Ocean Freight Service</h3>
                                                        <p>
                                                            For businesses needing to transport large volumes of cargo at
                                                            cost-effective rates, Velocity Express offers comprehensive Ocean
                                                            Freight Services. We provide reliable shipping solutions to key
                                                            ports worldwide, whether you're sending a Full Container Load (FCL)
                                                            or Less than Container Load (LCL). With strong relationships with
                                                            global shipping lines, we offer tailored options and flexible
                                                            scheduling to meet your specific needs. Our easy-to-use tracking
                                                            system keeps you informed, ensuring your goods arrive safely and on
                                                            time, every time.
                                                        </p>
                                                        <p>
                                                            For businesses needing to transport large volumes of cargo at
                                                            co-effective rates, Velocity Express offers comprehensive ocean
                                                            Freight Services. We provide reliable shipping solutions to key
                                                            consideration.
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <h2>E-Commerce Shipping with Velocity Express</h2>
                                                <p>
                                                    Velocity Express offers reliable and efficient e-commerce shipping services
                                                    to meet the growing needs of online businesses. With a vast network covering
                                                    19,000+ pin codes in India and 150 countries worldwide, we ensure fast,
                                                    secure deliveries to your customers, whether locally or internationally.
                                                </p>
                                                <p>
                                                    Our service includes real-time tracking, affordable rates, and 24/7 customer
                                                    support, providing seamless shipping solutions tailored to your business
                                                    needs.
                                                </p>
                                                <hr />

                                                <h2>Bulk Shipment Delivery</h2>
                                                <p>
                                                    At Velocity Express, our bulk shipping services provide efficient,
                                                    cost-effective solutions for businesses of all sizes. Whether you're
                                                    shipping across India or globally, we offer reliable options that fit your
                                                    needs and budget. Enjoy real-time tracking, 24/7 customer support, and a
                                                    vast network covering over 19,000 pin codes in India and 150 countries
                                                    worldwide.
                                                </p>
                                                <p>
                                                    Rest assured, your bulk shipments are handled with care, ensuring they reach
                                                    their destination safely and on time. Let us streamline your shipping
                                                    process and help your business grow with our seamless delivery solutions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>

                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="">
                                <h2>Frequently Asked Questions About Courier Services in Delhi</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            Which is the best courier service for domestic and international deliveries in Delhi?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers top-rated courier services in Delhi, both for domestic and international shipments. We guarantee fast, reliable, and affordable deliveries, ensuring your parcels arrive on time across India and worldwide.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            Where can I find trustworthy freight services in Delhi?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express provides reliable freight solutions for both air and sea shipments in Delhi. Whether you’re shipping locally or internationally, we offer timely and secure delivery for businesses of all sizes.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Which courier service is best for e-commerce shipping?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express is perfect for e-commerce businesses. We provide fast, secure deliveries with real-time tracking, ensuring your products are delivered quickly to your customers, whether within India or globally.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Which company is best for bulk delivery services?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers trusted bulk delivery services for both local and international shipments. Our cost-effective and reliable solutions ensure your large shipments arrive safely and on schedule.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            Can I send a courier within Delhi using Velocity Express?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, you can send parcels within Delhi and to any of India’s 19,000+ pin codes with Velocity Express. We ensure quick and dependable delivery across the country.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 6 */}
                                    <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                                            Does Velocity Express deliver couriers to the USA from Delhi?
                                            <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express ships to over 150 countries, including the USA. With our global network, we ensure your parcels reach their destination safely and on time.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>

                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div >
    )
}

export default CourierServiceAlappuzha
