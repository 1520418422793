import React from 'react'
import Footer from '../MyComponents/Footer'
import ServicesForm from './ServicesForm'
import IndexHeader from './IndexHeader';

const Domestic = () => {
    return (
        <>
             <IndexHeader/>
            <section className="page-title" style={{ backgroundImage: 'url(images/background/2.jpg)' }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1 className="title">Domestic</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Domestic</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="services-details">
                <div className="container">
                    <div className="row">
                        {/* Start Services Details Content */}
                        <div className="col-xl-8 col-lg-8">
                            <div className="services-details__content">
                                <p>
                                    Velocity Express is a leader in domestic courier services, delivering excellence with every package. Our comprehensive network, cutting-edge technology, and commitment to customer satisfaction set us apart in the industry. Here’s an in-depth look at what makes our domestic courier services exceptional:
                                </p>
                                <hr />
                                <h4>Nationwide Reach</h4>
                                <div className="product-description__list">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Unmatched Coverage:</b> With a presence in over 500 cities and more than 5000 pin codes, our extensive network ensures that we can reach every corner of the country.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Strategically Located Hubs:</b> Our 50+ offices and 10+ warehouses are strategically placed to guarantee efficient and timely deliveries, no matter where your package is headed.
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                                <h4>Versatile Services</h4>
                                <div className="product-description__list">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>E-Commerce Solutions:</b>
                                            <ul>
                                                <li>Prepaid and COD Deliveries: We offer both prepaid and cash-on-delivery options to meet the varying needs of online businesses and their customers.</li>
                                                <li>Reverse Logistics: Simplify returns with our reverse pickup services, ensuring a seamless experience for your customers.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Special Deliveries:</b>
                                            <ul>
                                                <li>Same-Day and Next-Day Options: For urgent shipments, our priority services ensure your package arrives as quickly as possible.</li>
                                                <li>Temperature-Controlled Services: We handle sensitive shipments requiring specific temperature conditions, ensuring they arrive in perfect condition.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                                <h4>Bulk Cargo and Special Services</h4>
                                <div className="product-description__list">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Bulk Deliveries:</b> Full Truckload (FTL) and Less Than Truckload (LTL): Whether you need to ship large quantities or smaller loads, we provide flexible options to suit your requirements.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Priority Freight Service:</b> Our priority freight services offer expedited handling for time-sensitive cargo.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Vendor and Drop Ship Services:</b> We provide vendor pickups and drop ship services, streamlining your supply chain operations and enhancing efficiency.
                                        </li>
                                    </ul>
                                </div>

                                <hr />
                                <div className="content">
                                    <div className="text">
                                        <h3>Advanced Technology</h3>
                                        <ul className="list-unstyled">
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Automation Across Processes:</b> Our operations are 100% automated, reducing errors and ensuring a smooth, efficient process from start to finish
                                            </li>
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Real-Time Tracking:</b> Stay informed with our advanced tracking system, which provides real-time updates on the status of your shipment.
                                            </li>
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Booking and API Integration:</b> Simplify your logistics with our easy-to-use booking system and seamless API integration for automated operations.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <hr />
                                <div className="content">
                                    <div className="text">
                                        <h3>Customer-Centric Approach</h3>
                                        <ul className="list-unstyled">
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Dedicated Support:</b> Our customer service team is available around the clock to assist with any queries or issues, ensuring a hassle-free experience.
                                            </li>
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>IVR and Automated Services:</b> Interactive Voice Response (IVR) and automated customer service tools provide timely and efficient support.
                                            </li>
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Personalized Solutions:</b> We understand that each customer’s needs are unique, and we offer tailored solutions to meet those specific requirements.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* End Services Details Content */}

                        {/* Start Services Details Sidebar */}
                        <div className="col-xl-4 col-lg-4">
                            <div className="service-sidebar">
                                {/* Start Services Details Sidebar Single */}
                                <div className="sidebar-widget service-sidebar-single">
                                    <div className="service-sidebar-single-contact-box text-center wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1200m" style={{ backgroundImage: 'url(images/resource/news-2.jpg)' }}>
                                        <div className="icon">
                                            <span className="lnr lnr-icon-phone"></span>
                                        </div>
                                        <div className="title">
                                            <h2>
                                                Best <br />Logistics <br />
                                                Services
                                            </h2>
                                        </div>
                                        <p className="phone"><a href="tel:+919021322132">+91 90 2132 2132</a></p>
                                        <p>Call Us Anytime</p>

                                        {/* NEW */}
                                        <p style={{ color: '#fff', fontSize: '20px' }}>OR</p>
                                        <a href="/booking">
                                            <button className="btn btn-warning">BOOK NOW</button>
                                        </a>
                                        {/* NEW */}
                                    </div>
                                </div>
                                {/* End Services Details Sidebar Single */}

                                {/* Start Services Details Sidebar Single */}
                                <div className="sidebar-widget service-sidebar-single">
                                    <div className="service-sidebar-single-services wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1200m">
                                        <div className="title">
                                            <h3>All Services</h3>
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="/domestic">Domestic <i className="fa fa-angle-right"></i></a>
                                            </li>
                                            <li className="current">
                                                <a href="/international">International <i className="fa fa-angle-right"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Services Details Sidebar Single */}

                                {/* Form Column */}
                                <div className="form-column col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner-column">
                                        <h5>Domestic Services</h5>
                                        <ServicesForm />
                                    </div>
                                </div>
                                {/* End Form Column */}
                            </div>
                        </div>
                        {/* End Services Details Sidebar */}
                    </div>
                </div>
            </section>


            <Footer />


        </>
    )
}

export default Domestic
