import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer'
const CourierServiceMumbai = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title>Best Courier Service in Mumbai - Velocity Express</title>
                <meta name="description" content=" Velocity Express offers the best courier service in Mumbai for fast, reliable, and affordable deliveries. Serving across India and 150+ countries, book now!" />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
        .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 430px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}`}
            </style>

            <>
                <section className="call-to-action style-two" style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Best Courier Service in Mumbai</h1>
                                        <p className="text-light">
                                            Velocity Express is one of the leading courier services providers in Mumbai, offering fast, reliable, and efficient delivery solutions for both local and international shipments. Whether you're sending personal items, documents, or business goods, our dedicated team ensures that your packages reach their destination safely and on time.
                                        </p>
                                    </div>

                                    <div className="col-md-3">
                                        <EnquiryForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-item-center wow fadeInRight"
                                    style={{
                                        marginTop: '-16px',
                                        zIndex: 99,
                                        background: '#fff',
                                        position: 'relative',
                                        padding: '12px 26px',
                                        borderRadius: '6px',
                                        boxShadow: '0px 2px 5px #d3d3d3',
                                    }}
                                    data-wow-delay="600ms"
                                >
                                    <li>
                                        <a href="#hyperlocal-courier">
                                            <span>Hyperlocal Courier</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#domestic-courier">
                                            <span>Domestic Courier</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#international-courier">
                                            <span>International Courier</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#air-freight">
                                            <span>Air Freight</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ocean-freight">
                                            <span>Ocean Freight</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>
                                    As a prominent player in the logistics industry, Velocity Express leverages advanced technology and automated systems to streamline the entire delivery process. This allows us to provide the best courier service in Mumbai, ensuring high accuracy and timely deliveries, no matter the size or complexity of the shipment. Our services are designed to meet the diverse needs of businesses and individuals, whether it's urgent parcel delivery or regular courier services.
                                </p>

                                <p>
                                    With a robust network and global reach, Velocity Express provides courier services across 19,000 pin codes in India and delivers to over 150 countries worldwide. Our domestic courier service in Mumbai is supported by an experienced team that handles shipments with care and precision. We understand the importance of on-time deliveries, especially in a busy city like Mumbai, and our goal is to make sure your shipments arrive without delay.
                                </p>

                                <p>
                                    For businesses in Mumbai, we offer customized solutions including time-sensitive deliveries and temperature-controlled services. Our automated systems ensure that every package is tracked and monitored, providing transparency and peace of mind throughout the shipping process. Additionally, our easy-to-use pickup app makes it convenient for you to schedule pickups, making Velocity Express a preferred choice for businesses looking for efficient courier services in Mumbai.
                                </p>

                                <p>
                                    Choose Velocity Express for the best courier service in Mumbai, known for its commitment to speed, reliability, and customer satisfaction. Whether you're sending a package across the city or shipping internationally, we are dedicated to providing seamless and timely delivery every time.
                                </p>

                                <hr />

                                <h2 className="mb-0">Why Velocity Express Is Your Top Courier Service in Mumbai</h2>
                                <p className="pt-2">
                                    Velocity Express offers quick, reliable, and affordable courier services in Mumbai. We provide fast delivery solutions, fair prices, and great customer support. This way, your parcels arrive on time, every time. Choose us for a hassle-free courier experience that meets all your delivery needs.
                                </p>

                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Affordable Courier Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Velocity Express offers cost-effective solutions without compromising on quality. We provide competitive pricing, making reliable courier services in Mumbai accessible to both businesses and individuals.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">Door-to-Door Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Our door-to-door courier service in Mumbai ensures a hassle-free experience. We pick up and deliver your packages directly from and to your location, saving you time and effort.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Secure Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                At Velocity Express, the security of your shipments is our top priority. We use advanced tracking systems and reliable packaging to ensure your parcels reach their destination safely.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Wide Network</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Velocity Express offers unmatched global reach with a vast network of over 150 countries. We provide seamless connectivity and reliable service, whether you're shipping locally within Mumbai or internationally.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Real-Time Tracking</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Stay updated with our real-time tracking feature. You can monitor your shipment’s progress at every stage, giving you peace of mind throughout the delivery process.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">24/7 Customer Support</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Our customer support team is available around the clock to assist you with any questions or concerns. Whether you need help with a shipment or tracking details, we’re here to provide support whenever needed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2>Fast and Reliable Courier Service in Mumbai</h2>
                                            <p>
                                                Velocity Express offers fast and reliable courier services in Mumbai, catering to both personal and business delivery needs. Whether you're sending documents, parcels, or goods, we ensure timely and efficient deliveries across the city.
                                            </p>
                                            <p>
                                                Our easy online booking system, competitive pricing, and dedicated customer support make us a trusted choice for all your courier requirements. Apart from Mumbai, we offer courier services in numerous cities across India. Check out <a href="https://velexp.com/our-presence">our presence</a> in major cities nationwide! Choose Velocity Express for secure, cost-effective courier services in Mumbai and enjoy hassle-free delivery every time.
                                            </p>
                                            <hr />
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h2 className="mb-2">Our Range of Courier Services</h2>
                                                    <h3>Areas We Serve</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Kolhapur</li>
                                                            <li>Courier Service in Ratnagiri</li>
                                                            <li>Courier Service in Sangli</li>
                                                            <li>Courier Service in Sindhudurg</li>
                                                            <li>Courier Service in Mumbai</li>
                                                            <li>Courier Service in Mumbai Suburban</li>
                                                            <li>Courier Service in Pune</li>
                                                            <li>Courier Service in Akola</li>
                                                            <li>Courier Service in Washim</li>
                                                            <li>Courier Service in Yavatmal</li>
                                                            <li>Courier Service in Amravati</li>
                                                            <li>Courier Service in Buldhana</li>
                                                            <li>Courier Service in Gadchiroli</li>
                                                            <li>Courier Service in Chandrapur</li>
                                                            <li>Courier Service in Nagpur</li>
                                                            <li>Courier Service in Bhandara</li>
                                                            <li>Courier Service in Gondia</li>
                                                            <li>Courier Service in Wardha</li>
                                                            <li>Courier Service in Malegaon</li>
                                                            <li>Courier Service in Nashik</li>
                                                            <li>Courier Service in Raigarh</li>
                                                            <li>Courier Service in Thane</li>
                                                            <li>Courier Service in Ahmednagar</li>
                                                            <li>Courier Service in Solapur</li>
                                                            <li>Courier Service in Satara</li>
                                                        </ul>
                                                    </div>
                                                    <hr />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h3 className="mb-2">Serving Key Regions Across India</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Andhra Pradesh</li>
                                                            <li>Courier Service in Assam</li>
                                                            <li>Courier Service in Bihar</li>
                                                            <li>Courier Service in Chhattisgarh</li>
                                                            <li>Courier Service in Delhi</li>
                                                            <li>Courier Service in Gujarat</li>
                                                            <li>Courier Service in Daman and Diu</li>
                                                            <li>Courier Service in Haryana</li>
                                                            <li>Courier Service in Himachal Pradesh</li>
                                                            <li>Courier Service in Jammu and Kashmir</li>
                                                            <li>Courier Service in Jharkhand</li>
                                                            <li>Courier Service in Karnataka</li>
                                                            <li>Courier Service in Kerala</li>
                                                            <li>Courier Service in Madhya Pradesh</li>
                                                            <li>Courier Service in Maharashtra</li>
                                                            <li>Courier Service in Goa</li>
                                                            <li>Courier Service in Manipur</li>
                                                            <li>Courier Service in Mizoram</li>
                                                            <li>Courier Service in Nagaland</li>
                                                            <li>Courier Service in Sikkim</li>
                                                            <li>Courier Service in Tripura</li>
                                                            <li>Courier Service in Arunachal Pradesh</li>
                                                            <li>Courier Service in Meghalaya</li>
                                                            <li>Courier Service in Odisha</li>
                                                            <li>Courier Service in Chandigarh</li>
                                                            <li>Courier Service in Punjab</li>
                                                            <li>Courier Service in Rajasthan</li>
                                                            <li>Courier Service in Tamil Nadu</li>
                                                            <li>Courier Service in Telangana</li>
                                                            <li>Courier Service in Uttar Pradesh</li>
                                                            <li>Courier Service in Uttarakhand</li>
                                                            <li>Courier Service in West Bengal</li>
                                                            <li>Courier Service in Andaman and Nicobar Islands</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <hr />
                                                <h2>Courier Services at Velocity Express</h2>
                                                <div className="row">
                                                    <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="hyperlocal-courier" style={{ scrollMarginTop: "140px" }}>
                                                        <div className="inner-box">
                                                            <div className="icon-box">
                                                                <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                                <span className="count">01</span>
                                                            </div>
                                                            <div className="content-box">
                                                                <h3>Hyperlocal Courier Service</h3>
                                                                <div className="text">
                                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                                        Velocity Express provides a reliable and affordable hyperlocal courier service for fast local deliveries. Our
                                                                        efficient network ensures that orders are quickly delivered to their destinations with precision and care. We
                                                                        work with multiple top-rated courier partners to tailor the service to your specific hyperlocal delivery needs.
                                                                        With 24/7 availability, we guarantee round-the-clock deliveries, ensuring your shipments reach their destination
                                                                        on time, whenever you need them.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="domestic-courier" style={{ scrollMarginTop: "140px" }}>
                                                        <div className="inner-box">
                                                            <div className="icon-box">
                                                                <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                                                                <span className="count">02</span>
                                                            </div>
                                                            <div className="content-box">
                                                                <h3>Domestic Courier Service</h3>
                                                                <div className="text">
                                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                                        Velocity Express offers fast and reliable domestic courier services to meet the growing demand in India. Whether
                                                                        you're sending packages or documents, we ensure secure and on-time deliveries for both individuals and
                                                                        businesses.
                                                                    </p>
                                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                                        Our solutions are tailored to meet the specific needs of the domestic market, with competitive pricing and
                                                                        customer-focused service. With various payment and scheduling options, you can count on our dedicated team to
                                                                        provide a smooth and hassle-free delivery experience every time. Book our <a href="https://velexp.com/domestic">domestic
                                                                            courier services</a> today!
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="international-courier" style={{ scrollMarginTop: "140px" }}>
                                                        <div className="inner-box">
                                                            <div className="icon-box">
                                                                <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Extensive Network Coverage" />
                                                                <span className="count">03</span>
                                                            </div>
                                                            <div className="content-box">
                                                                <h3>International Courier Service</h3>
                                                                <div className="text">
                                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                                        Velocity Express is a trusted provider of international courier services, offering fast and reliable delivery to
                                                                        destinations worldwide. We balance affordability with high-quality service, ensuring your shipments are handled
                                                                        with care and reach their destination on time.
                                                                    </p>
                                                                    <p className="text" style={{ fontSize: "0.83em" }}>
                                                                        With years of experience and a vast global network, we simplify international shipping with secure payment
                                                                        options, real-time tracking, and expert solutions. Book our <a href="https://velexp.com/international">international
                                                                            courier service</a> today! Choose Velocity Express for hassle-free deliveries, no matter where you're sending.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-details pt-0 bg-light">
                    <div className="container pt-5">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">Freight Services at Velocity Express</h2>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="air-freight" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Air Freight Service</h3>
                                                        <p>
                                                            Velocity Express offers efficient Air Freight Services for those needing fast and reliable international shipments. We handle a wide variety of goods, from documents to specialized cargo, ensuring timely deliveries to major global destinations.
                                                        </p>
                                                        <p>
                                                            With a strong network of agents and partnerships with leading airlines, we provide flexible delivery options and additional services like customs clearance and documentation. Our proactive updates keep you informed about your shipment’s progress, ensuring a smooth and on-time delivery experience.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="ocean-freight" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Ocean Freight Service</h3>
                                                        <p>
                                                            Our ocean freight service at Velocity Express is ideal for businesses transporting large volumes of goods at competitive prices. We connect you to major ports worldwide, offering both Full Container Load (FCL) and Less than Container Load (LCL) options.
                                                        </p>
                                                        <p>
                                                            With strong relationships with shipping lines, we ensure flexible schedules and optimal transit times to meet your unique needs. Advanced tracking systems allow you to monitor your cargo, giving you confidence that your shipments will arrive safely and on time.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="ecommerce-shipment" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">E-Commerce Shipment Delivery</h3>
                                                        <p>
                                                            Velocity Express offers reliable e-commerce shipment delivery solutions for both domestic and international shipping. Whether you're sending products within India or internationally, our service ensures fast and secure deliveries to your customers. We simplify the process, allowing you to order online and receive your goods directly at your doorstep. With a focus on timely deliveries, we help you provide an efficient and hassle-free shopping experience for your customers.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 column" id="bulk-shipment" style={{ scrollMarginTop: "140px" }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h3 className="title">Bulk Shipment Delivery</h3>
                                                        <p>
                                                            Velocity Express provides cost-effective bulk shipping services, ideal for businesses that need to transport large quantities of goods. Our solutions are designed to meet the diverse needs of businesses, whether small or large, by offering flexible and budget-friendly shipping options. With our extensive logistics network, we ensure your bulk shipments are delivered on time and in perfect condition. Our reliable tracking system gives you peace of mind, knowing that your products are in safe hands throughout the delivery process.
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="">
                                <h2>Frequently Asked Questions About Courier Services in Mumbai</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            Who gives the best domestic and international courier services in Mumbai?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express is known for providing the best domestic and international courier services in Mumbai. With fast, reliable, and cost-effective solutions, we ensure timely delivery of your parcels both within India and across the globe.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            Where can I get reliable freight services in Mumbai?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers reliable freight services in Mumbai for both air and ocean shipments. Our comprehensive freight solutions cater to businesses of all sizes, ensuring timely and secure delivery of your goods to global destinations.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Which courier service is the best for e-commerce shipping?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express provides excellent courier services for e-commerce businesses. With quick, secure deliveries and real-time tracking, we ensure that your products reach customers promptly and in perfect condition, both domestically and internationally.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Which shipping company offers reliable bulk delivery services?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express is a trusted provider of bulk shipment delivery services. Whether you’re shipping large quantities of goods within India or internationally, our cost-effective solutions and reliable logistics network ensure your bulk shipments are delivered safely and on time.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            Can I send a courier to Delhi from Mumbai with Velocity Express?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, you can send a courier from Mumbai to Delhi and other cities like Pune, Bangalore, Kerala, and beyond. We serve 19,000+ pincodes worldwide, ensuring quick and reliable delivery across India and internationally.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 6 */}
                                    <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                                            Does Velocity Express offer courier delivery to the USA from Mumbai?
                                            <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express provides courier delivery to over 150 countries, including the USA, from Mumbai. Our global network ensures your parcels reach their international destination safely and on time.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>

                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div>
    )
}

export default CourierServiceMumbai
