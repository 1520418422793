import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer';

const CourierServiceBhubaneswar = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title> Reliable Courier Service in Bhubaneswar – Velocity Express</title>
                <meta name="description" content="Choose Velocity Express for a reliable courier service in Bhubaneswar. Fast delivery, transparent pricing, and top-notch customer care for all your shipping needs." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 540px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}`}
            </style>

            <>

                <section className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Reliable Courier Service in Bhubaneswar</h1>
                                        <p className="text-light">
                                            Velocity Express offers a reliable courier service in Bhubaneswar by simplifying the process of sending packages within India and internationally. With a strong network spread across 150 countries, we have a team of 700 dedicated professionals and 50 offices. We ensure seamless delivery options for customers across the globe.
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        {/* Enquiry form */}
                                        <EnquiryForm /> {/* Replace this with actual React component for the enquiry form */}
                                        {/* End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{
                                        marginTop: '-16px',
                                        zIndex: 99,
                                        background: '#fff',
                                        position: 'relative',
                                        padding: '12px 26px',
                                        borderRadius: '6px',
                                        boxShadow: '0px 2px 5px #d3d3d3',
                                    }}
                                    data-wow-delay="600ms"
                                >
                                    <li>
                                        <a href="#domestic-express">
                                            <span>Domestic Express</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#international-express">
                                            <span>International Express</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#e-commerce">
                                            <span>E-commerce</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#bulk-cargo">
                                            <span>Bulk Cargo</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#air-and-sea-freight">
                                            <span>Air and Sea Freight</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>
                                    We specialize in domestic, international, and hyperlocal courier services. Additionally, we
                                    efficiently manage e-commerce deliveries, supporting businesses with reliable logistics
                                    solutions.
                                </p>

                                <p>
                                    Known for our customer-first approach and competitive pricing, we’re one of the best courier
                                    services in Bhubaneswar. We promise to deliver on time and offer flexible shipping options.
                                    This makes us the top choice for personal and business shipments.
                                </p>
                                <hr />

                                <h2>Velocity Express: Fast, Efficient Courier Services in Bhubaneswar</h2>

                                <p>
                                    Sending parcels locally or internationally from Bhubaneswar is easy and worry-free with
                                    Velocity Express. We have over 200 professionals for domestic services and 100 for
                                    international deliveries. Our teams work diligently to ensure your packages arrive safely
                                    and on time.
                                </p>

                                <p>
                                    We’ve streamlined our courier services with advanced technology to offer a seamless
                                    experience. You can book your parcel online, track its status in real time, and receive
                                    automated updates at each stage.
                                </p>

                                <p>
                                    Our commitment to customer care goes beyond delivery. We have a dedicated support team
                                    available 24/7 to answer any questions. With IVR and automated tools for quick assistance,
                                    we’re here whenever you need us. Choose Velocity Express as your trusted delivery partner
                                    for reliable and efficient service.
                                </p>
                                <hr />
                                <h2>Our Services at Velocity Express</h2>
                                <p>
                                    Velocity Express is committed to making both local and international shipping easy and
                                    seamless. Here’s what we offer:
                                </p>
                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6" id="domestic-express" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Domestic Express Delivery</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Our domestic express delivery service from Bhubaneswar prioritizes speed and reliability.
                                                We ensure your packages arrive on schedule anywhere in India. With a logistics network that
                                                spans 500 cities and over 5,000 pin codes, we deliver with efficiency and reach. Strategically
                                                placed warehouses enhance our operations, allowing for quick dispatches and dependable
                                                delivery—even in hard-to-reach regions.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6" id="international-express" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">International Express Delivery</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Sending packages overseas is easy and worry-free with Velocity Express’s international
                                                express delivery. With access to over 150 countries, we operate one of the largest independent
                                                global networks. We handle customs clearance and documentation, and for added convenience, we
                                                offer a duty-paid delivery option. Shipping worldwide has never been simpler or more stress-free.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6" id="e-commerce" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">E-commerce Deliveries</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Perfect for online businesses, our e-commerce delivery service is designed with both
                                                merchants and their customers in mind. We offer flexible payment options, including prepaid
                                                and cash-on-delivery, making shopping convenient. Plus, our reverse logistics service streamlines
                                                the returns process. Velocity Express supports online stores and ensures customer satisfaction
                                                every step of the way.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2" id="bulk-cargo" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Bulk Cargo Deliveries</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Our bulk cargo service makes it easy to transport large shipments. Whether you’re shipping heavy
                                                machinery or sizable inventory, Velocity Express manages it with skill and care. Our experienced
                                                team takes care of every detail. So, you can rest assured your cargo will arrive on time and in
                                                perfect condition.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-3 col-sm-6 mt-2" id="air-and-sea-freight" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="On-time Delivery" />
                                            <p className="pTagtitle mt-2 mb-1">Air and Sea Freight</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Need a fast shipment? Our air freight service reaches over 50 countries. We manage logistics
                                                and ensure your shipments reach the destination at the specified time.
                                            </p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                For more extensive shipments, our sea freight service offers both Full Container Load (FCL)
                                                and Less than Container Load (LCL) options. With partnerships with major shipping lines, we
                                                provide reliable, competitively priced service by sea as well.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2 className="product-description__title">What to Expect from Velocity Express</h2>
                                            <p>
                                                Velocity Express’s courier service in Bhubaneswar delivers an exceptional shipping experience
                                                tailored to meet your unique needs. Here's what you can expect:
                                            </p>
                                            <div className="row">
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/affordable-shipping.svg"
                                                                alt="Affordable Shipping Solutions"
                                                            />
                                                            <span className="count">01</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Fast and Reliable Service</h5>
                                                            <div className="text">
                                                                We pride ourselves on delivering your packages on time, every time, ensuring they reach
                                                                their destination without delay.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/easy-booking.svg"
                                                                alt="Affordable Shipping Solutions"
                                                            />
                                                            <span className="count">02</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Transparent Pricing</h5>
                                                            <div className="text">
                                                                Enjoy affordable, clear pricing with no hidden costs—what you see is what you pay.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/network-coverage.svg"
                                                                alt="Extensive Network Coverage"
                                                            />
                                                            <span className="count">03</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Real-Time Tracking</h5>
                                                            <div className="text">
                                                                Stay informed about the status of your shipment with our easy-to-use{" "}
                                                                <a href="https://velexp.com/track/" target="_blank" rel="noopener noreferrer">
                                                                    real-time tracking system
                                                                </a>
                                                                . Simply visit our website, enter your tracking ID, and get the latest updates on your
                                                                parcel’s journey.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/real-time-tracking.svg"
                                                                alt="Real-Time Tracking"
                                                            />
                                                            <span className="count">04</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Door to Door Pickup and Delivery</h5>
                                                            <div className="text">
                                                                Send and receive parcels from the comfort of your home. This service is ideal for
                                                                everyone, from busy professionals to elderly customers, and even businesses.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/secure-service.svg"
                                                                alt="Safe and Secure Service"
                                                            />
                                                            <span className="count">05</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Same Day & Next Day Delivery</h5>
                                                            <div className="text">
                                                                Need something shipped urgently? We offer same-day and next-day delivery to ensure your
                                                                parcels get where they need to go fast.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Temperature-Controlled Services"
                                                            />
                                                            <span className="count">06</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Temperature-Controlled Services</h5>
                                                            <div className="text">
                                                                To keep sensitive items like medicines or perishables in perfect condition during transit,
                                                                we provide temperature-controlled services.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Free Packaging"
                                                            />
                                                            <span className="count">07</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Free Packaging</h5>
                                                            <div className="text">
                                                                Your items deserve the best protection, so we offer high-quality, secure packaging at no
                                                                extra cost. We ensure your parcels remain safe throughout their journey.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Helpful Customer Support"
                                                            />
                                                            <span className="count">08</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Helpful Customer Support</h5>
                                                            <div className="text">
                                                                Our customer support team is here for you 24/7 at +91 90 2132 2132. With automated customer
                                                                service tools and an IVR system, we provide quick and efficient assistance whenever you need
                                                                it.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Careful Handling"
                                                            />
                                                            <span className="count">09</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Careful Handling</h5>
                                                            <div className="text">
                                                                Velocity Express treats every parcel with the utmost care, whether it's a delicate item or
                                                                a heavy shipment. You can trust us to handle your goods with attention and precision.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Flexible Solutions"
                                                            />
                                                            <span className="count">10</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Flexible Solutions</h5>
                                                            <div className="text">
                                                                From single packages to bulk cargo, our services accommodate all your shipping needs.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Pick and Pack Services"
                                                            />
                                                            <span className="count">11</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Pick and Pack Services</h5>
                                                            <div className="text">
                                                                We simplify order fulfillment with our efficient pick and pack services. This service saves
                                                                you valuable time and effort in gathering and packaging your items.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img
                                                                src="/imgs/location-backgroun-img/temperature-controlled-services.svg"
                                                                alt="Warehousing Solutions"
                                                            />
                                                            <span className="count">12</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Warehousing Solutions</h5>
                                                            <div className="text">
                                                                Our secure, well-organized warehouses are ideal to keep goods safe until they're ready for
                                                                shipping.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                            </div>
                        </div>
                    </div>
                </section>




                <section className="services-details pt-0">
                    <div className="container pb-2">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h3 className="mt-0">How to Book a Parcel with Velocity Express</h3>
                                    <p>Sending your parcels from Bhubaneswar is simple with just three easy steps:</p>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div
                                                    className="col-lg-4 col-md-6 col-sm-12 column"
                                                    id="pack-your-parcel"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h6 className="title">Prepare Your Parcel</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            Pack your items according to shipping standards. If you need assistance, take advantage of our
                                                            free packaging service for added convenience.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-lg-4 col-md-6 col-sm-12 column"
                                                    id="schedule-pickup"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h6 className="title">Schedule Your Pickup</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            Choose the time and location that works best for you to have your parcel collected. You can
                                                            easily schedule this on our website or by contacting our customer support team.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-lg-4 col-md-6 col-sm-12 column"
                                                    id="track-your-shipment"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h6 className="title">Track Your Shipment</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            Once your parcel is on its way, you can track its journey in real time through our tracking
                                                            system. Stay updated every step of the way.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div>
                                <h2>Frequently Asked Questions (FAQs)</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            What services does Velocity Express offer in Bhubaneswar?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers a range of services, including domestic express delivery, international express delivery, and e-commerce deliveries. Also, we provide bulk cargo shipments, air, and sea freight options.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            How can I track my shipment?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can easily track your shipment using our real-time tracking system. Simply visit our website, enter your tracking ID, and stay updated on the status and location of your parcel.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Does Velocity Express offer international shipping?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we offer international express delivery to over 150 countries worldwide. We handle all necessary customs clearance and documentation. We even offer a duty-paid delivery option for added convenience.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Is there an option for urgent deliveries?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we offer same-day and next-day delivery services to ensure your parcels are shipped quickly when you need them most.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            How does Velocity Express handle bulk cargo shipments?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    We offer a bulk cargo service that caters to large shipments, including heavy machinery or sizable inventories. Our experienced team handles each shipment with care and ensures timely, secure delivery.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 6 */}
                                    <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                                            Can I send temperature-sensitive items through Velocity Express?
                                            <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we provide temperature-controlled services for sensitive items, ensuring they remain in the right conditions throughout transit.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 7 */}
                                    <li className={`accordion block ${activeIndex === 6 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(6)}>
                                            Does Velocity Express offer e-commerce delivery solutions?
                                            <div className={`icon fa ${activeIndex === 6 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 6 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we offer specialized e-commerce delivery services with options like prepaid and cash-on-delivery. We also simplify the returns process with our reverse logistics service.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 8 */}
                                    <li className={`accordion block ${activeIndex === 7 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(7)}>
                                            What if I need help or have questions about my shipment?
                                            <div className={`icon fa ${activeIndex === 7 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 7 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Our customer support team is available 24/7 to assist you. You can reach us at +91 90 2132 2132 or through our automated customer service tools for quick support.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 9 */}
                                    <li className={`accordion block ${activeIndex === 8 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(8)}>
                                            Does Velocity Express provide packaging for my parcels?
                                            <div className={`icon fa ${activeIndex === 8 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 8 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we provide free, high-quality packaging for your parcels to ensure they remain safe and secure during transit.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 10 */}
                                    <li className={`accordion block ${activeIndex === 9 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(9)}>
                                            What areas does Velocity Express serve?
                                            <div className={`icon fa ${activeIndex === 9 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 9 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express serves both local and international destinations. Domestically, we cover over 500 cities and more than 5,000 pin codes across India. Internationally, we reach over 150 countries.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div>
    )
}

export default CourierServiceBhubaneswar
