import React from 'react';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import { Link } from 'react-router-dom';
export default function IndexHeader() {
    return(
        <header className="main-header header-style-one">
            <div className="header-top">
                <div className="top-left">
                    <ul className="list-style-one">
                        <li className="address"><i className="fa fa-map-marker-alt"></i> GF/ SF - Tejas, Opp Hotel Prasadalay, Shukrawar Peth - 411002</li>
                        <li><i className="fa fa-phone-volume"></i> <a href="tel:+919021322132">+91 90 2132 2132</a></li>
                    </ul>
                </div>

                <div className="top-right">
                    <div className="dropdown">
                        <button className="dropbtn">Welcome to Velocity</button>
                    </div>
                </div>
            </div>

           <Menu/>

           <MobileMenu/>

            <div className="sticky-header">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="logo">
                            <a href="/" title="Velocity Express"><img src="/imgs/vellocity-express-logo.png" alt="Velocity Express" title="Velocity Express" /></a>
                        </div>

                        
                        <div className="nav-outer">
                            <nav className="main-menu">
                                <div className="navbar-collapse show collapse clearfix">
                                    <ul className="navigation clearfix">
                                    <li className="current"><Link to="/">Home</Link></li>
                                        <li><Link to="/about-us">About</Link></li>
                                        <li className="dropdown">
                                            <a href="#">Services</a>
                                            <ul>
                                            <li><a href="/domestic">Domestic</a></li>
                                            <li><a href="/international">International</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/our-presence">Our Presence</a></li>
                                        <li><a href="/contact-us">Contact</a></li>
                                    </ul>
                                </div>
                            </nav>

                            <div className="mobile-nav-toggler"><span className="icon lnr-icon-bars"></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
    }