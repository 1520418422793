import React from 'react'
import ServicesForm from './ServicesForm'
import IndexHeader from './IndexHeader';

const International = () => {
    return (
        <>
         <IndexHeader/>
            <section className="page-title" style={{ backgroundImage: 'url(images/background/2.jpg)' }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1 className="title">International</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>International</li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="services-details">
                <div className="container">
                    <div className="row">
                        {/* Start Services Details Content */}
                        <div className="col-xl-8 col-lg-8">
                            <div className="services-details__content">
                                <p>
                                    Velocity Express offers unparalleled international courier services designed to meet the diverse needs of our global clientele. Our comprehensive solutions ensure that your packages are delivered safely, efficiently, and on time to destinations around the world. Here's why Velocity Express stands out in the international courier industry:
                                </p>
                                <hr />
                                <h4>Global Reach and Coverage</h4>
                                <div className="product-description__list">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Extensive Network:</b> We have a presence in over 200 countries, making us one of the largest self-operated international networks.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Strategic Locations:</b> Our strategically located offices and warehouses ensure that we can provide seamless services across different regions.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Local Expertise:</b> With offices and partners worldwide, we possess local expertise to navigate complex international shipping regulations and customs procedures.
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                                <h4>Diverse Range of Services</h4>
                                <div className="product-description__list">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>International Courier Deliveries:</b>
                                            <ul>
                                                <li>Duty Paid Services: Our duty-paid delivery options simplify the customs process, ensuring that recipients do not face any unexpected fees.</li>
                                                <li>Door-to-Door Delivery: We offer reliable door-to-door delivery services, providing convenience and peace of mind.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Air Freight Services:</b>
                                            <ul>
                                                <li>Expedited Shipping: Our air freight services ensure rapid delivery to over 50 countries, ideal for urgent shipments.</li>
                                                <li>Comprehensive Handling: From pickup to delivery, we handle all aspects of the shipping process, including customs clearance and documentation.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Sea Freight Services:</b>
                                            <ul>
                                                <li>FCL and LCL Options: We provide Full Container Load (FCL) and Less than Container Load (LCL) options to suit varying shipment sizes and budgets.</li>
                                                <li>Reliable Scheduling: Our partnerships with major shipping lines ensure reliable scheduling and competitive rates.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Warehousing Services:</b>
                                            <ul>
                                                <li>Global Warehousing: Our warehousing services in 5 key countries support logistics operations in 25+ regions.</li>
                                                <li>Advanced Facilities: Equipped with state-of-the-art technology, our warehouses ensure the safety and security of your goods.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Pick & Pack Services:</b>
                                            <ul>
                                                <li>Efficient Order Fulfillment: Our pick & pack services streamline your supply chain, ensuring accurate and timely order fulfillment.</li>
                                                <li>Customization: We offer customized solutions to meet your specific needs, from packaging to labeling.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                                <h4>Operational Excellence</h4>
                                <div className="product-description__list">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Experienced Team:</b> Our team of over 700 employees, including 200+ domestic and 100+ international operations staff, brings decades of experience to the table.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>Efficient Processes:</b> 100% automation across processes ensures efficiency and minimizes errors.
                                        </li>
                                        <li>
                                            <span className="fa fa-arrow-right"></span> <b>State-of-the-Art Infrastructure:</b> Our facilities in key locations like Delhi and Mumbai are equipped with advanced technology to support smooth operations.
                                        </li>
                                    </ul>
                                </div>

                                <hr />
                                <div className="content">
                                    <div className="text">
                                        <h3>Commitment to Quality</h3>
                                        <ul className="list-unstyled">
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Reliability:</b> We pride ourselves on our reliable services, ensuring that your packages are delivered on time, every time.
                                            </li>
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Safety:</b> We adhere to the highest safety standards, ensuring that your shipments are protected throughout the journey.
                                            </li>
                                            <li>
                                                <span className="fa fa-arrow-right"></span> <b>Sustainability:</b> Our commitment to sustainable practices ensures that we minimize our environmental impact while providing top-notch services.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* End Services Details Content */}

                        {/* Start Services Details Sidebar */}
                        <div className="col-xl-4 col-lg-4">
                            <div className="service-sidebar">
                                {/* Start Services Details Sidebar Single */}
                                <div className="sidebar-widget service-sidebar-single">
                                    <div className="service-sidebar-single-contact-box text-center wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1200m" style={{ backgroundImage: 'url(images/resource/news-2.jpg)' }}>
                                        <div className="icon">
                                            <span className="lnr lnr-icon-phone"></span>
                                        </div>
                                        <div className="title">
                                            <h2>
                                                Best <br />Logistics <br />
                                                Services
                                            </h2>
                                        </div>
                                        <p className="phone"><a href="tel:+919021322132">+91 90 2132 2132</a></p>
                                        <p>Call Us Anytime</p>

                                        {/* NEW */}
                                        <p style={{ color: '#fff', fontSize: '20px' }}>OR</p>
                                        <a href="/booking">
                                            <button className="btn btn-warning">BOOK NOW</button>
                                        </a>
                                        {/* NEW */}
                                    </div>
                                </div>
                                {/* End Services Details Sidebar Single */}

                                {/* Start Services Details Sidebar Single */}
                                <div className="sidebar-widget service-sidebar-single">
                                    <div className="service-sidebar-single-services wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1200m">
                                        <div className="title">
                                            <h3>All Services</h3>
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="/domestic">Domestic <i className="fa fa-angle-right"></i></a>
                                            </li>
                                            <li className="current">
                                                <a href="/international">International <i className="fa fa-angle-right"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Services Details Sidebar Single */}

                                {/* Form Column */}
                                <div className="form-column col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner-column">
                                        <h5>International Services</h5>
                                        <ServicesForm/>
                                    </div>
                                </div>
                                {/* End Form Column */}

                            </div>
                        </div>
                        {/* End Services Details Sidebar */}
                    </div>
                </div>
            </section>


        </>
    )
}

export default International
