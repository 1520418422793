import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer'

const CourierServiceKolkata = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title> Velocity Express: Reliable Courier Service in Kolkata</title>
                <meta name="description" content="Trust Velocity Express for reliable courier service in Kolkata. Fast, secure deliveries with professional support." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: auto;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}
        .row .col-lg-12 .listing{margin-left:15px !important; font-size: 0.88em!important}
        .row .col-lg-12 .fa{color:#ce762b;}
        .feature-block .content-box .text {font-size: 13px !important;} `}
            </style>

            <>
                <section
                    className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Reliable Courier Service in Kolkata</h1>
                                        <p className="text-light">
                                            Velocity Express’s reliable courier service in Kolkata eases the process of sending couriers within India and abroad. We provide domestic, international, and hyperlocal courier services. Having a wide reach of 150 countries worldwide, Velocity Express has a team of 700 professionals and 50 offices.
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <EnquiryForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{
                                        marginTop: '-16px',
                                        zIndex: 99,
                                        background: '#fff',
                                        position: 'relative',
                                        padding: '12px 26px',
                                        borderRadius: '6px',
                                        boxShadow: '0px 2px 5px #d3d3d3',
                                    }}
                                    data-wow-delay="600ms"
                                >
                                    <li>
                                        <a href="#domestic-express"><span>Domestic Express</span></a>
                                    </li>
                                    <li>
                                        <a href="#international-express"><span>International Express</span></a>
                                    </li>
                                    <li>
                                        <a href="#e-commerce"><span>E-commerce</span></a>
                                    </li>
                                    <li>
                                        <a href="#bulk-cargo"><span>Bulk Cargo</span></a>
                                    </li>
                                    <li>
                                        <a href="#air-and-sea-freight"><span>Air and Sea Freight</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>
                                    Our customer-centric approach and competitive shipping rates make us one of the best courier services in Kolkata. Our expedited shipping options and on-time delivery make us the top choice to ship personal or commercial goods. Velocity Express also takes care of e-commerce deliveries.
                                </p>
                                <hr />
                                <h2>Velocity Express: Fast, Efficient Courier Services in Kolkata</h2>
                                <p>
                                    Shipping your parcels locally and internationally from Kolkata is going to be hassle-free with Velocity Express. Our 200+ local team and 100+ international team make sure your packages arrive safely and at the given time.
                                </p>
                                <p>
                                    Our swift and trustworthy courier service makes use of advanced technology. You can book your parcel online and use our real-time tracking to know the status of your shipment. You will also receive automated updates of every stage so you can rest assured of your parcels being in safe hands.
                                </p>
                                <p>
                                    That is not all, our customer support team is available round the clock to answer your queries. Our IVR and automated customer service tools provide timely assistance. So, choose Velocity Express to be your delivery partner.
                                </p>

                                <hr />
                                <h2>Our Services at Velocity Express</h2>

                                <p>Velocity Express offers exclusive services that make local and global shipping seamless. Here is what we provide:</p>

                                <div className="row py-4">
                                    {/* Domestic Express Delivery */}
                                    <div className="col-lg-3 col-md-3 col-sm-6" id="domestic-express" style={{ scrollMarginTop: "140px" }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Domestic Express Delivery</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Our domestic express delivery service from Kolkata focuses on speed and reliability, ensuring your packages reach any destination across India right on time. Our logistics network spans over 500 cities and more than 7,000 pin codes. We offer extensive reach and efficient delivery solutions. Strategically located warehouses streamline our operations, enabling swift dispatch and timely deliveries—even to the most remote locations.
                                            </p>
                                        </div>
                                    </div>

                                    {/* International Express Delivery */}
                                    <div className="col-lg-3 col-md-3 col-sm-6" id="international-express" style={{ scrollMarginTop: "140px" }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">International Express Delivery</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Sending parcels to your loved ones overseas is simple with Velocity Express’s international express delivery service. Our access to more than 150 countries worldwide makes us one of the largest self-operated international networks. We also take care of customs clearance and documentation. For the convenience of our customers, we offer duty paid delivery option. So, shipping goods globally is a stress-free process with Velocity Express.
                                            </p>
                                        </div>
                                    </div>

                                    {/* E-commerce Deliveries */}
                                    <div className="col-lg-3 col-md-3 col-sm-6" id="e-commerce" style={{ scrollMarginTop: "140px" }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">E-commerce Deliveries</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                This service is ideal for online businesses and their customers. With our prepaid and cash-on-delivery options, we make shopping easy. Also, our reverse logistics simplifies the return process. Our e-commerce delivery service supports online stores while keeping customer satisfaction at the forefront.
                                            </p>
                                        </div>
                                    </div>

                                    {/* Bulk Cargo Deliveries */}
                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2" id="bulk-cargo" style={{ scrollMarginTop: "140px" }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Bulk Cargo Deliveries</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Sending large shipments is now effortless with our bulk cargo delivery service. Whether you want to send heavy machinery or any huge inventory, Velocity Express simplifies the process. Our well-trained team will handle the entire process with precision and care. So, you can relax knowing your shipment will arrive safely and on time at its destination.
                                            </p>
                                        </div>
                                    </div>

                                    {/* Air and Sea Freight */}
                                    <div className="col-lg-12 col-md-3 col-sm-6 mt-2" id="air-and-sea-freight" style={{ scrollMarginTop: "140px" }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="On-time Delivery" />
                                            <p className="pTagtitle mt-2 mb-1">Air and Sea Freight</p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                Have an urgent shipment? Choose our air-freight service, available to over 50 countries. We take care of every logistical detail, ensuring your shipments arrive safely and on schedule.
                                            </p>
                                            <p className="text" style={{ fontSize: "0.83em" }}>
                                                We also provide sea freight service with Full Container Load (FCL) and Less than Container Load (LCL) options. Our tie-up with major shipping lines ensures timely delivery at a competitive price.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2 className="product-description__title">What to Expect from Velocity Express</h2>
                                            <p>Velocity Express's courier service in Kolkata offers an exceptional shipping experience, customized to meet your needs. You can expect</p>
                                            <div className="row">
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">01</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Fast and Reliable Service</h5>
                                                            <div className="text">Velocity Express provides efficient, on-time deliveries that ensure your shipments reach their destination without delays.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Transparent Pricing" />
                                                            <span className="count">02</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Transparent Pricing</h5>
                                                            <div className="text">Our shipping charges are pocket-friendly and transparent with no hidden costs. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Real-Time Tracking" />
                                                            <span className="count">03</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Real-Time Tracking</h5>
                                                            <div className="text">Learn about the status of your shipment with our real-time tracking system. Visit our website, enter your <a href="https://velexp.com/track/">tracking ID </a>, and check the position of your parcel. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/real-time-tracking.svg" alt="Door-to-Door Pickup and Delivery" />
                                                            <span className="count">04</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Door to Door Pickup and Delivery</h5>
                                                            <div className="text">Customers can send and receive parcels from the comfort of their homes. Working class, elderly people, and even businesses can take advantage of this service.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/secure-service.svg" alt="Same Day & Next Day Delivery" />
                                                            <span className="count">05</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Same Day & Next Day Delivery</h5>
                                                            <div className="text">We provide same-day and next-day delivery for parcels that need to be shipped quickly.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Temperature-Controlled Services" />
                                                            <span className="count">06</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Temperature-Controlled Services</h5>
                                                            <div className="text">We at Velocity Express understand the importance of maintaining the right temperature for sensitive items. Hence, we provide temperature-controlled service. You can use this service to send medicines or perishables.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Free Packaging" />
                                                            <span className="count">07</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Free Packaging</h5>
                                                            <div className="text">Your items deserve top-notch protection, which is why we provide secure packaging at no additional charge. We ensure your packages remain safe throughout transit, giving you peace of mind every step of the way.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Helpful Customer Support" />
                                                            <span className="count">08</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Helpful Customer Support</h5>
                                                            <div className="text"> Our customer support is available at your service 24x7 at +91 90 2132 2132. Our automated customer service tools and IVR provide timely support.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Careful Handling" />
                                                            <span className="count">09</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Careful Handling</h5>
                                                            <div className="text">For Velocity Express, every parcel be it a small delicate item or heavy goods, is important. So, we handle every shipment with utmost care and attention.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Flexible Solutions" />
                                                            <span className="count">10</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Flexible Solutions</h5>
                                                            <div className="text">With us, you can send a single package or bulk cargo. Our services cater to all kinds of shipping needs.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Pick and Pack Services" />
                                                            <span className="count">11</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Pick and Pack Services</h5>
                                                            <div className="text">We simplify order fulfillment with our pick and pack services. We efficiently gather and package your items, saving you valuable time and effort.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Warehousing Solutions" />
                                                            <span className="count">12</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Warehousing Solutions</h5>
                                                            <div className="text">Our warehouses are thoughtfully designed to provide optimal storage for your goods. So, you can rest assured your parcels remain safe and secure until they're ready to ship.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0">
                    <div className="container pb-2">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h3 className="mt-0">How to Book a Parcel with Velocity Express</h3>
                                    <p>Follow these three easy steps to send your parcels from Kolkata</p>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                {/* Prepare Your Parcel */}
                                                <div
                                                    className="col-lg-4 col-md-6 col-sm-12 column"
                                                    id="pack-your-parcel"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h6 className="title">Prepare Your Parcel</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            Pack your items according to shipping standards. If you find it difficult, use our free packaging service.
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* Schedule Your Pickup */}
                                                <div
                                                    className="col-lg-4 col-md-6 col-sm-12 column"
                                                    id="schedule-pickup"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h6 className="title">Schedule Your Pickup</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            Schedule the best time and location to collect your parcel. You can do this on our website or inform our customer support.
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* Track Your Shipment */}
                                                <div
                                                    className="col-lg-4 col-md-6 col-sm-12 column"
                                                    id="track-your-shipment"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h6 className="title">Track Your Shipment</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            Once your parcel is out for delivery, keep track of it using our real-time tracking system.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="">
                                <h2>Frequently Asked Questions (FAQs)</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            What services does Velocity Express offer in Kolkata?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers a range of courier services. It includes domestic express delivery, international express delivery, e-commerce deliveries, bulk cargo deliveries, air and sea freight services, and temperature-controlled shipping.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            How can I track my parcel with Velocity Express?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can easily track your shipment using our real-time tracking system. Simply visit our website, enter your tracking ID, and view the status of your parcel.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Does Velocity Express offer same-day or next-day delivery?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we provide same-day and next-day delivery options for urgent shipments. This ensures your parcels arrive as quickly as possible.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Can I send parcels internationally from Kolkata with Velocity Express?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express provides international express delivery to over 150 countries worldwide. We focus on timely delivery and handling of customs clearance and documentation.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            How does Velocity Express ensure the safety of my shipments?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    We prioritize the safety of your items. We provide secure packaging at no extra cost. You can track your shipment in real-time. This way, you can monitor its status throughout the journey.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 6 */}
                                    <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                                            How does Velocity Express support e-commerce businesses?
                                            <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express supports e-commerce businesses by offering flexible shipping solutions, including prepaid and cash-on-delivery options. Our efficient reverse logistics service also makes returns easy for customers. This ensures a smooth and hassle-free experience for both businesses and their clients.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 7 */}
                                    <li className={`accordion block ${activeIndex === 6 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(6)}>
                                            Can I use Velocity Express for bulk cargo shipments from Kolkata?
                                            <div className={`icon fa ${activeIndex === 6 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 6 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express offers a bulk cargo delivery service, ideal for sending large shipments. We handle all logistics with precision and care to ensure timely and safe delivery.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 8 */}
                                    <li className={`accordion block ${activeIndex === 7 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(7)}>
                                            Does Velocity Express offer free packaging service in Kolkata?
                                            <div className={`icon fa ${activeIndex === 7 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 7 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we offer free secure packaging to ensure the items remain intact during their journey.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 9 */}
                                    <li className={`accordion block ${activeIndex === 8 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(8)}>
                                            What is the process to book a parcel with Velocity Express?
                                            <div className={`icon fa ${activeIndex === 8 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 8 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Booking is simple. First, prepare your parcel, then schedule a pickup through our website or customer support. Finally, track your shipment using our real-time tracking system.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 10 */}
                                    <li className={`accordion block ${activeIndex === 9 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(9)}>
                                            How can I contact customer support if I have an issue?
                                            <div className={`icon fa ${activeIndex === 9 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 9 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Our customer support team is available 24/7. You can reach us at +91 90 2132 2132, or use our automated IVR system for timely assistance.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>

                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div >
    )
}

export default CourierServiceKolkata
