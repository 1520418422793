import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';

export default function Menu() {
    // State management for the AWB number, tracking info, and status
    const [awbno, setAwbno] = useState('');
    const navigate = useNavigate();
   
    // Handler for the "Track" button click
    const handleTrackButtonClick = async () => {
      const awbnoTrimmed = awbno.trim();
    
      if (awbnoTrimmed === '') {
        alert('Enter Tracking No.');
        return;
      }
    
      try {
        const response = await axios.post('http://192.168.1.222:8000/tracking/', {
          awbno: awbnoTrimmed,
        });
        
        if (response.status !== 200) {
          console.error('Error! Status:', response.status);
        }  else {
          // Navigate to the Track component and pass the data via state
          navigate('/track', {
            state: {
              trackingData: {
                awbno: awbnoTrimmed,
                status: response.statusText,
                details: response.data, // Assuming data contains the tracking details
              },
            },
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    return(
        <div className="header-lower">
      <div className="main-box">
        <div className="logo-box">
          <div className="logo">
            <a href="/"><img src="/imgs/vellocity-express-logo.png" style={{ height: '50px' }} alt="Velocity Express" title="Velocity Express" /></a>
          </div>
        </div>
        <div className="nav-outer">
        <nav className="nav main-menu">
          <ul className="navigation">
            <li className="current"><Link to="/">Home</Link></li>
            <li><Link to="/about-us">About</Link></li>
            <li className="dropdown">
              <a href="#">Services</a>
              <ul>
                <li><Link to="/domestic">Domestic</Link></li>
                <li><Link to="/international">International</Link></li>
              </ul>
            </li>
            <li><Link to="/our-presence">Our Presence</Link></li>
            <li><Link to="/contact-us">Contact</Link></li>
          </ul>
        </nav>  

          <div className="outer-box header_track">
            <div className="tracking-form gap-2">
              <div class="px-1 py-1" style={{borderRadius:'22%',}}>
                <a className="py-2 px-3" href="/userlogin" style={{color: '#ef7b1b',fontWeight: 'bold',borderRadius: '10px',border: '1px solid #ef7b1b',}}>
                <img src="/imgs/user_login.svg" style={{ height: '18px' }} className="user-login" alt="User Login"/>{' '}LOGIN</a>
              </div>

              <div className="d-flex gx-0">
                <div className="form-group">
                <input
                  type="text"
                  value={awbno}
                  onChange={(e) => setAwbno(e.target.value)}
                  placeholder="AWB | Order Id"
                />
                </div>
                <div className="form-group text-center">
                <button type="button" className="theme-btn btn-style-one" onClick={handleTrackButtonClick}>
                    <span className="btn-title">Track</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="mobile-nav-toggler"><span className="icon lnr-icon-bars"></span></div>
          </div>
        </div>
      </div>
    </div>
    );
}