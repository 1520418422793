import React, { useEffect } from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const ProjectCarousel = () => (
    <ReactOwlCarousel
        className="owl-theme project-carousel"
        loop={true}
        margin={10}
        nav
        dots
        autoplay={true}             // Enable autoplay
        autoplayTimeout={3000}      // Time between slide transitions (in milliseconds)
        autoplayHoverPause={true}   // Pause autoplay on hover
        responsive={{
            0: { items: 1 },       // Show 1 item for small screens (mobile)
            600: { items: 2 },     // Show 2 items for medium screens (tablets)
            1000: { items: 3 },    // Show 3 items for large screens (desktops)
            1200: { items: 4 },    // Show 4 items for very large screens (wide desktops)
        }}
    >
        {/* Project Block 1 */}
        <div className="project-block">
            <div className="inner-box">
                <div className="image-box">
                    <figure className="image">
                        <a href="#" className="lightbox-image">
                            <img src="/imgs/bulk-cargo.jpg" alt="bulk-cargo" />
                        </a>
                    </figure>
                    <a href="#" className="icon"><i className="fa fa-plus"></i></a>
                </div>
                <div className="content-box">
                    <span className="sub-title">API</span>
                    <h4 className="title"><a href="#">Integrated Booking</a></h4>
                </div>
            </div>
        </div>

        {/* Project Block 2 */}
        <div className="project-block">
            <div className="inner-box">
                <div className="image-box">
                    <figure className="image">
                        <a href="#" className="lightbox-image">
                            <img src="/imgs/e-commerce.jpg" alt="e-commerce" />
                        </a>
                    </figure>
                    <a href="#" className="icon"><i className="fa fa-plus"></i></a>
                </div>
                <div className="content-box">
                    <span className="sub-title">API</span>
                    <h4 className="title"><a href="#">Powered Tracking</a></h4>
                </div>
            </div>
        </div>

        {/* Project Block 3 */}
        <div className="project-block">
            <div className="inner-box">
                <div className="image-box">
                    <figure className="image">
                        <a href="#" className="lightbox-image">
                            <img src="/imgs/sameday-delivery.jpg" alt="sameday-delivery" />
                        </a>
                    </figure>
                    <a href="#" className="icon"><i className="fa fa-plus"></i></a>
                </div>
                <div className="content-box">
                    <span className="sub-title">Automated</span>
                    <h4 className="title"><a href="#">Reports</a></h4>
                </div>
            </div>
        </div>

        {/* Project Block 4 */}
        <div className="project-block">
            <div className="inner-box">
                <div className="image-box">
                    <figure className="image">
                        <a href="#" className="lightbox-image">
                            <img src="/imgs/wharehouse.jpg" alt="wharehouse" />
                        </a>
                    </figure>
                    <a href="#" className="icon"><i className="fa fa-plus"></i></a>
                </div>
                <div className="content-box">
                    <span className="sub-title">IVR and Automated</span>
                    <h4 className="title"><a href="#">Customer Service</a></h4>
                </div>
            </div>
        </div>
    </ReactOwlCarousel>
);

export default ProjectCarousel;
