import React , { useEffect, useRef, useState} from 'react'
import Footer from './Footer';
import useEstimate from './Estimate'; // Import the custom hook
import Select from 'react-select/async';// Async Select for lazy loading
import ProjectCarousel from "./ProjectCarousel";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import IndexHeader from './IndexHeader';
import axios from 'axios';



export default function Header() {
    
    const [isFlipped, setIsFlipped] = useState(false);

    //const handleEstimateClick = () => setIsFlipped(true);
    const handleFlipToFront = () => setIsFlipped(false);

    const {
        origin,
        setOrigin,
        destination,
        setDestination,
        weight,
        setWeight,
        service,
        setService,
        weightunit,
        setWeightunit,
        errors,
        setErrors,
        handleEstimateClick,
        fromCity,
        fromPincode,
        toCity,
        toPincode,
        amount,
        serviceLevel,
        displayWeight,
    } = useEstimate({ setIsFlipped }); // Use the custom hook

    const phoneInputRef = useRef(null); // Ref for the phone input

    const [full_name, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedService, setSelectedService] = useState('-1');
    const [selectedType, setSelectedType] = useState('-1');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [enquiryLoading, setEnquiryLoading] = useState(false);
    

    useEffect(() => {
        // Initialize intl-tel-input
        const iti = intlTelInput(phoneInputRef.current, {
          separateDialCode: true,
          preferredCountries: ['us', 'gb', 'in'],
          initialCountry: 'in',
        });
        // Trigger the countrychange event manually to update the dial code
        const updatePhone = () => {
            setPhone(iti.selectedCountryData.dialCode);
        };

        // Trigger the initial countrychange event
        updatePhone();
    
        // Event listener to update phone state when the user interacts with intl-tel-input
        phoneInputRef.current.addEventListener('countrychange', () => {
          setPhone(iti.selectedCountryData.dialCode); // Update phone number in international format
        });
    
        return () => {
          iti.destroy(); // Cleanup when the component unmounts
        };
      }, []);

    // Fetch options based on user input
    const fetchOptions = async (inputValue) => {
        if (inputValue.length < 3) {
            return []; // Only fetch if the input is 3+ characters
        }
    
        setLoading(true);
        try {
            const response = await fetch('http://192.168.1.222:8000/get_cities_pincode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ search: inputValue }) // Send the search term
            });
    
            // Check if the response status is OK (status 200)
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
    
            // Parse response data as JSON
            const result = await response.json();
    
            // Ensure `result.entries` exists and is an array
            if (!result.entries || !Array.isArray(result.entries)) {
                throw new Error('Unexpected data format');
            }
    
            // Map results to the format AsyncSelect expects
            const formattedOptions = result.entries.map(item => ({
                value: item.pincode,
                label: `${item.district}`
            }));
    
            setLoading(false);  // Reset loading state
            return formattedOptions; // Return options to AsyncSelect
        } catch (error) {
            setLoading(false);
            setError('Failed to load data', error);
            return [];  // Return empty array if an error occurs
        }
    };

    
    const clearErrorAfterTimeout = (field) => {
        setTimeout(() => {
            setErrors(prev => ({ ...prev, [field]: "" }));
        }, 3000); // Clears the error after 3 seconds
    };
    //let isValid = true;
    const handleSendRequest = async (event) => {
        event.preventDefault(); // Prevent default form submission
        let isValid = true; // Initialize validation flag
        setEnquiryLoading(true);
        if(full_name === ""){
            setErrors(prev => ({ ...prev, full_name: "Name is mandatory" }));
            clearErrorAfterTimeout('full_name');
            isValid = false;
        }
        if (!email) {
            // Show "Email cannot be blank" inside the input as a placeholder
            setErrors(prev => ({ ...prev, email: "Email is mandatory" }));
            clearErrorAfterTimeout('email');
            isValid = false;
        } else if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)) {
            // Show "Invalid email format" below the input
            setErrors(prev => ({ ...prev, email: "invalid" }));
            clearErrorAfterTimeout('email');
            isValid = false;
        } 
       
        const phonecode = phone
        const phonenumber = phoneInputRef.current.value
        // Assuming phone is the phone code and phoneInputRef holds the phone number input

        // Validate both phonecode and phonenumber
        if (phonenumber === "") {
            // If phone number is empty
            setErrors((prev) => ({ ...prev, phone: "Phone number is mandatory" }));
            clearErrorAfterTimeout("phone");
            isValid = false;
        } else if (!/^\d+$/.test(phonenumber)) {
            // If phone number contains non-numeric characters
            setErrors((prev) => ({ ...prev, phone: "Phone number must be digits only" }));
            clearErrorAfterTimeout("phone");
            isValid = false;
        } else {
            // Clear error if phone number is valid
            setErrors((prev) => ({ ...prev, phone: "" }));
        }

        if (selectedService === "-1") {
            setErrors(prev => ({ ...prev, selectedService: "Select Services Type" }));
            clearErrorAfterTimeout('selectedService');
            isValid = false;
        }  
        
        if (selectedType === "-1") {
            setErrors(prev => ({ ...prev, selectedType: "Select Type" }));
            clearErrorAfterTimeout('selectedType');
            isValid = false;
        } 
        // Exit early if validation fails
        if (!isValid) {
            return;
        }
       try {
            const response = await axios.post('http://192.168.1.222:8000/send-enquiry/', {
                full_name: full_name,
                email: email,
                phonecode: phonecode,
                phonenumber: phonenumber,
                selectedService: selectedService,
                selectedType: selectedType,
            });
            if (response.data.status === 200) {
                setErrors(prev => ({ ...prev, mailMessage: response.data.msg }));
                setFullname('');
                setEmail('');
                setPhone('');
                setSelectedService('-1');
                setSelectedType('-1');
                if (phoneInputRef.current) {
                    phoneInputRef.current.value = '';
                }
                setTimeout(() => {
                    setErrors({ mailMessage: '' });
                }, 3000);
            } else {
                setErrors(prev => ({ ...prev, mailMessage: 'An error occurred. Please try again.' }));
            }
        } catch (error) {
            console.error('Error sending request:', error);
        }finally {
            setEnquiryLoading(false); // Reset loading state
        }
    };

    // Handle change when user selects an option
    const handleServiceChange = (event) => {
        setSelectedService(event.target.value);
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };   

 
  return (
   <>
   <IndexHeader/>
    <div className="page-wrapper"> 
        <section id="content" className="hero_section">
				<div className="content-wrap py-0">
					<div className="position-relative">
						<div className="auto-container start-0 mt-2 mb-3 mb-lg-3 smalltagstyle">
							<div className="row">
								<div className="col-xl-6 col-lg-5">
									<h1 className="display-1 text-black mb-2">Your <span>one-stop shop</span> for comprehensive logistic solutions</h1>
									<div className="tabs-box">
										<div className="tab-btn-box text-center mb-2">
											<ul className="tab-btns tab-buttons d-flex gap-2">
												<li className="tab-btn active-btn border px-3" data-tab="#tab-1">Domestic</li>
												<li className="border px-3" >International</li>
											</ul>
										</div>

                                        <div className="tabs-content">
											<div className="tab active-tab" id="tab-1">
												<div className="col-lg-8">
                                                    <div className="flip-card-3D-wrapper">
                                                        <div className={isFlipped ? "do-flip" : ""} id="flip-card">
                                                            <div className="flip-card-front">
                                                                <div className="title-box">
                                                                    <div className="bg-velexp text border px-4 py-2">
                                                                        <div className="row home-moving-form position-relative mt-1 mb-0">
                                                                            <div className="form-process">
																				<div className="css3-spinner">
																					<div className="css3-spinner-scaler"></div>
																				</div>
																			</div>
                                                                            <div className="col-sm-12">
                                                                                <div className="mb-0">
                                                                                <Select
                                                                                    className="select-2 form-select"
                                                                                    id="first_mile"
                                                                                    value={origin.district && origin.pincode ? { value: origin.pincode, label: `${origin.district} - ${origin.pincode}` } : null}  // Show district - pincode only if both are set
                                                                                    onChange={(selectedOption) => setOrigin(selectedOption ? { district: selectedOption.label.split(' - ')[0], pincode: selectedOption.value } : null)}  // Extract district and pincode
                                                                                    loadOptions={fetchOptions}
                                                                                    onInputChange={(value) => setInputValue(value)}
                                                                                    placeholder="Select origin city/pincode"
                                                                                    isSearchable
                                                                                    styles={{ width: '100%' }}
                                                                                    isLoading={loading}
                                                                                    noOptionsMessage={() => 
                                                                                        inputValue.length < 3 ? 'Please enter 2 or more characters' : 
                                                                                        (loading ? 'Loading...' : error || 'No options')
                                                                                    }
                                                                                />
                                                                                {errors.origin && <div className="text-danger">{errors.origin}</div>}                                                                   
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 mb-0 text-center">
                                                                                <img src="images/icons/sort.svg" alt="sort by dentination" style={{width:'20px', color: 'red'}} />
                                                                            </div>

                                                                            <div className="col-sm-12">
                                                                                <div className="mb-2">
                                                                                <Select
                                                                                className="select-2 form-select"
                                                                                id="last_mile"
                                                                                value={
                                                                                    destination.district && destination.pincode
                                                                                        ? { value: destination.pincode, label: `${destination.district} - ${destination.pincode}` }
                                                                                        : null
                                                                                } // Show district - pincode only if both are set
                                                                                onChange={(selectedOption) =>
                                                                                    setDestination(
                                                                                        selectedOption
                                                                                            ? { district: selectedOption.label.split(' - ')[0], pincode: selectedOption.value }
                                                                                            : null
                                                                                    )
                                                                                } // Extract district and pincode
                                                                                loadOptions={(inputValue) => {
                                                                                    setLoading(true); // Destination-specific loading state
                                                                                    return fetchOptions(inputValue).finally(() => setLoading(false));
                                                                                }}
                                                                                onInputChange={(value) => setInputValue(value)} // Destination-specific input value
                                                                                placeholder="Select destination city/pincode"
                                                                                isSearchable
                                                                                styles={{ width: '100%' }}
                                                                                isLoading={loading}
                                                                                noOptionsMessage={() =>
                                                                                    inputValue.length < 3
                                                                                        ? 'Please enter 2 or more characters'
                                                                                        : loading
                                                                                        ? 'Loading...'
                                                                                        : error || 'No options'
                                                                                }
                                                                            />

                                                                                {errors.destination && <div className="text-danger">{errors.destination}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-12 form-group mt-3">
                                                                                <select className="form-select py-1 ps-2" id="service_level" value={service} onChange={(e) => setService(e.target.value)}>
                                                                                    <option value="Regular">Regular</option>
                                                                                    <option value="Same day">Same Day Delivery</option>
                                                                                    <option value="Next day">Next Day Delivery</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className="col-sm-12 form-group mt-3 d-flex">
                                                                                <div className="input-group mx-auto" style={{ width:'70%'}}>
                                                                                    <div className="input-group-text"><img src="/images/icons/weight.svg" alt="weight" /></div>
                                                                                    <input type="text" id="weight" name="weight" className="form-control required" placeholder="Total Weight" value={weight} onChange={(e) => setWeight(e.target.value)}/>                                                                    
                                                                                </div>
                                                                                <select className="form-select form-select-sm ps-2" style={{width:'30%'}} id="weight_unit" value={weightunit} onChange={(e) => setWeightunit(e.target.value)}>
                                                                                    <option value="kg">KG</option>
                                                                                    <option value="gm">GM</option>
                                                                                </select>                                                               
                                                                            </div>
                                                                            {errors.weight && <div className="text-danger">{errors.weight}</div>}

                                                                            <div className="col-sm-12 col-md-12 form-group mt-3">
                                                                                <a onClick={handleEstimateClick} className="button btn-dark button-xlarge py-2 shipdost_btn_style w-100 rounded-3 align-item-center justify-content-center gap-2 d-flex">
                                                                                    <span>Get Estimate</span> <img src="/images/icons/arrow-right.svg" alt="arrow-right" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                                
                                                            </div>

                                                            <div className="flip-card-back">
                                                                <div className="title-box estimate-result">
                                                                    <div className="bg-velexp text border px-4 py-3">
                                                                        <div className="row align-items-center justify-content-center d-flex">
                                                                            <div className="col-md-6 col-6">
																				<div className="bg-light rounded-3 px-2 py-0 mb-2">
																					<small> From </small>
																					<h6 style={{ margin: 0, lineHeight: "14px" }} id="ge_from_city"> {fromCity} </h6>
																					<p className="mb-0" id="ge_from_pincode"> {fromPincode} </p>
																				</div>
																			</div>

                                                                            <div className="col-md-6 col-6">
																				<div className="bg-light rounded-3 px-2 py-0 mb-2">
																					<small> To </small>
																					<h6 style={{ margin: 0, lineHeight: "14px" }} id="ge_to_city"> {toCity} </h6>
																					<p className="mb-0" id="ge_to_pincode"> {toPincode} </p>
																				</div>
																			</div>

                                                                            <div className="col-md-6 col-6">
																				<div className="bg-light rounded-3 px-2 py-0 mb-2">
																					<small> Total Weight </small>
																					<h6 style={{ margin: 0, lineHeight: "14px" }} id="ge_weight"> {displayWeight} </h6>
																					<p className="mb-0" id="ge_unit"> Kgs </p>
																				</div>
																			</div>

                                                                            <div className="col-md-6 col-6">
																				<div className="bg-light rounded-3 px-2 py-0 mb-2">
																					<small> Amount </small>
																					<h6 style={{ margin: 0, lineHeight: "14px" }} id="ge_amount"> {amount} </h6>
																					<p className="mb-0" id="ge_currency"> INR </p>
																				</div>
																			</div>

                                                                            <div className="col-md-6 col-6">
																				<div className="bg-light rounded-3 px-2 py-0 mb-2" style={{height: '60px', textTransform:'capitalize'}}>
																					<small> Service Level </small>
																					<h6 style={{ margin: 0, lineHeight: "14px" }} id="ge_service_level"> {service} </h6>
																				</div>
																			</div>

                                                                            <div className="col-md-6 col-6">
																				<div className="bg-light rounded-3 px-2 py-0 mb-2" style={{height: '60px', textTransform:'capitalize'}}>
																					<small> Product Type </small>
																					<h6 style={{ margin: 0, lineHeight: "14px" }} id="ge_product_type"> Parcels </h6>
																				</div>
																			</div>

                                                                            <div className="col-md-12 pb-2">
																				<div className="d-flex justify-content-between mt-2">
																					<a className="button btn-dark button-xlarge py-1 px-3 shipdost_btn_style rounded-3" ><span className="btn-title">Book Now</span></a>
																					
																					<button id="flip-card-btn-turn-to-front" style={{background: "none", color: "#a1a1a1"}} onClick={handleFlipToFront}>Back</button>
																				</div>
																			</div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                                                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
										
                                       
									</div>
								</div>


								<div className="col-md-6">
									<div className="row same_font_h4">
										<div className="col-md-6 col-12">
											<div className="rounded-60 globe" data-animate="zoomIn">
												<div className="content_globe">
													<h4><span>Domestic, Hyperlocal</span> & <strong>International</strong></h4>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="rounded-60 delivery_boy d-flex align-items-center" data-animate="zoomIn fast" data-delay="500">
												<div className="content_delivery_boy">
													<h4><span>Your dost</span> <br/> <strong>at each step</strong></h4>
												</div>
											</div>

											<div className="rounded-60 mt-4 pickup_boy" data-animate="zoomIn fast" data-delay="300">
												<div className="content_pickup_boy">
													<h4><span>Free Pickup &</span><br/> <strong> Packing</strong></h4>
												</div>
											</div>
										</div>

										<div className="col-12 mt-4">
											<div className="rounded-60 d-flex align-item-center air_freight" data-animate="zoomIn fast" data-delay="300">
												<div className="content_air_freight">
													<h4><span>Air Freight,</span><br/> <strong> Swift and Relable</strong></h4>
												</div>
											</div>
										</div>


									</div>
								</div>

							</div>
                            
						</div>

					</div>

				</div>
			</section>

        

        <section className="features-section">
            <div className="auto-container">
                <div className="row">
                    <div className="feature-block col-lg-4 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="icon-box">
                                <i className="icon flaticon-logistics-delivery-6"></i>
                                <span className="count">01</span>
                            </div>
                            <div className="content-box">
                                <h4 className="title">
                                    <a >Efficient <br /> pricing</a >
                                </h4>
                                <div className="text">we use resources wisely to offer competitive rates</div>
                            </div>
                        </div>
                    </div>

                  
                    <div className="feature-block col-lg-4 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="icon-box">
                                <i className="icon flaticon-fast-delivery-1"></i>
                                <span className="count">02</span>
                            </div>
                            <div className="content-box">
                                <h4 className="title">
                                    <a >Fast-Track <br /> Shipping</a>
                                </h4>
                                <div className="text">Your packages arrive faster than ever.</div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="feature-block col-lg-4 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="icon-box">
                                <i className="icon flaticon-container-2"></i>
                                <span className="count">03</span>
                            </div>
                            <div className="content-box">
                                <h4 className="title"><a >Warehouse operation</a></h4>
                                <div className="text">Efficient use of space and resources.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section pt-0">
            <div className="anim-icons">
                <div className="float-image wow fadeInRight"><img src="/images/resource/float-img-1.png" alt="float-img" /></div>
                <span className="icon icon-dots-1 bounce-x"></span>
                <span className="icon icon-dotted-map zoom-one"></span>
            </div>

            <div className="auto-container">
                <div className="row">
                    <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                        <div className="inner-column">
                            <div className="sec-title">
                                <span className="sub-title">Velocity Express </span>
                                <h2>We provide Domestic and International  Express Delivery</h2>
                                <div className="text">Our express delivery services cater to both domestic and international destinations. Experience fast, reliable shipping with our dedicated courier solutions.</div>
                            </div>

                            <div className="content-box">
                                <div className="about-block">
                                    <i className="icon flaticon-worldwide-shipping"></i>
                                    <h4 className="title">Domestic Service</h4>
                                    <p className="text">We offer E-commercce deliveries, Bulk cargo deliveries, special deliveries and special services.</p>
                                </div>

                                <div className="about-block">
                                    <i className="icon flaticon-3d-cube"></i>
                                    <h4 className="title">International Service</h4>
                                    <p className="text">We provide Courier Delivery, warehousing, pick & pack service, Imports.</p>
                                </div>
                            </div>

                            <div className="btm-box">
                                <a href="/about" className="theme-btn btn-style-one"><span className="btn-title">Explore More</span></a>
                            </div>
                        </div>
                    </div>

                  
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <figure className="image-1"><img src="/imgs/bulk-cargo.jpg" alt="bulk-cargo" /></figure>
                            <figure className="image-2"><img src="/imgs/e-commerce.jpg" alt="e-commerce" /></figure>
                            <div className="experience">
                                <strong
                                    ><i className="icon flaticon-global"></i> 40<br />
                                    Years</strong
                                >
                                Working Exprience
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="services-section">
            <div className="anim-icons">
                <span className="icon icon-wave-line"></span>
            </div>

            <div className="auto-container">
                <div className="sec-title text-center">
                    <span className="sub-title">Leaders in Express Delivery</span>
                    <h2>International logistics specialists</h2>
                </div>

                <div className="row">
                    <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                        <div className="inner-box">
                            <div className="image-box">
                                <figure className="image">
                                    <img src="/imgs/air-frieght.jpg" alt="Air Freight service" />
                                </figure>
                            </div>
                            <div className="content-box">
                                <i className="icon flaticon-airplane-2"></i>
                                <span className="sub-title">01 Service</span>
                                <h4 className="title"><a >Air Freight service</a></h4>
                                <div className="text">Hassle-free duty-paid services across 50+ countries.</div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
                        <div className="inner-box">
                            <div className="image-box">
                                <figure className="image">
                                    <img src="/imgs/sea-freight.jpg" alt="Sea Freight service" />
                                </figure>
                            </div>
                            <div className="content-box">
                                <i className="icon flaticon-cargo-boat"></i>
                                <span className="sub-title">02 Service</span>
                                <h4 className="title"><a >Sea Freight service</a></h4>
                                <div className="text">Global FCL and LCL Coverage: Serving 25+ countries worldwide.</div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms">
                        <div className="inner-box">
                            <div className="image-box">
                                <figure className="image">
                                    <img src="/imgs/international-ware-house.jpg" alt="Sea Freight service" />
                                </figure>
                            </div>
                            <div className="content-box">
                                <i className="icon flaticon-delivery-truck-3"></i>
                                <span className="sub-title">03 Service</span>
                                <h4 className="title"><a >Global warehousing</a></h4>
                                <div className="text">Strategic warehouse across 5 countries, delivering to 25+ countries.</div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="service-block col-xl-3 col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="900ms">
                        <div className="inner-box">
                            <div className="image-box">
                                <figure className="image">
                                    <img src="/imgs/pick-and-pack.jpg" alt="Sea Freight service" />
                                </figure>
                            </div>
                            <div className="content-box">
                                <i className="icon flaticon-delivery-box-4"></i>
                                <span className="sub-title">04 Service</span>
                                <h4 className="title"><a >Other solution</a></h4>
                                <div className="text">Serving 25+ countries with centers in 5 countries.<br/><br/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="tracking-section pull-down">
            <div className="auto-container">
                <div className="outer-box">
                    <div className="arrow-box wow fadeInRight">
                        <img src="/images/icons/arrow-2.png" alt="Results in few seconds" className="icon" />
                        <span className="title">Results in <br />few seconds</span>
                    </div>
                    <div className="tracking-form">
                        <h4 className="title">Track your <br />Order</h4>
                        <form method="post" action="#">
                            <div className="row">
                                <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                    <input type="text" name="tawbno" id="tawbno" placeholder="Your Tracking ID Now" />
                                </div>
                                
                                <div className="form-group col-lg-8 col-md-12 col-sm-12 text-end">
                                    <button type="button" className="theme-btn btn-style-one" onClick="indextrack('tawbno')"><span className="btn-title">Track Order</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <section className="call-to-action">
            <div className="auto-container">
                <div className="outer-box">
                    <a href="https://www.youtube.com/watch?v=Fvae8nxzVz4" className="play-now lightbox-image"><i className="icon fa fa-play"></i><span className="ripple"></span></a>
                    <div className="sec-title light mb-0">
                        <div className="sub-title">Open for business 24/7</div>
                        <h1>Round-the-clock service <br />at your fingertips.</h1>
                        <a  className="theme-btn btn-style-one hvr-light"><span className="btn-title">Get A Quote</span></a>
                    </div>
                </div>
            </div>
        </section>

        <section className="work-section">
            <div className="anim-icons">
                <span className="icon icon-dotted-map-2 zoom-one"></span>
                <span className="icon icon-plane-1 bounce-y"></span>
            </div>

            <div className="auto-container">
                <div className="sec-title text-center">
                    <span className="sub-title">How It Work</span>
                    <h2>Book Your Courier in 3 Easy Steps</h2>
                </div>

                <div className="row">
                    <div className="work-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight">
                        <div className="inner-box">
                            <div className="icon-box">
                                <span className="count">01</span>
                                <i className="icon flaticon-delivery-box-4"></i>
                            </div>
                            <h4 className="title">Prepare your <br />Package</h4>
                        </div>
                    </div>

                   
                    <div className="work-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="300ms">
                        <div className="inner-box">
                            <div className="icon-box">
                                <span className="count">02</span>
                                <i className="icon flaticon-stock-1"></i>
                            </div>
                            <h4 className="title"> Schedule Your <br />Pickup</h4>
                        </div>
                    </div>

                    
                    <div className="work-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="600ms">
                        <div className="inner-box">
                            <div className="icon-box">
                                <span className="count">03</span>
                                <i className="icon flaticon-delivery-box-3"></i>
                            </div>
                            <h4 className="title">Track and<br />Trace</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="fun-fact-section p-0">
            <div className="auto-container">
                <div className="outer-box">
                <div className="bg-image" style={{ backgroundImage: 'url(images/background/3.jpg)' }}></div>

                    <div className="row">
                        <div className="content-column col-lg-7 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title light">
                                    <span className="sub-title">We deliver On Time</span>
                                    <h2>Velocity Express: Delivering Excellence.</h2>
                                    <div className="text">Long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div>
                                </div>

                                <div className="fact-counter">
                                    <div className="row">
                                        <div className="counter-column col-lg-4 col-md-6 col-sm-12">
                                            <div className="inner">
                                                <div className="count-box"><span className="count-text" data-speed="3000" data-stop="100">0</span></div>
                                                <h4 className="counter-title">Years Execution  <br />Experience</h4>
                                                <i className="icon flaticon-delivery-8"></i>
                                            </div>
                                        </div>

                                       
                                        <div className="counter-column col-lg-4 col-md-6 col-sm-12">
                                            <div className="inner">
                                                <div className="count-box"><span className="count-text" data-speed="3000" data-stop="150">0</span></div>
                                                <h4 className="counter-title">Countries <br />Self-Network</h4>
                                                <i className="icon flaticon-delivery-box-3"></i>
                                            </div>
                                        </div>

                                      
                                        <div className="counter-column col-lg-4 col-md-6 col-sm-12">
                                            <div className="inner">
                                                <div className="count-box"><span className="count-text" data-speed="3000" data-stop="700">0</span></div>
                                                <h4 className="counter-title">
                                                    Agents
                                                </h4>
                                                <i className="icon flaticon-team"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="image-column col-lg-5 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <figure className="image"><img src="images/background/delivering-excellence.jpg" alt="delivering-excellence.jpg" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="project-section pb-0">
            <div className="large-container">
                <div className="sec-title text-center">
                    <h2>Engineered for Efficiency</h2>
                </div>
                
                <ProjectCarousel />
            </div>
        </section>

        <section className="why-choose-us pull-up pb-0">
			<div className="bg-image" style={{backgroundImage: 'url(/images/we-are-your-global-logistics-powerhouse.jpg)'}}></div>

            <div className="anim-icons">
                <div className="float-image"><img src="/images/we-are-your-global-logistics-powerhouse.jpg" alt="" /></div>

            </div>

            <div className="auto-container">
                <div className="row">
                    
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title light">
                                <span className="sub-title">Why Choose Us?</span>
                                <h2>We are your global logistics powerhouse with</h2>
                            </div>

                            
                            <div className="feature-block-two">
                                <div className="inner-box">
                                    <i className="icon flaticon-delivery-box-4"></i>
                                    <h4 className="title">Expert Delivery Teams</h4>
                                    <p className="text">Our 200+ strong domestic and 100+ international teams ensure precise shipment handling.</p>
                                </div>
                            </div>

                            
                            <div className="feature-block-two">
                                <div className="inner-box">
                                    <i className="icon flaticon-international-shipping-3"></i>
                                    <h4 className="title">Automation Advantage</h4>
                                    <p className="text">Our 100% automated processes guarantee efficiency and accuracy in every transaction.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="form-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="contact-form wow fadeInLeft">
                                <div method="post" id="contact-form">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <label>Your Name:</label>
                                            <input type="text" name="full_name"  id="name" value={full_name} placeholder={errors.full_name || "Name"} onChange={(e) => setFullname(e.target.value)} />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Your Email:</label>
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="Enter your email"/>
                                            {errors.email === "invalid" && (
                                                <div className=" mt-1" style={{ color: "blue" }}>Enter valid email</div>
                                            )}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Phone No:</label>
                                            <input
                                            type="tel"
                                            ref={phoneInputRef}
                                            id="phone"
                                            name="Phone"
                                            placeholder={errors.phone || "Phone"}
                                            onChange={(e) => setPhone(e.target.value)}
                                            />
                                            {errors.phone && phone !== "" && (
                                            <div className="mt-1" style={{ color: "blue" }}>{errors.phone}</div>
                                            )}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Services Type:</label>
                                            <select className="custom-select" id="services" value={selectedService} onChange={handleServiceChange}>
                                                <option value="">Select</option>
                                                <option value="Domestic">Domestic</option>
                                                <option value="International">International</option>
                                            </select>
                                            {errors.selectedService && <div className="" style={{color:"blue"}}>{errors.selectedService}</div>}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Type:</label>
                                            <select className="custom-select" id="type" value={selectedType} onChange={handleTypeChange}>
                                                <option value="">Select</option>
                                                <option value="Pickup">Pickup</option>
                                                <option value="Drop">Drop</option>
                                            </select>
                                            {errors.selectedType && <div className="" style={{color:"blue"}}>{errors.selectedType}</div>}
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <button
                                            className={`theme-btn btn-style-two hvr-light`}
                                            type="button" // Change to "button" to prevent default form submission
                                            name="submit-form"
                                            onClick={handleSendRequest} // Use the correct function here
                                            disabled={enquiryLoading} // Disable button during loading
                                        >
                                            <span className="btn-title">
                                                {enquiryLoading ? "Submitting..." : "Submit Request"}
                                            </span>
                                        </button>
                                        </div>

                                        <div className="alertmsg col-lg-12 col-md-12 col-sm-12">{errors.mailMessage && <div className="" style={{color:"white"}}>{errors.mailMessage}</div>}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
		</section> 

        <section className="about-section-two">
            <div className="auto-container">
                <div className="row">
                    <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                        <div className="inner-column">
                            <div className="sec-title">
                                <span className="sub-title">Learn About Us</span>
                                <h2>Velocity Express: Beyond Speed.</h2>
                                <h4>Accelerate your business with our domestic and international express services.</h4>
                                <div className="text">With a vast network spanning 150+ countries, we offer unparalleled global reach. Our dedicated team of over 700 professionals, strategically located across 50+ offices, ensures seamless operations and exceptional service. Leveraging our extensive agent network of 700+, we deliver your shipments with precision and speed.</div>
                            </div>

                            <div className="row">
                                <div className="feature-block-three col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner">
                                        <i className="icon flaticon-delivery-courier"></i>
                                        <h4 className="title">Cost Optimisation</h4>
                                    </div>
                                </div>

                                <div className="feature-block-three col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner">
                                        <i className="icon flaticon-delivery-insurance-3"></i>
                                        <h4 className="title">Reduced <br />Transit Time</h4>
                                    </div>
                                </div>

                                <div className="feature-block-three col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner">
                                        <i className="icon flaticon-delivery-box-3"></i>
                                        <h4 className="title">Delivery <br />on Time</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <figure className="image-1 wow fadeInUp"><img src="/images/beyond-speed-1.jpg" alt="" /></figure>
                            <figure className="image-2 wow fadeInRight">
                                <img src="/images/beyond-speed-2.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>  

        <Footer/>    
    </div>

    </>
  );
}