import React from 'react'
import Footer from '../MyComponents/Footer'
import AboutUSCarousel from "./AboutUSCarousel";
import IndexHeader from './IndexHeader';

const AboutUs = () => {
  return (
    <>
    <IndexHeader/>
      <section className="page-title" style={{ backgroundImage: 'url(images/background/2.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer">
            <h1 className="title">About Us</h1>
            <ul className="page-breadcrumb">
              <li><a href="/">Home</a></li>
              <li>About</li>
            </ul>
          </div>
        </div>
      </section>



      <section className="about-section">
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="sub-title">Velocity Express – </span>
                  <h2>Accelerate Your Business</h2>
                  <div className="text">
                    Velocity Express is a leading logistics provider offering swift and reliable express delivery services both domestically and internationally. Our dedicated team and advanced technology ensure timely delivery of your shipments.
                  </div>
                </div>

                <div className="sec-title">
                  <span className="sub-title">Why Choose Us?</span>
                  <div className="text">
                    We are your global logistics powerhouse with
                    <br />
                    Expert Delivery Teams - Our 200+ strong domestic and 100+ international teams ensure precise shipment handling.
                    <br />
                    Automation Advantage: Our 100% automated processes guarantee efficiency and accuracy in every transaction.
                  </div>
                </div>

                <div className="sec-title">
                  <span className="sub-title">Learn About Us</span>
                  <div className="text">
                    Velocity Express: Beyond Speed.
                    Accelerate your business with our domestic and international express services.
                    <br />
                    With a vast network spanning 150+ countries, we offer unparalleled global reach. Our dedicated team of over 700 professionals, strategically located across 50+ offices, ensures seamless operations and exceptional service. Leveraging our extensive agent network of 700+, we deliver your shipments with precision and speed.
                    <br />
                    Imports 30+ countries
                    <br />
                    Pickup App
                    <br />
                    Time & Temperature Controlled Services
                    <br />
                    Industry Updates
                    <br />
                    Stay updated with the latest industry trends and company news
                  </div>
                </div>

                <div className="sec-title">
                  <span className="sub-title">Services</span>
                  <div className="text">
                    <ul>
                      <li>Domestic Express Delivery</li>
                      <li>International Express Delivery</li>
                      <li>E-commerce Deliveries</li>
                      <li>Bulk Cargo Deliveries</li>
                      <li>Air & Sea Freight</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboutUSCarousel/>


   





      <Footer/>
    </>
  )
}

export default AboutUs
