import React from 'react'

const EnquiryForm = () => {
    return (
        <>
            <div className="styled-form" style={{ padding: '16px 20px' }}>
                <div className="mb-2">
                    <label htmlFor="name" className="form-label mb-0">Name</label>
                    <input type="text" className="form-control" id="name" name="name" required placeholder="Enter name" />
                </div>
                <div className="mb-2 d-flex flex-column">
                    <label htmlFor="phone" className="form-label mb-0">Phone Number</label>
                    <input type="tel" className="form-control" id="phone" name="phone" required placeholder="Enter number" />
                </div>
                <div className="mb-2">
                    <label htmlFor="email" className="form-label mb-0">Email Address</label>
                    <input type="email" className="form-control" id="email" name="email" required placeholder="Enter email" />
                </div>
                <input type="hidden" id="location" name="location" value="{{ location }}" />
                <button className="btn btn-success w-100 mt-2" style={{ backgroundColor: '#ce762b', borderColor: '#ce762b' }}>
                    Submit
                </button>
                <div className="alertmsg mt-3 p-2"></div>
            </div>
        </>
    )
}

export default EnquiryForm
