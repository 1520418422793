import React from 'react'
import Menu from '../MyComponents/Menu'
import Footer from '../MyComponents/Footer'
import IndexHeader from './IndexHeader';

const ContactUs = () => {
    return (
        <>
        <IndexHeader/>
            <section className="page-title">
                <div className="auto-container">
                    <div className="title-outer">
                        <h1 className="title">Contact</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="contact-details">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-details__right">
                                <div className="sec-title mb-2">
                                    <span className="sub-title">Need any help?</span>
                                    <h3 className="mb-1">Get in touch with us</h3>
                                    <div className="text">
                                        Velocity Express: Beyond Speed accelerate your business with our domestic and international express services.
                                    </div>
                                </div>
                                <ul className="list-unstyled contact-details__info mt-1">
                                    <li>
                                        <div className="icon">
                                            <span className="lnr-icon-phone-plus"></span>
                                        </div>
                                        <div className="text">
                                            <h6>Have any question?</h6>
                                            <a href="tel:+919021322132">+91 90 2132 2132</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="lnr-icon-envelope1"></span>
                                        </div>
                                        <div className="text">
                                            <h6>Write email</h6>
                                            <a href="mailto:info@velexp.com">info@velexp.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="lnr-icon-location"></span>
                                        </div>
                                        <div className="text">
                                            <h6>Head Office</h6>
                                            <span>GF/ SF - Tejas, Opp Hotel Prasadalay, Shukrawar Peth - 411002</span>
                                        </div>
                                    </li>
                                    <hr />
                                    <li>
                                        <div className="icon">
                                            <span className="lnr-icon-location"></span>
                                        </div>
                                        <div className="text">
                                            <h6>Branch Office</h6>
                                            <span style={{ lineHeight: '1.2' }}>
                                                PNSO Technology Pvt. Ltd <br />
                                                Muhammed Saddique Building, 18/290-A, <br />
                                                Edayar, Binanipuram, Ernakulam 683502
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6">
                            <div className="sec-title">
                                <span className="sub-title">Send us email</span>
                                <h3 className="mb-1">Feel free to write</h3>
                            </div>
                            {/* Contact Form */}
                            <div id="contact_form" name="contact_form" method="post">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label>Name <small className="mandatory">*</small></label>
                                            <input name="form_name" className="form-control" type="text" id="name" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label>Email <small className="mandatory">*</small></label>
                                            <input name="form_email" className="form-control required email" type="email" placeholder="Enter Email" id="email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label>Subject <small className="mandatory">*</small></label>
                                            <input name="form_subject" className="form-control required" type="text" id="subject" placeholder="Enter Subject" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label>Phone</label>
                                            <input name="form_phone" id="phone" className="form-control" type="text" placeholder="Enter Phone" maxLength="10" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label>Message</label>
                                    <textarea name="form_message" id="message" className="form-control required" rows="5" placeholder="Enter Message"></textarea>
                                </div>
                                <div className="mb-3">
                                    <input name="form_botcheck" className="form-control" type="hidden" value="" />
                                    <button
                                        className="theme-btn btn-style-one"
                                        data-loading-text="Please wait..."

                                    >
                                        <span className="btn-title">Send message</span>
                                    </button>
                                </div>
                                <div className="alertmsg"></div>
                            </div>
                            {/* Contact Form Validation */}
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container-fluid p-0">
                    <div className="row">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15132.538594649146!2d73.86017698640765!3d18.522816451453068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c05daddc72cd%3A0x743a90d312534b44!2sATLANTIC%20INTERNATIONAL%20EXPRESS%20COURIER!5e0!3m2!1sen!2sin!4v1723609600505!5m2!1sen!2sin"
                            width="600"
                            height="450"
                            style={{ border: '0' }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default ContactUs
