import React from 'react'
import Footer from '../MyComponents/Footer'
import IndexHeader from './IndexHeader';


const OurPresence = () => {
    return (
        <>
        <IndexHeader/>
            <section className="page-title" style={{ backgroundImage: 'url(images/background/2.jpg)' }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1 className="title">Our Presence</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Our Presence</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="about-section mt-2">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-md-2">
                            <a href="/our-presence/courier-service-bangalore">
                                <img src="/imgs/ourpresence/bangalore.png" alt="Bangalore" />
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="/our-presence/courier-service-ahmedabad">
                                <img src="/imgs/ourpresence/ahemdabad.png" alt="Ahmedabad" />
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="/our-presence/courier-service-alappuzha">
                                <img src="/imgs/ourpresence/alappuzha.png" alt="Alappuzha" />
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="/our-presence/courier-service-bhubaneswar">
                                <img src="/imgs/ourpresence/bhubaneshwar.png" alt="Bhubaneswar" />
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#">
                                <img src="/imgs/ourpresence/chennai.png" alt="Chennai" />
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="/our-presence/courier-service-delhi">
                                <img src="/imgs/ourpresence/delhi.png" alt="Delhi" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/guwahati.png" alt="Guwahati" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-hyderabad">
                                <img src="/imgs/ourpresence/hyderabad.png" alt="Hyderabad" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/indore.png" alt="Indore" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/jaipur.png" alt="Jaipur" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/jalandhar.png" alt="Jalandhar" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-kannur">
                                <img src="/imgs/ourpresence/kannur.png" alt="Kannur" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-kochi">
                                <img src="/imgs/ourpresence/kochi.png" alt="Kochi" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-kolkata">
                                <img src="/imgs/ourpresence/kolkata.png" alt="Kolkata" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-kottayam">
                                <img src="/imgs/ourpresence/kottayam.png" alt="Kottayam" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/ludhiana.png" alt="Ludhiana" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/meerut.png" alt="Meerut" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-mumbai">
                                <img src="/imgs/ourpresence/mumbai.png" alt="Mumbai" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/nagpur.png" alt="Nagpur" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-palakkad">
                                <img src="/imgs/ourpresence/palakkad.png" alt="Palakkad" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/patna.png" alt="Patna" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-pune">
                                <img src="/imgs/ourpresence/pune.png" alt="Pune" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-rajkot">
                                <img src="/imgs/ourpresence/rajkot.png" alt="Rajkot" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-ranchi">
                                <img src="/imgs/ourpresence/ranchi.png" alt="Ranchi" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/sangli.png" alt="Sangli" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-surat">
                                <img src="/imgs/ourpresence/surat.png" alt="Surat" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-thrissur">
                                <img src="/imgs/ourpresence/thrissur.png" alt="Thrissur" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-thiruvananthapuram">
                                <img src="/imgs/ourpresence/trivandrum.png" alt="Thiruvananthapuram" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="#">
                                <img src="/imgs/ourpresence/vadodara.png" alt="Vadodara" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-aurangabad">
                                <img src="/imgs/ourpresence/aurangabad.png" alt="Aurangabad" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-baroda">
                                <img src="/imgs/ourpresence/baroda.png" alt="Baroda" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-bhiwadi">
                                <img src="/imgs/ourpresence/bhiwadi.png" alt="Bhiwadi" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-ernakulam">
                                <img src="/imgs/ourpresence/ernakulam.png" alt="Ernakulam" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-habra">
                                <img src="/imgs/ourpresence/habra.png" alt="Habra" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-haryana">
                                <img src="/imgs/ourpresence/haryana.png" alt="Haryana" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-kalyan/">
                                <img src="/imgs/ourpresence/kalyan.png" alt="Kalyan" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-kollam">
                                <img src="/imgs/ourpresence/kollam.png" alt="Kollam" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-malappuram">
                                <img src="/imgs/ourpresence/malappuram.png" alt="Malappuram" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-manjeri">
                                <img src="/imgs/ourpresence/manjeri.png" alt="Manjeri" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-nashik">
                                <img src="/imgs/ourpresence/nashik.png" alt="Nashik" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-pathanamthitta">
                                <img src="/imgs/ourpresence/pathanamthitta.png" alt="Pathanamthitta" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-satara">
                                <img src="/imgs/ourpresence/satara.png" alt="Satara" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-solapur">
                                <img src="/imgs/ourpresence/solapur.png" alt="Solapur" />
                            </a>
                        </div>
                        <div className="col-md-2 pt-3">
                            <a href="/our-presence/courier-service-wayanad">
                                <img src="/imgs/ourpresence/wayanad.png" alt="Wayanad" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>




           

        </>
    )
}

export default OurPresence
