import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './styles.css';
import Menu from './MyComponents/Menu';

import Header from './MyComponents/Header';
import AboutUs from './MyComponents/AboutUs';
import Domestic from './MyComponents/Domestic';
import International from './MyComponents/International';
import ContactUs from './MyComponents/ContactUs';
import OurPresence from './MyComponents/OurPresence';
import UserLogin from './MyComponents/UserLogin';
import CourierServiceBangalore from './MyComponents/LocationPages/CourierServiceBangalore';
import CourierServiceAhmedabad from './MyComponents/LocationPages/CourierServiceAhmedabad';
import CourierServiceAlappuzha from './MyComponents/LocationPages/CourierServiceAlappuzha';
import CourierServiceBhubaneswar from './MyComponents/LocationPages/CourierServiceBhubaneswar';
import CourierServiceDelhi from './MyComponents/LocationPages/CourierServiceDelhi';
import CourierServiceHyderabad from './MyComponents/LocationPages/CourierServiceHyderabad';
import CourierServiceKannur from './MyComponents/LocationPages/CourierServiceKannur';
import CourierServiceKochi from './MyComponents/LocationPages/CourierServiceKochi';
import CourierServiceKolkata from './MyComponents/LocationPages/CourierServiceKolkata';
import CourierServiceKottayam from './MyComponents/LocationPages/CourierServiceKottayam';
import CourierServiceMumbai from './MyComponents/LocationPages/CourierServiceMumbai';
import Track from './MyComponents/HeaderTrack';


function App() {
  return (
  <Router>       
    <Routes>
    <Route path="/" element={<><Header /><Menu /></>} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/domestic" element={<Domestic />} />
    <Route path="/international" element={<International />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/our-presence" element={<OurPresence />} />
    <Route path="/userlogin" element={<UserLogin />} />

    <Route path="/our-presence/courier-service-bangalore" element={<CourierServiceBangalore />} />
    <Route path="/our-presence/courier-service-ahmedabad" element={<CourierServiceAhmedabad />} />
    <Route path="/our-presence/courier-service-alappuzha" element={<CourierServiceAlappuzha />} />
    <Route path="/our-presence/courier-service-bhubaneswar" element={<CourierServiceBhubaneswar />} />
    <Route path="/our-presence/courier-service-delhi" element={<CourierServiceDelhi />} />
    <Route path="/our-presence/courier-service-hyderabad" element={<CourierServiceHyderabad />} />
    <Route path="/our-presence/courier-service-kannur" element={<CourierServiceKannur />} />
    <Route path="/our-presence/courier-service-kochi" element={<CourierServiceKochi />} />
    <Route path="/our-presence/courier-service-kolkata" element={<CourierServiceKolkata />} />
    <Route path="/our-presence/courier-service-Kottayam" element={<CourierServiceKottayam />} />
    <Route path="/our-presence/courier-service-mumbai" element={<CourierServiceMumbai />} />
    <Route path="/track" element={<Track />} />
    </Routes>
  </Router>
  );
}

export default App;
