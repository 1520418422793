import React , { useEffect, useRef, useState} from 'react'
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import './UserLogin.css'

const UserLogin = () => {
    const [phone, setPhone] = useState("");
    const phoneInputRef = useRef(null);
    useEffect(() => {
        // Initialize intl-tel-input
        const iti = intlTelInput(phoneInputRef.current, {
          separateDialCode: true,
          preferredCountries: ['us', 'gb', 'in'],
          initialCountry: 'in',
        });
    
        // Event listener to update phone state when the user interacts with intl-tel-input
        phoneInputRef.current.addEventListener('countrychange', () => {
          setPhone(iti.selectedCountryData.dialCode); // Update phone number in international format
        });
    
        return () => {
          iti.destroy(); // Cleanup when the component unmounts
        };
      }, []);
    return (
        <div className="page-wrapper">
            <section className="login-section">
                <div className="auto-container">
                    <div className="row align-items-center justify-content-center flex-column">
                        <div className="col-lg-6 text-center">
                            <a href="/"> <img  src="/imgs/vellocity-express-logo.png" alt="vellocity express" className="mt-md-2 mt-lg-2" style={{width: '220px'}} /> </a>
                        </div>
                        <div className="col-lg-6 mt-md-4 mt-lg-4">
					        <div className="card border-0 p-3 rounded-5 login-box">
						        <h4 className="mb-1 text-center"> Log in / Sign up to Velocity using </h4>
                                <div className="card-body text-center">
                                    <input type="hidden" id="ge_from_city"/>
                                    <input type="hidden" id="ge_to_city"/>
                                    <input type="hidden" id="ge_weight"/>
                                    <input type="hidden" id="ge_amount"/>
                                    <div className="block-tab-2">
                                        <ul className="nav nav-tabs nav-fill gap-3" id="velexp-tab" role="tablist">
                                            <li className="nav-item">
                                                <button className="nav-link"><a href="{% provider_login_url 'google' %}?next=/dashboard" className="google-login gap-2 d-flex justify-content-center align-items-center"><img src="/imgs/google_login.svg" className="google_login" alt="Google Login" /> Google</a></button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="velexp-tab-2" data-bs-toggle="pill" data-bs-target="#tab-2" type="button" role="tab" aria-selected="true"> <img src="/imgs/otp-login.svg" className="otp-login" alt="OTP Login" /><span> Mobile </span></button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="velexp-tab-1" data-bs-toggle="pill" data-bs-target="#tab-1" type="button" role="tab" aria-selected="false"><img src="/imgs/user_login.svg" className="user-login" alt="User Login" /><span> User Login  </span></button>
                                            </li>
                                        </ul>

                                        <div className="fancy-title title-border text-center pt-3 pb-3">
                                            <div className="line-with-text">
                                            <span>OR</span>
                                            </div>
                                        </div>

                                        <div className="tab-content">
                                            <div className="tab-pane show active" role="tabpanel" aria-labelledby="tab-2-justify-tab" tabindex="0" id="tab-2">
                                                <div className="row flex-row-reverse align-items-center gx-0">
                                                    <div className="col-12 form-group">
                                                        <div className="input-group" id="phone_number">
                                                            <input type="tel" ref={phoneInputRef} name="velexp-enquiry-phone" id="phone" className="form-control required" value="" placeholder="Mobile Number"/>
                                                        </div>
                                                        <div className="input-group pt-3">
                                                            <input name="velexp-enquiry-phone" id="user-otp" className="form-control required" value="" placeholder="Enter OTP" type="hidden"/>
                                                        </div>
                                                        <input type="hidden" name="" id="otp-default" value="121"/>
                                                        <div className="col-12">
                                                            <small id="err-msg12" style={{color:'red'}}> </small>
                                                        </div>
                                                        <div className="col-12" id="counterDiv" tyle={{color:'red'}}>
                                                            <small id="counter" className="hide"></small>
                                                            <a href="#" className="hide" id="resendOtpBtn" onClick="sendOtp()"><small>Resend OTP</small></a>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <button className="theme-btn velexp_btn m-0 w-100 rounded" id="login-btn" name="register-form-submit" value="register"  onClick="sendOtp()">Send OTP</button>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <button className="theme-btn velexp_btn m-0 w-100 rounded hide" id="verify-btn" name="register-form-submit" value="register">Verify OTP</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" role="tabpanel" aria-labelledby="tab-1-justify-tab" tabindex="0" id="tab-1">
										        <div className="row col-mb-20 align-items-center">	
											        <div className="col-md-12">
                                                        <div className="row gy-3 mb-0 gx-0">
                                                            <div className="col-12 form-group hide">
                                                                <input type="hidden" id="signin-account_number" name="account_number" autocomplete="off" value="0000" className="required email form-control" placeholder="Account Number"/>
                                                            </div> 
                                                            <div className="col-12 form-group">
                                                                <input type="text" id="signin-username" name="register-form-email" autocomplete="off" value="" className="required email form-control" placeholder="Username"/>
                                                            </div>

                                                            <div className="col-12 form-group">
                                                                <input type="password" id="signin-password" name="register-form-password" autocomplete="new-password" value="" className="form-control" placeholder="Password"/>
                                                            </div>
                                                                <div id="err-msg" style={{color:'red'}}>
                                                            </div>

                                                            <div className="col-12 mt-2">
                                                                <button className="velexp_btn m-0 w-100 rounded" id="register-form-submit" name="register-form-submit" value="register" onclick="validateUser()" >Sign in</button>
                                                            </div>
                                                        </div>
                                                    </div>
										        </div>
									        </div>
                                        </div>
                                    </div>
                                    <a href="/" className="text-contrast-900 h-text-color text-center"><small>&larr; Back to website</small></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyright-text  text-center">
                                <small>&copy; Copyright 2024 by Velocity Express Unit of PNSO Technology Pvt. Ltd.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default UserLogin