import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader'
import Footer from '../Footer';

const CourierServiceHyderabad = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title>Reliable Courier Service in Hyderabad – Velocity Express</title>
                <meta name="description" content="Choose Velocity Express for a reliable courier service in Hyderabad. Enjoy fast, secure deliveries at competitive rates, locally and globally." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 255px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}`}
            </style>

            <>

                <section
                    className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}
                >
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Reliable Courier Service in Hyderabad</h1>
                                        <p className="text-light">
                                            Sending a package with Velocity Express is as simple as it gets. Our reliable
                                            courier service in Hyderabad offers everything from domestic and international
                                            shipping to hyperlocal deliveries. We ensure your parcels reach their destinations
                                            smoothly, be it within India or overseas.
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        {/* Enquiry form component */}
                                        <EnquiryForm />
                                        {/* End enquiry form */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{ marginTop: '-16px', zIndex: 99, background: '#fff', position: 'relative', padding: '12px 26px', borderRadius: '6px', boxShadow: '0px 2px 5px #d3d3d3' }}

                                    data-wow-delay="600ms"
                                >
                                    <li>
                                        <a href="#fast-and-reliable"><span>Fast and Reliable Service</span></a>
                                    </li>
                                    <li>
                                        <a href="#transparent-pricing"><span>Transparent Pricing</span></a>
                                    </li>
                                    <li>
                                        <a href="#careful-handling"><span>Careful Handling</span></a>
                                    </li>
                                    <li>
                                        <a href="#flexible-solutions"><span>Flexible Solutions</span></a>
                                    </li>
                                    <li>
                                        <a href="#efficient-warehousing"><span>Efficient Warehousing</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>With a reach spanning 150 countries, Velocity Express boasts 50 offices and a dedicated team of 700 professionals. We aim to get your packages where they need to go, quickly and reliably.</p>
                                <p>Velocity Express is a top choice for sending parcels from Hyderabad. We offer premium courier service at affordable shipping rates. With us, you can effortlessly ship personal items like gifts, food items, medicines, etc. You can also count on us to send commercial consignments across Indian states and abroad.</p>
                                <p>Velocity Express is a preferred choice for e-commerce deliveries as well. So, book your shipments with us and relax knowing your parcels are in safe hands.</p>
                                <hr />

                                <h2>Velocity Express: Fast, Efficient Courier Services in Hyderabad</h2>
                                <p>Shipping with Velocity Express across Hyderabad, within India, or around the globe means peace of mind. Our local (200+) and (100+) international teams make sure every parcel arrives safely and right on time.</p>
                                <p>With an easy online booking process and real-time tracking, you can stay updated on your package’s journey. The highlight of Velocity Express is that every step, right from shipping updates to delivery confirmation, is automated. So, you always know where your package is and that it’s in good hands.</p>
                                <p>If you ever need support, reach out to our customer service team available 24/7. Also, you can receive timely assistance from our automated customer service tools and IVR. So, Velocity Express is your trusted delivery partner.</p>
                                <hr />

                                <h2>Our Courier Services at Velocity Express</h2>
                                <p>Customers will experience seamless local and international shipping with our best courier service in Hyderabad. We offer an array of services like:</p>
                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Domestic Express Delivery</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                When speed and reliability are a must, this service ensures packages reach any corner of India without delay. We have a wide-reaching network of over 500 cities and 7,000 pin codes, plus strategically located warehouses. So, we are all set to get your parcel to its destination quickly—even in remote areas.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">International Express Delivery</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Shipping internationally is hassle-free with our international express service. Operating in over 150 countries, Velocity Express has one of the largest self-managed networks for global deliveries. We handle customs clearance, and documentation, and even offer duty paid delivery options for extra convenience. This makes sure your package arrives smoothly, with no stress involved.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">E-commerce Deliveries</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Perfect for online businesses, our e-commerce delivery service prioritizes customer satisfaction. With our prepaid and cash-on-delivery options, customers will experience flexible shopping. Our reverse logistics simplifies returns for a hassle-free experience.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Bulk Cargo Deliveries</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Moving large shipments can be tricky, but with our bulk cargo service, it’s seamless. From heavy machinery to large inventories, we manage every detail with care and precision. So, you can rest assured your shipment will arrive safely, on time, and without a hitch.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Air and Sea Freight</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Need urgent international shipping? Our air-freight service will meet your needs. You can experience swift and reliable transit as we seamlessly connect to over 50 countries.
                                            </p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                For sending bulk shipments abroad, you can choose our sea freight option. We offer Full Container Load (FCL) and Less than Container Load (LCL) services, which are both timely and cost-effective.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2 className="product-description__title">Our Value-Added Services for a Smooth Shipping Experience</h2>
                                            <div className="row">
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">01</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Same Day & Next Day Delivery</h5>
                                                            <div className="text">For packages that need swift delivery, we offer same-day and next-day delivery services.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/real-time-tracking.svg" alt="Real-Time Tracking" />
                                                            <span className="count">02</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Real-Time Tracking</h5>
                                                            <div className="text">Customers can know the status of their shipment with our <a href="https://velexp.com/track/">real-time tracking</a> service. All you have to do is, visit our website and enter your tracking ID to know the position of your goods.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Extensive Network Coverage" />
                                                            <span className="count">03</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Door-to-Door Pickup and Delivery</h5>
                                                            <div className="text">This service enables customers to send and receive packages from the comfort of their homes and offices. The service is ideal for senior citizens, working class, and even businesses.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Real-Time Tracking" />
                                                            <span className="count">04</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Free Packaging</h5>
                                                            <div className="text">Proper packaging is crucial to keep items safe during transit. Hence, we offer a complimentary packaging service where our expert team will secure your parcels as per the norms.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/secure-service.svg" alt="Safe and Secure Service" />
                                                            <span className="count">05</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Helpful Customer Support</h5>
                                                            <div className="text">Our customer support team is available 24/7 at +91 90 2132 2132 to resolve your queries. Also, our automated customer service tools and IVR provide timely assistance.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Temperature-Controlled Services" />
                                                            <span className="count">06</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Pick and Pack Services</h5>
                                                            <div className="text">Save time and effort with our pick and pack services. We take care of gathering and packaging your items efficiently, so you don’t have to.</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/temperature-controlled-services.svg" alt="Temperature-Controlled Services" />
                                                            <span className="count">07</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h5 className="mt-0 mb-1">Temperature-Controlled Services</h5>
                                                            <div className="text">For items like medicines or perishables that need special care, our temperature-controlled services maintain the perfect conditions. Thus, you can rest assured that the items will arrive at the destination in the best state.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-light">
                    <div className="container pt-5">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">What to Expect from Velocity Express</h2>
                                    <p>When you choose Velocity Express to send parcels from Hyderabad, you can expect:</p>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="fast-and-reliable" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Fast and Reliable Service</h6>
                                                        <p>We at Velocity Express understand the importance of the timely arrival of parcels. Hence, we offer fast and efficient service to make sure your shipments reach their destination without delays.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="transparent-pricing" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Transparent Pricing</h6>
                                                        <p>Our prices are competitive and transparent. So, customers can relax knowing what to pay when sending parcels with us.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-12 col-sm-12 column" id="careful-handling" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item setheight">
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Careful Handling</h6>
                                                        <p>Be it an individual consignment, heavy goods, or bulk shipment, we handle it with precision and care.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12 column" id="flexible-solutions" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '155px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Flexible Solutions</h6>
                                                        <p>Our services meet all shipping demands. So, you can send all kinds of parcels be it single or bulk without any hassle.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12 column" id="efficient-warehousing" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '155px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Efficient Warehousing Solutions</h6>
                                                        <p>Your goods are important to us, and we make sure they’re safe. Our warehouses are set up to keep your parcels secure until they’re ready to go.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0">
                    <div className="container pt-5 pb-2">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">How to Book a Parcel with Velocity Express</h2>
                                    <p>Sending Your Parcels from Hyderabad? Follow These 3 Simple Steps:</p>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="pack-your-parcel" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '140px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Pack Your Parcel</h6>
                                                        <p style={{ fontSize: '0.75em' }}>Pack your items securely according to the standard guidelines. If you need help, use our free packaging service.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="schedule-pickup" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '140px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Schedule Pickup</h6>
                                                        <p style={{ fontSize: '0.75em' }}>Choose a convenient time and place for pickup. You can schedule it on our website or through customer support.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="track-your-shipment" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '140px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Track Your Shipment</h6>
                                                        <p style={{ fontSize: '0.75em' }}>Once your parcel is on its way, track it in real time until it reaches its destination.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>






                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div>
                                <h2>Frequently Asked Questions (FAQs)</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            How do I send a parcel from Hyderabad with Velocity Express?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Sending a parcel from Hyderabad is simple! Just pack your items securely, schedule a pickup time, and track your shipment in real-time.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            What countries do you deliver to from Hyderabad?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express delivers to over 150 countries worldwide. We have a large, self-managed network, so shipping internationally is quick and efficient.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Can I track my parcel in real time?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, once your parcel leaves Hyderabad, you can track its status at any time using our real-time tracking system.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Is packaging included in the shipping cost?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes! We offer free packaging services to ensure your items are securely packed and ready for transit.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            What types of items can I send from Hyderabad?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can send almost anything, from personal items like gifts and medicines to bulk commercial shipments. We also offer temperature-controlled services for perishables and sensitive goods.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 6 */}
                                    <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                                            How can I schedule a pickup for my parcel in Hyderabad?
                                            <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can easily schedule a pickup on our website or reach out to our customer support.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 7 */}
                                    <li className={`accordion block ${activeIndex === 6 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(6)}>
                                            Do you offer same-day or next-day delivery from Hyderabad?
                                            <div className={`icon fa ${activeIndex === 6 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 6 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we offer same-day and next-day delivery options for parcels that need to reach their destination quickly.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 8 */}
                                    <li className={`accordion block ${activeIndex === 7 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(7)}>
                                            How do I get help if I have an issue with my shipment?
                                            <div className={`icon fa ${activeIndex === 7 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 7 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Our customer support team is available 24/7 at +91 90 2132 2132. You can also use our automated customer service tools and IVR for fast assistance.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 9 */}
                                    <li className={`accordion block ${activeIndex === 8 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(8)}>
                                            Can I send large shipments like machinery or inventory from Hyderabad?
                                            <div className={`icon fa ${activeIndex === 8 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 8 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Absolutely! We offer bulk cargo delivery services, handling everything from heavy machinery to large inventories with care and precision.
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {/* Block 10 */}
                                    <li className={`accordion block ${activeIndex === 9 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(9)}>
                                            Can I send perishable items from Hyderabad through Velocity Express?
                                            <div className={`icon fa ${activeIndex === 9 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 9 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we offer temperature-controlled services for perishables, medicines, and other temperature-sensitive goods. We ensure the items stay in the ideal conditions throughout their journey.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div >
    )
}

export default CourierServiceHyderabad
