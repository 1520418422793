import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer'

const CourierServiceKochi = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title>Best Courier Service in Kochi | Velocity Express</title>
                <meta name="description" content="Choose Velocity Express for the Best Courier Service in Kochi. Fast, secure domestic & international deliveries with real-time tracking and reliable service." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: auto;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}
        .row .col-lg-12 .listing{margin-left:15px !important; font-size: 0.88em!important}
        .row .col-lg-12 .fa{color:#ce762b;}
        .feature-block .content-box .text {font-size: 13px !important;} `}
            </style>

            <>

                <section
                    className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Best Courier Service in Kochi</h1>
                                        <p className="text-light">
                                            Velocity Express offers reliable and efficient courier services in Kochi, catering to both domestic and international shipping needs. Our team of dedicated professionals uses advanced technology. We make sure your parcels are delivered quickly and safely, whether you're sending items across town or around the world.
                                        </p>
                                    </div>

                                    <div className="col-md-3">
                                        <EnquiryForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-item-center wow fadeInRight"
                                    style={{
                                        marginTop: '-16px',
                                        zIndex: 99,
                                        background: '#fff',
                                        position: 'relative',
                                        padding: '12px 26px',
                                        borderRadius: '6px',
                                        boxShadow: '0px 2px 5px #d3d3d3'
                                    }}
                                    data-wow-delay="600ms"
                                >
                                    <li>
                                        <a href="#hyperlocal-courier">
                                            <span> Hyperlocal Courier </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#domestic-courier">
                                            <span> Domestic Courier </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#international-courier">
                                            <span> International Courier</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#air-freight">
                                            <span> Air Freight </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ocean-freight">
                                            <span> Ocean Freight </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p>
                                    Our courier service in Kochi is designed to provide seamless and hassle-free deliveries, allowing you to track your shipments in real-time for added peace of mind.
                                </p>

                                <p>As one of the leading logistics providers, Velocity Express boasts an extensive global network, reaching over 150 countries. Our commitment to speed and reliability is backed by a 100% automated process that ensures accuracy at every step. Whether you need standard delivery or specialized services like time-sensitive or temperature-controlled shipping, we are equipped to meet your needs and deliver your shipments on time, every time.</p>

                                <hr />

                                <h2 className="mb-0"> Why Velocity Express Is Your Go-To Courier Delivery Service in Kochi</h2>
                                <p> Velocity Express stands out as the preferred courier service in Kochi because we offer fast, reliable, and efficient delivery solutions tailored to meet the unique needs of our customers. With over a decade of experience and a commitment to excellence, we ensure your shipments are delivered on time, every time. </p>

                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Affordable Courier Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express offers competitive pricing without compromising on quality. Whether you send documents or parcels, our affordable courier charges make us the go-to choice for individuals and businesses.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">Door-to-Door Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Our door-to-door courier service ensures maximum convenience by picking up your parcels from your location and delivering them directly to the recipient's address. It saves you time and effort, providing a hassle-free experience.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Secure Service</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Security is a top priority at Velocity Express. We handle all shipments with care, ensuring they reach their destination safely. Our processes and systems are designed to safeguard your parcels from pickup to delivery.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Wide Network</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>With a vast network covering 7000+ pin codes across India and over 150 countries globally, we ensure seamless delivery to both local and international destinations. No matter where you are, we’ve got you covered.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                                            <p className="pTagtitle mt-2 mb-1">Real-Time Tracking</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Stay updated with the real-time tracking feature from Velocity Express. You can easily monitor your shipment's progress from start to finish, ensuring complete transparency and peace of mind throughout the delivery process.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                                            <p className="pTagtitle mt-2 mb-1">24/7 Customer Support</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>Our dedicated customer support team is available round-the-clock to assist you with any queries or concerns. Whether it's tracking updates or delivery inquiries, we're here to help you anytime, day or night.</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="text">
                                            <h2>Fast and Reliable Courier Service in Kochi</h2>

                                            <p>Kochi, a vibrant city on the southwestern coast of Kerala, is a hub of trade, tourism, and culture. With Kochi's vibrant commercial growth and expanding population, the demand for fast and reliable courier services is more essential than ever. Whether you're sending important documents, personal packages, or business goods, the demand for efficient and timely deliveries is constant.</p>

                                            <p>At Velocity Express, we understand the unique needs of Kochi residents and businesses. Our courier services in Kochi are designed to offer quick, and secure delivery solutions, ensuring your shipments are handled with care and reach their destination on time. With our door-to-door service, we provide the convenience of having your packages picked up and delivered directly to the specified address, saving you time and effort.</p>

                                            <p>Choose Velocity Express for reliable and customer-focused courier services in Kochi. Whether you’re sending a small parcel or a large consignment, we make shipping simple and worry-free, keeping your deliveries safe every step of the way. Our services are available across Kerala and throughout India. Discover <a href="https://velexp.com/our-presence">our locations here.</a></p>

                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h3 className="mb-2">Enjoy Our Courier Service in Other Cities of Kerala</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Kollam</li>
                                                            <li>Courier Service in Pathanamthitta</li>
                                                            <li>Courier Service in Thiruvananthapuram</li>
                                                            <li>Courier Service in Wayanad</li>
                                                            <li>Courier Service in Kozhikode</li>
                                                            <li>Courier Service in Kasaragod</li>
                                                            <li>Courier Service in Thalassery</li>
                                                            <li>Courier Service in Ernakulam</li>
                                                            <li>Courier Service in Alappuzha</li>
                                                            <li>Courier Service in Malappuram</li>
                                                            <li>Courier Service in Mahe</li>
                                                            <li>Courier Service in Idukki</li>
                                                            <li>Courier Service in Thrissur</li>
                                                            <li>Courier Service in Kannur</li>
                                                            <li>Courier Service in Palakkad</li>
                                                        </ul>
                                                    </div>
                                                    <hr />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h3 className="mb-2">Courier Services Across India</h3>
                                                    <div className="table-container">
                                                        <ul>
                                                            <li>Courier Service in Andhra Pradesh</li>
                                                            <li>Courier Service in Assam</li>
                                                            <li>Courier Service in Bihar</li>
                                                            <li>Courier Service in Chhattisgarh</li>
                                                            <li>Courier Service in Kochi</li>
                                                            <li>Courier Service in Gujarat</li>
                                                            <li>Courier Service in Daman and Diu</li>
                                                            <li>Courier Service in Haryana</li>
                                                            <li>Courier Service in Himachal Pradesh</li>
                                                            <li>Courier Service in Jammu and Kashmir</li>
                                                            <li>Courier Service in Jharkhand</li>
                                                            <li>Courier Service in Karnataka</li>
                                                            <li>Courier Service in Kerala</li>
                                                            <li>Courier Service in Madhya Pradesh</li>
                                                            <li>Courier Service in Maharashtra</li>
                                                            <li>Courier Service in Goa</li>
                                                            <li>Courier Service in Manipur</li>
                                                            <li>Courier Service in Mizoram</li>
                                                            <li>Courier Service in Nagaland</li>
                                                            <li>Courier Service in Sikkim</li>
                                                            <li>Courier Service in Tripura</li>
                                                            <li>Courier Service in Arunachal Pradesh</li>
                                                            <li>Courier Service in Meghalaya</li>
                                                            <li>Courier Service in Odisha</li>
                                                            <li>Courier Service in Chandigarh</li>
                                                            <li>Courier Service in Punjab</li>
                                                            <li>Courier Service in Rajasthan</li>
                                                            <li>Courier Service in Tamil Nadu</li>
                                                            <li>Courier Service in Telangana</li>
                                                            <li>Courier Service in Uttar Pradesh</li>
                                                            <li>Courier Service in Uttarakhand</li>
                                                            <li>Courier Service in West Bengal</li>
                                                            <li>Courier Service in Andaman and Nicobar Islands</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h2>Courier Services at Velocity Express</h2>

                                            <div className="row">
                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="hyperlocal-courier" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">01</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Hyperlocal Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express offers fast and efficient hyperlocal courier services, ideal for businesses and individuals in need of quick, same-day deliveries within local areas. With our streamlined processes, we ensure that your parcels are picked up and delivered within hours, making us the go-to choice for retail and e-commerce deliveries in Kochi.</p>
                                                                <p className="text" style={{ fontSize: '0.83em' }}>Whether you're delivering documents, small packages, or urgent items, our hyperlocal service ensures fast, reliable delivery with unmatched convenience. Get started with our local delivery service today and experience fast delivery like never before!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="domestic-courier" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                                                            <span className="count">02</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Domestic Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express provides reliable domestic courier services across India, covering over 19,000+ pin codes nationwide. Whether you are a business looking to fulfil e-commerce orders or an individual sending a personal package, we offer tailored solutions that meet your specific needs.</p>
                                                                <p className="text" style={{ fontSize: '0.83em' }}>Our domestic parcel service is efficient, affordable, and secure. Whether it’s a small package or a large shipment, we ensure timely delivery across the country. Trust Velocity Express for your <a href="https://velexp.com/domestic">domestic courier</a> needs – get your parcel delivered quickly and safely.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="international-courier" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="inner-box">
                                                        <div className="icon-box">
                                                            <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Extensive Network Coverage" />
                                                            <span className="count">03</span>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>International Courier Service</h3>
                                                            <div className="text">
                                                                <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express offers top-tier international courier services, ensuring fast and reliable deliveries to over 150 countries worldwide. Whether you need to ship documents or larger packages, our international shipping service is designed to handle your global shipments with care and efficiency.</p>
                                                                <p className="text" style={{ fontSize: '0.83em' }}>With an excellent track record of timely deliveries, we offer cost-effective solutions without compromising on quality. Our experienced team ensures that your international shipments reach their destination safely and on time. Choose Velocity Express for seamless <a href="https://velexp.com/international">international parcel service</a> and global reach.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-light">
                    <div className="container pt-5">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h2 className="mt-0">Freight Services at Velocity Express</h2>

                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div
                                                    className="col-lg-6 col-md-6 col-sm-12 column"
                                                    id="air-freight"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item setheight">
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h3 className="title">Air Freight Service</h3>
                                                        <p>
                                                            Velocity Express offers efficient air freight services for
                                                            customers who need fast, reliable delivery of goods worldwide.
                                                            Whether you're shipping documents, commercial products, or
                                                            specialized cargo, our air freight shipping service ensures your
                                                            shipments reach their destination quickly.
                                                        </p>
                                                        <p>
                                                            We operate between major international destinations, providing
                                                            flexible delivery speeds and additional services like customs
                                                            clearance and documentation. With our strong network and strategic
                                                            airline partnerships, we guarantee a smooth, on-time shipping
                                                            experience.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-lg-6 col-md-6 col-sm-12 column"
                                                    id="ocean-freight"
                                                    style={{ scrollMarginTop: '140px' }}
                                                >
                                                    <div className="single-item setheight">
                                                        <div className="icon-box">
                                                            <i className="fas fa-check-circle"></i>
                                                        </div>
                                                        <h3 className="title">Ocean Freight Service</h3>
                                                        <p>
                                                            For larger shipments and cost-effective solutions, Velocity
                                                            Express provides comprehensive ocean freight services. We handle
                                                            Full Container Loads (FCL) and Less than Container Loads (LCL),
                                                            ensuring your cargo is shipped efficiently to major ports across the
                                                            globe.
                                                        </p>
                                                        <p>
                                                            Our strong partnerships with leading shipping lines allow us to
                                                            offer flexible transit times and competitive rates, tailored to your
                                                            business needs. With our advanced tracking tools, you can monitor
                                                            your ocean shipments every step of the way, ensuring peace of mind
                                                            and timely arrivals.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0">
                    <div className="container pt-5 pb-2">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <h2>E-Commerce Shipping with Velocity Express</h2>
                                <p>
                                    Velocity Express offers reliable e-commerce shipping solutions for businesses
                                    and customers, both within India and internationally. Whether you're shipping
                                    products locally or globally, we ensure timely and safe delivery of your items.
                                </p>

                                <p>
                                    Our streamlined process allows you to send and receive packages with ease, making
                                    your e-commerce transactions hassle-free. With a focus on fast, secure delivery,
                                    Velocity Express helps businesses meet customer expectations and enhances the
                                    online shopping experience.
                                </p>
                                <hr />
                                <h2>Bulk Shipment Delivery</h2>
                                <p>
                                    Velocity Express provides efficient bulk shipping services, designed to meet the
                                    needs of businesses handling large volumes of goods. Whether you are a small
                                    business or a large enterprise, our bulk shipping solutions are cost-effective
                                    and tailored to your specific needs.
                                </p>

                                <p>
                                    We offer seamless tracking and timely delivery, ensuring that your goods reach
                                    their destination safely and on schedule. Choose Velocity Express for your bulk
                                    shipment needs and enjoy reliable, hassle-free transportation services.
                                </p>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>







                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="">
                                <h2>Frequently Asked Questions</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            Which is the best courier service in Kochi for shipping couriers overseas?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers the best international courier services in Kochi. We ensure fast, secure, and affordable deliveries to over 150 countries worldwide.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            Where can I get a reliable domestic courier service in Kochi to send fragile items?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    For reliable domestic courier service in Kochi, Velocity Express offers safe handling and timely delivery of fragile items. Trust our experienced team to ensure your shipment reaches its destination securely.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Does Velocity Express offer door-to-door courier service in Kochi?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express provides convenient door-to-door courier service in Kochi, picking up parcels from your location and delivering them directly to the recipient.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            Can I track my shipments with Velocity Express in Kochi?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, Velocity Express offers real-time tracking for all shipments, giving you complete visibility of your parcels from pickup to delivery in Kochi and beyond.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            What types of courier services does Velocity Express offer in Kochi?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express provides a variety of courier services in Kochi, including domestic, international, freight, e-commerce, and bulk delivery solutions.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>

                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div >
    )
}

export default CourierServiceKochi
