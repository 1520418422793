import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader';
import Footer from '../Footer'

const CourierServiceKannur = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize intlTelInput once the script is loaded
        script.onload = () => {
            const phoneInput = document.querySelector("#phone");
            if (phoneInput) {
                intlTelInput(phoneInput, {
                    // Your options here to customize the behavior of the phone number input field
                });
            }
        };

        // Cleanup the script tag when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);;

    // Function to toggle the active state of an accordion item
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
    };

    return (

        <div>
            <IndexHeader />
            <Helmet>
                <title> Reliable Courier Service in Kannur by Velocity Express</title>
                <meta name="description" content="Velocity Express offers reliable courier services in Kannur. Enjoy fast delivery, secure packaging, and top-notch customer support with every shipment." />
                <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
            </Helmet>

            <style>{`
            .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
        .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        .bg-light li:after {content: "";display: inline-block;width: 2px;height: 28px;background: #929292;position: relative;top: 4px;left: 52px;}
        .bg-gray {background-color: #f8f9fa !important;}
        .bg-light li:last-child:after {width: 0px;}
        .bg-light li span {font-size:1em;font-weight:bold;color:#333333;}
        .content-column p {color:#333333;line-height: 1.7;}
        .bg-blog-box {background:#fffaf6;border:2px solid #e2ac7d;padding:20px 16px;border-radius:10px;height: 100%;}
        .inner-box img {height:60px;}
        h1,h2,h3,h4,h5,h6 {color:#ce762b;}
        .table-container ul {display: grid;grid-template-columns: repeat(6, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .2s;}
        .table-container-across-india ul {display: grid;grid-template-columns: repeat(4, 1fr);list-style: none;padding: 0;margin: 0;color: #333;gap: 4px;}
        .table-container-across-india li {border: 1px solid #ccc;padding: 6px;font-size: 12px;transition: transform .1s;}
        .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;transform: scale(1.1);}
        .list-unstyled p {margin-bottom: 2px;}
        .services-details__content .feature-list .single-item:hover {background-color:#fffaf6;color:#333333;}
        .services-details__content .feature-list .single-item:hover .title,.services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
        .accordion-box .block .acc-btn.active,.feature-block-four .icon,.team-block .social-links a,.testimonial-block .content-box .rating {background-color: #ff9740;}
        .accordion-box .block .acc-btn {padding: 10px 70px 10px 30px;font-size: 18px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .icon-box .count {-webkit-text-stroke-color: #e3ac7f;}
        .call-to-action.style-two {padding: 10px;}
        .feature-block .content-box .text {font-size: 13px!important;}
        .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-ms-flex-direction: column;flex-direction: column;}
        .inner-box img {height: 50px;}
        .feature-block .icon-box {background-color: #fffaf6;}
        .feature-block .content-box {padding: 25px 13px!important;}
        .styled-form {border: 1px solid #ccc;background-color: white;padding: 10px 20px;border-radius: 8px;}
        .single-item {height: 250px;}
        .form-control,.input-text {height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
        label {font-size: 0.85em;}
        .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {font-size: 0.85em;}
        .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh;}
        h1 {font-size: 3.2em;margin-top: 30px;}
        .setheight {height: 210px;}
        .form-control,.input-text {padding: 15px 15px;}
        .col-lg-3 .pTagtitle,.col-lg-6 .pTagtitle {color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
        .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 255px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
        a {color:#ce762b;}
        .row .col-lg-12 .listing{margin-left:15px !important; font-size: 0.88em!important}
        .row .col-lg-12 .fa{color:#ce762b;} `}
            </style>

            <>
                <section className="call-to-action style-two"
                    style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}>
                    <div className="container-fluid">
                        <div className="outer-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h1 className="mb-2">Reliable Courier Service in Kannur</h1>
                                        <p className="text-light">
                                            Reliable courier services are vital for smooth and timely deliveries. At Velocity Express, we understand this need and are here to help. We offer domestic, hyperlocal, and international shipping solutions that make sending packages easy.
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        {/* Enquiry Form */}
                                        <EnquiryForm />
                                        {/* End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul
                                    className="bg-light d-flex justify-content-between align-items-center wow fadeInRight"
                                    style={{ marginTop: "-16px", zIndex: 99, background: "#fff", position: "relative", padding: "12px 26px", borderRadius: "6px", boxShadow: "0px 2px 5px #d3d3d3" }}

                                    data-wow-delay="600ms"
                                >
                                    <li><a href="#domestic-express"><span>Domestic Express</span></a></li>
                                    <li><a href="#international-express"><span>International Express</span></a></li>
                                    <li><a href="#e-commerce"><span>E-commerce</span></a></li>
                                    <li><a href="#bulk-cargo"><span>Bulk Cargo</span></a></li>
                                    <li><a href="#air-and-sea-freight"><span>Air and Sea Freight</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section pt-3 pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                                <p> Our services cater to a variety of needs, from e-commerce deliveries to bulk shipments. With competitive rates and fast delivery options, we ensure your items arrive quickly and securely.
                                </p>
                                <p> We pride ourselves on quality service at every step. Velocity Express operates in 150 countries, backed by a team of over 700 professionals across 50 offices. This allows us to provide fast and safe shipping, making us a reliable courier service in Kannur.
                                </p>
                                <p>At Velocity Express, we focus on understanding and meeting the unique needs of our customers. Your packages matter to us and we deliver them safely and on time, every time. Thus, we are the top choice for delivering parcels locally and internationally.
                                </p>
                                <hr />
                                <h2>Velocity Express: Fast, Efficient Courier Services in Kannur</h2>
                                <p>At Velocity Express, we believe in keeping things simple, fast, and efficient. Our team has over 200 local experts and more than 100 international professionals. We work hard to ensure your shipments arrive on time and without any hassles.
                                </p>
                                <p>We use advanced technology to improve your experience. Our online booking system is easy to use, and you can track your shipments in real time. With automated reporting, you’ll always know where your package is. You can count on us for timely updates and accurate deliveries.
                                </p>
                                <p>If you need help, our automated customer service and IVR options are ready to assist you. Choose Velocity Express - the best courier service in Kannur that is quick, reliable, and tailored to your needs. We're here to make shipping as smooth as possible!
                                </p>
                                <hr />
                                <h2>Our Services at Velocity Express</h2>
                                <p>At Velocity Express, we believe that shipping should be simple and fast. Here’s what we offer to make your life easier:</p>
                                <div className="row py-4">
                                    <div className="col-lg-3 col-md-3 col-sm-6" id="domestic-express" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                                            <p className="pTagtitle mt-2 mb-1">Domestic Express Delivery</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Want to send a package from Kannur across India? We make it effortless! Our domestic express delivery service ensures that your items arrive on time. This applies to both personal gifts and business shipments.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6" id="international-express" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                                            <p className="pTagtitle mt-2 mb-1">International Express Delivery</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Thinking of sending parcels abroad? Our International Express Delivery service has you covered. We take care of customs clearance and offer duty-paid delivery options. So, you can count on us for smooth and hassle-free delivery of packages overseas.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6" id="e-commerce" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">E-commerce Deliveries</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Are you an online store owner in Kannur? We offer tailored delivery e-commerce solutions just for you! Choose from prepaid or cash-on-delivery (COD) options to give your customers the flexibility they want. Plus, our reverse logistics service makes it easy for customers to return items.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 mt-2" id="bulk-cargo" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                                            <p className="pTagtitle mt-2 mb-1">Bulk Cargo Deliveries</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                If you have a large shipment to send, we’ve got the bulk cargo delivery service. We take care of all the logistics to ensure your goods arrive safely and on time.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-3 col-sm-6 mt-2" id="air-and-sea-freight" style={{ scrollMarginTop: '140px' }}>
                                        <div className="bg-blog-box inner-box text-center">
                                            <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="On-time Delivery" />
                                            <p className="pTagtitle mt-2 mb-1">Air and Sea Freight</p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                Need to send packages overseas quickly? Our Air Freight service delivers to over 50 countries in no time. We manage all the logistics, so you can relax knowing your shipments are in safe hands.
                                            </p>
                                            <p className="text" style={{ fontSize: '0.83em' }}>
                                                For bigger shipments, we offer sea freight options, including Full Container Load (FCL) and Less than Container Load (LCL). We’ll help you find the best solution for your needs at competitive rates.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <h2>What to Expect from Velocity Express</h2>
                                <p>When you choose Velocity Express in Kannur, here’s what you can look forward to:</p>
                                <ul>
                                    <li className="listing"><i className="fa fa-check-square"></i> Fast and Reliable Service: Timely delivery is our priority. Our extensive network and dedicated team work to get your packages to their destination quickly and safely.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Transparent Pricing: With us, you’ll never encounter hidden fees. Our rates are clear and competitive.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Real-Time Tracking: Stay informed every step of the way. Simply enter your tracking ID to check your shipment’s current location anytime.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Door-to-Door Pickup and Delivery: For the convenience of our customers, we offer doorstep pickup and delivery service. Customers have to just tell us the suitable time and location for pickup, and our team will take care of the rest.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Same Day & Next Day Delivery: When time is short, our fast shipping options deliver your package right on time.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Temperature-Controlled Services: For medical supplies or perishables, our specialized shipping keeps them at the ideal temperature.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Free Packaging: We offer secure packaging at no extra charge, ensuring your items remain intact throughout the journey.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Helpful Customer Support: Our automated customer service and IVR options make it easy to get assistance. Call us at +91 90 2132 2132 for any queries or information.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Careful Handling: For us at Velocity Express, every package is important. Whether it is a personal package or a business consignment, we treat each shipment with care and attention.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Flexible Solutions: From single parcels to large cargo, we adapt to your shipping requirements with ease and efficiency.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Pick and Pack Services: We make order fulfillment easy by assembling and packing your items correctly. This saves you time and effort.</li>
                                    <li className="listing"><i className="fa fa-check-square"></i> Warehousing Solutions: We provide safe storage options for your goods. Our secure warehouses keep your items safe until they are ready to ship.</li>
                                </ul>

                                <p className="pt-3">
                                    With Velocity Express, you’re partnering with a team committed to efficient, transparent, and dependable service.
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0">
                    <div className="container pb-2">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="services-details__content">
                                    <h3 className="mt-0">How to Book a Parcel with Velocity Express</h3>
                                    <p>Sending a parcel from Kannur with Velocity Express is easier than you might think! Just follow these three simple steps:</p>
                                    <div className="content">
                                        <div className="feature-list">
                                            <div className="row clearfix">
                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="pack-your-parcel" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Prepare Your Parcel</h6>
                                                        <p style={{ fontSize: '0.75em' }}>Start by securely packing your items. If you’d like some help, we’ve got you covered with free packaging to make sure everything is ready for transit.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="schedule-pickup" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Schedule Your Pickup</h6>
                                                        <p style={{ fontSize: '0.75em' }}>Next, pick a time for us to collect your package that fits your schedule. You can do this quickly through our website or just give our friendly customer support team a call.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 col-sm-12 column" id="track-your-shipment" style={{ scrollMarginTop: '140px' }}>
                                                    <div className="single-item" style={{ height: '150px' }}>
                                                        <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                                                        <h6 className="title">Track Your Shipment</h6>
                                                        <p style={{ fontSize: '0.75em' }}>
                                                            After we pick up your parcel, you can easily track its journey. Our <a href="https://velexp.com/track/">tracking system</a> keeps you updated in real-time, so you’ll always know where your package is.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Services Details Content */}
                        </div>
                    </div>
                </section>
                <section className="services-details pt-0 bg-gray">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="">
                                <h2>Frequently Asked Questions (FAQs)</h2>
                                <ul className="accordion-box wow fadeInRight">
                                    {/* Block 1 */}
                                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                                            What courier services does Velocity Express offer in Kannur?
                                            <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Velocity Express offers a variety of courier services in Kannur. These include domestic express delivery, hyperlocal, and international shipping. We also offer e-commerce logistics and bulk cargo delivery, all tailored to meet your shipping needs.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 2 */}
                                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                                            How can I send a package from Kannur to another city in India?
                                            <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can easily send a package from Kannur to any city in India using our domestic express delivery service. Just prepare your parcel, schedule a pickup, and we’ll handle the rest!
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 3 */}
                                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                                            Can I send international parcels from Kannur?
                                            <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes! Velocity Express offers international express delivery services. We take care of customs clearance and logistics. Hence, it is easy for you to send packages abroad without any hassle.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 4 */}
                                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                                            What if I need to return an item purchased online?
                                            <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Our reverse logistics service simplifies the return process. Just contact us, and we’ll arrange for the pickup of the return package.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 5 */}
                                    <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                                            How do I track my shipment sent from Kannur?
                                            <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Once your package starts its journey, you can track it using our real-time tracking system. Just enter your tracking ID on our website to see the latest updates.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 6 */}
                                    <li className={`accordion block ${activeIndex === 5 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(5)}>
                                            What if I have a large shipment to send?
                                            <div className={`icon fa ${activeIndex === 5 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 5 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    For bulk shipments, Velocity Express offers specialized bulk cargo delivery services. We’ll manage all the logistics to ensure your goods are delivered safely and on time.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 7 */}
                                    <li className={`accordion block ${activeIndex === 6 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(6)}>
                                            Do you offer same-day delivery in Kannur?
                                            <div className={`icon fa ${activeIndex === 6 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 6 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we do! If you have an urgent shipment, our same-day and next-day delivery options can get your package to its destination.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 8 */}
                                    <li className={`accordion block ${activeIndex === 7 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(7)}>
                                            How can I contact customer support in Kannur?
                                            <div className={`icon fa ${activeIndex === 7 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 7 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    You can reach our customer support team by calling +91 90 2132 2132. We’re here to assist you with any questions or concerns you may have.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 9 */}
                                    <li className={`accordion block ${activeIndex === 8 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(8)}>
                                            Is packaging provided for my shipments?
                                            <div className={`icon fa ${activeIndex === 8 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 8 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Absolutely! Velocity Express offers free packaging for your items to ensure they are securely packed for transit.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* Block 10 */}
                                    <li className={`accordion block ${activeIndex === 9 ? 'active-block' : ''}`}>
                                        <div className="acc-btn" onClick={() => toggleAccordion(9)}>
                                            Do you offer temperature-controlled shipping services in Kannur?
                                            <div className={`icon fa ${activeIndex === 9 ? 'fa-minus' : 'fa-plus'}`}></div>
                                        </div>
                                        <div className={`acc-content ${activeIndex === 9 ? 'current' : ''}`}>
                                            <div className="content">
                                                <div className="text">
                                                    Yes, we provide temperature-controlled shipping services in Kannur. This is for items that must stay at certain temperatures during transportation. This is especially important for sensitive goods that require special handling to ensure they arrive safely.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
                <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
            </>
        </div >
    )
}

export default CourierServiceKannur
