import React from 'react';
export default function MobileMenu() { 
    return(           
            <div className="mobile-menu">
                <div className="menu-backdrop"></div>
                
                <nav className="menu-box">
                    <div className="upper-box">
                        <div className="nav-logo">
                            <a href="/"><img src="/imgs/vellocity-express-mobile.png" alt="Velocity Express" title="Velocity Express" /></a>
                        </div>
                        <div className="close-btn"><i className="icon fa fa-times"></i></div>
                    </div>

                    <ul className="navigation clearfix">
                        
                    </ul>
                    <ul className="contact-list-one">
                        <li>                
                            <div className="contact-info-box">
                                <i className="icon lnr-icon-phone-handset"></i>
                                <span className="title">Call Now</span>
                                <a href="tel:+918892917544">+91 88929 17544</a>
                            </div>
                        </li>
                        <li>
                            <div className="contact-info-box">
                                <span className="icon lnr-icon-envelope1"></span>
                                <span className="title">Send Email</span>
                                <a href="mailto:enquiry@velocityexpress.com">enquiry@velocityexpress.com</a>
                            </div>
                        </li>
                        
                    </ul>

                    <ul className="social-links">
                        <li>
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#"><i className="fab fa-youtube"></i></a>
                        </li>
                    </ul>
                </nav>
            </div>
    );
    }