import React from 'react';
import OwlCarousel from 'react-owl-carousel'; // Assuming you have owl-carousel package installed



const TestimonialSection = () => {
  // Testimonial data array
  const testimonials = [
    {
      title: 'Our Vision',
      content: '“Our vision is to transform the courier industry through innovation, reliability, and exceptional service. We aim to set new standards in global logistics while maintaining a personal touch, advancing sustainability, and empowering our team. Our goal is to deliver not just packages, but a seamless and outstanding experience that connects communities and drives the future of delivery.”'
    },
    {
      title: 'Our Mission',
      content: '“All of our lorries are fitted with Satellite Tracking and Temperature Controlled monitoring systems so that the transportation of high value and temperature-sensitive loads can be monitored at all times.”'
    },
    {
      title: 'Our Values',
      content: '“Our mission is simple: to provide reliable, secure, and prompt courier services that exceed your expectations. We strive to be your trusted partner in logistics, delivering not just packages but peace of mind. Whether you’re a business needing regular shipments or an individual sending a special gift, we’re here to ensure your delivery reaches its destination safely and on time.”'
    },
    // Add more testimonials here if needed
  ];

  return (
    <section className="testimonial-section pt-0 pb-100">
      <div className="anim-icons">
        <span className="icon icon-bg-dots"></span>
        <span className="icon icon-plane-2 bounce-y"></span>
      </div>

      <div className="auto-container">
        <div className="outer-box">
          {/* Testimonial Carousel */}
          <OwlCarousel 
            className="testimonial-carousel owl-carousel owl-theme" 
            loop={true}  // Enable looping
            margin={10}  // Optional, adjust as needed
            nav={false}  // Optional, enables/disables navigation arrows
            dots={true}  // Optional, enables/disables dots navigation
            autoplay={true}  // Optional, enable autoplay
            autoplayTimeout={3000}  // Optional, autoplay interval in ms
            autoplayHoverPause={true}  // Optional, pause autoplay on hover
          >
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-block" key={index}>
                <div className="inner-box">
                  <div className="content-box">
                    <div className="rating" style={{ fontSize: 14 }}>
                      {testimonial.title}
                    </div>
                    <div className="text" style={{fontSize:14}}>
                      {testimonial.content}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>

        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
