import EnquiryForm from './EnquiryForm'
import React, { useEffect, useState } from "react";
import intlTelInput from "intl-tel-input"; // Import intlTelInput
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS
import IndexHeader from '../IndexHeader'
import { Helmet } from "react-helmet";
import Footer from '../Footer';

const TestPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
    script.async = true;
    document.body.appendChild(script);

    // Initialize intlTelInput once the script is loaded
    script.onload = () => {
      const phoneInput = document.querySelector("#phone");
      if (phoneInput) {
        intlTelInput(phoneInput, {
          // Your options here to customize the behavior of the phone number input field
        });
      }
    };

    // Cleanup the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const [activeIndex, setActiveIndex] = useState(0);

  // Function to toggle the active state of an accordion item
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Close if same item is clicked, open new one
  };

  return (

    <div>
      <IndexHeader />
      <Helmet>
        <title>Best Courier Service in Bangalore - Velocity Express</title>
        <meta name="description" content="Experience fast, reliable courier services in Bangalore with Velocity Express. Enjoy seamless delivery for domestic and international shipments." />
        <link rel="stylesheet" type="text/css" href="/static/assets/libs/phonecode/css/intlTelInput.css" />
      </Helmet>

      <style>
        {`
          .bg-light li:after {
            content: "";
            display: inline-block;
            width: 2px;
            height: 28px;
            background: #929292;
            position: relative;
            top: 4px;
            left: 52px;
          }
          .bg-gray {background-color: #f8f9fa !important;}
          .bg-light li:last-child:after {width: 0px;}
          .bg-light li span {font-size:1em; font-weight:bold; color:#333333}
          .content-column  p { color:#333333; line-height: 1.7;}
          .bg-blog-box {background:#fffaf6; border:2px solid #e2ac7d; padding:20px 16px; border-radius:10px; height: 100%;}
          .inner-box img {height:60px;}
          h1,h2,h3,h4,h5,h6{color:#ce762b;}
          .table-container ul {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            list-style: none;
            padding: 0;
            margin: 0;
            color: #333;
            gap: 4px;
          }
          .table-container li {
            border: 1px solid #ccc;
            padding: 6px;
            font-size: 12px;
            transition: transform .2s;
          }
          .table-container-across-india ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            list-style: none;
            padding: 0;
            margin: 0;
            color: #333;
            gap: 4px;
          }
          .table-container-across-india li {
            border: 1px solid #ccc;
            padding: 6px;
            font-size: 12px;
            transition: transform .1s;
          }
          .table-container-across-india li:hover, .table-container li:hover {background:#fffaf6;  transform: scale(1.1);}
          .list-unstyled p { margin-bottom: 2px;}
          .services-details__content .feature-list .single-item:hover {background-color:#fffaf6; color:#333333;}
          .services-details__content .feature-list .single-item:hover .title, .services-details__content .feature-list .single-item:hover .icon-box {color: #ce762b;}
          .accordion-box .block .acc-btn.active, .feature-block-four .icon, .team-block .social-links a, .testimonial-block .content-box .rating {background-color: #ff9740;}
          .accordion-box .block .acc-btn { padding: 10px 70px 10px 30px; font-size: 18px;}
          .feature-block .icon-box { background-color: #fffaf6;}
          .feature-block .icon-box .count { -webkit-text-stroke-color: #e3ac7f;}
          .call-to-action.style-two{padding: 10px;}
          .feature-block .content-box .text {font-size: 13px!important;}
          .feature-block .icon-box {position: relative;background-color: var(--bg-theme-color2-light);min-width: 70px;text-align: center;padding: 20px 0px;display: -webkit-box;display: -ms-flexbox;display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -ms-flex-direction: column;
            flex-direction: column;}
          .inner-box img {height: 50px;}
          .feature-block .icon-box {background-color: #fffaf6;}
          .feature-block .content-box{padding: 25px 13px!important;}
          .styled-form {border: 1px solid #ccc; background-color: white; padding: 10px 20px; border-radius: 8px;}
          .single-item {height: 250px;}
          .form-control, .input-text{height:0px!important;padding:15px 24px;outline: 0;background-color: #f4f5f8;border: 1px solid #f4f5f8;color: #686a6f;font-size: .9rem;width: 100%;}
          label {font-size: 0.85em;}
          .iti  .iti__flag-container .iti__selected-flag .iti__selected-dial-code{ font-size: 0.85em;}
          .centered-content {display: flex;justify-content: center;align-items: center;height: 100vh; }
          h1{font-size: 3.2em;margin-top: 30px;}
          .setheight {height: 210px;}
          .form-control, .input-text{ padding: 15px 15px; }
          .col-lg-3 .pTagtitle , .col-lg-6 .pTagtitle{color: #ce762b;font-weight: var(--h4-font-weight);font-size: large;}
          .services-details__content .feature-list .single-item {position: relative;display: block;border: 1px solid #e1e8e4;height: 540px;padding: 16px 30px 16px 53px;margin-bottom: 20px;-webkit-transition: .6s;transition: .6s;}
          a{color:#ce762b}
          .accordion-box .block .acc-btn.active, .feature-block-four .icon, .team-block .social-links a, .testimonial-block .content-box .rating {background-color: #ff9740;}
          .active-block .acc-btn {background-color: #ff9740;    color: white !important;}
          .accordion-box .block .acc-btn {position: relative;cursor: pointer;-webkit-transition: all 500ms ease;transition: all 500ms ease;border-bottom: 1px solid #fff;}
        `}
      </style>
      <>
        <section
          className="call-to-action style-two"
          style={{ backgroundImage: 'url(/imgs/location-backgroun-img/kalyan.jpg)' }}
        >
          <div className="container-fluid">
            <div className="outer-box">
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <h1 className="mb-2">Best Courier Service in Bangalore</h1>
                    <p className="text-light">
                      Velocity Express is your trusted partner for reliable and efficient courier services in
                      Bangalore. Whether you're sending a package across the city or around the world, we ensure
                      fast, safe, and timely delivery. With advanced technology and a dedicated team, we handle
                      all shipments with care and precision.
                    </p>
                  </div>

                  <div className="col-md-3">
                    <EnquiryForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto-container">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="bg-light d-flex justify-content-between align-item-center wow fadeInRight"
                  style={{ marginTop: '-16px', zIndex: 99, backgroundColor: '#fff', position: 'relative', padding: '12px 26px', borderRadius: '6px', boxShadow: '0px 2px 5px #d3d3d3' }}
                  data-wow-delay="600ms"
                >
                  <li><a href="#hyperlocal-courier"><span>Hyperlocal Courier</span></a></li>
                  <li><a href="#domestic-courier"><span>Domestic Courier</span></a></li>
                  <li><a href="#international-courier"><span>International Courier</span></a></li>
                  <li><a href="#air-freight"><span>Air Freight</span></a></li>
                  <li><a href="#ocean-freight"><span>Ocean Freight</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </section>


        <section className="about-section pt-3 pb-0">
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-12 col-md-12 col-sm-12 order-2 wow fadeInRight" data-wow-delay="600ms">
                <p>
                  Our courier network spans over 7000+ pin codes across India and extends to more than 150 countries globally. This vast reach enables us to offer smooth and hassle-free domestic and international delivery. We understand the urgency of certain shipments, and our team works diligently to ensure timely deliveries, whether for personal or business needs.
                </p>

                <p>
                  In Bangalore (Bengaluru), we offer a variety of services, such as temperature-controlled shipping for perishable goods and a convenient pickup app that makes scheduling shipments effortless. Our experienced staff ensures every shipment is processed smoothly, so you can count on us to take care of your delivery needs.
                </p>
                <p>
                  Velocity Express is committed to keeping customers informed with real-time updates and industry news. Choose us for your courier needs in Bangalore, and experience hassle-free, reliable delivery solutions every time.
                </p>

                <hr />

                <h2 className="mb-0"> Why Velocity Express Is Your Top Courier Service in Bangalore</h2>
                <p>Velocity Express stands out as the leading choice for courier services in Bangalore, providing reliable and affordable delivery solutions for individuals and businesses alike. Here’s why:</p>

                <div className="row py-4">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/door-to-door-service.png" alt="Door-to-door Service" />
                      <p className="pTagtitle mt-2 mb-1">Affordable Courier Service</p>
                      <p className="text" style={{ fontSize: '0.83em' }}>We know cost matters to our customers. That’s why we offer competitive prices without compromising on quality. Whether you're sending personal or business packages, our affordable courier service makes it easy to send items locally or internationally.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/free-packing.png" alt="Free Packaging" />
                      <p className="pTagtitle mt-2 mb-1">Door-to-Door Service</p>
                      <p className="text" style={{ fontSize: '0.83em' }}>For your convenience, Velocity Express offers door-to-door delivery service in Bangalore. We pick up your packages from your location and deliver them directly to the recipient, saving you time and effort.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                      <p className="pTagtitle mt-2 mb-1">Secure Service</p>
                      <p className="text" style={{ fontSize: '0.83em' }}>We prioritize the safety of your shipments. Our team ensures that each package is carefully handled and securely transported, guaranteeing that your items arrive in perfect condition.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-6 mt-2">
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/safe-transit-vehicle.png" alt="Safe Transit Vehicles" />
                      <p className="pTagtitle mt-2 mb-1">Wide Network</p>
                      <p className="text" style={{ fontSize: '0.83em' }}>Our vast network covers over 19,000 pin codes in India and 150+ countries worldwide. With this reach, we offer a variety of shipping options that ensure timely and efficient delivery to almost any destination.</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                      <p className="pTagtitle mt-2 mb-1">Real-Time Tracking</p>
                      <p className="text" style={{ fontSize: '0.83em' }}>Stay informed every step of the way with our real-time tracking feature. You can easily monitor your shipment's journey from pickup to delivery, giving you peace of mind.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-3 col-sm-6 mt-2">
                    <div className="bg-blog-box inner-box text-center">
                      <img src="/imgs/location-backgroun-img/on-time-delivery.png" alt="door-to-door-service" />
                      <p className="pTagtitle mt-2 mb-1">24/7 Customer Support</p>
                      <p className="text" style={{ fontSize: '0.83em' }}>Velocity Express is committed to providing excellent customer service around the clock. Our dedicated team is available 24/7 to assist you with booking, tracking, or any other concerns you may have.</p>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="text">
                      <h2>Fast and Reliable Courier Service in Bangalore</h2>
                      <p>Bangalore is one of India’s busiest cities, home to many industries, startups, and a rapidly growing e-commerce sector. As the demand for fast and secure courier services increases, Velocity Express is here to meet your needs.</p>
                      <p>Whether you're shipping important documents, local products, or gifts, we ensure timely and safe deliveries within Bangalore and beyond. Our door-to-door service guarantees convenience, while our real-time tracking lets you monitor every step of the journey.</p>
                      <p>Looking for "courier services near me"? Look no further! Velocity Express is your local courier expert, offering reliable and efficient delivery solutions throughout Bangalore and India. Check out the other <a href="https://velexp.com/our-presence">areas we serve</a> and experience hassle-free shipping today.</p>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="mb-2">Enjoy Our Courier Service in Other Places in Karnataka</h3>
                        <div className="table-container">
                          <ul>
                            <li>Courier Service in Bangalore Rural</li>
                            <li>Courier Service in Ramanagar</li>
                            <li>Courier Service in Bagalkot</li>
                            <li>Courier Service in Bellary</li>
                            <li>Courier Service in Davangere</li>
                            <li>Courier Service in Bidar</li>
                            <li>Courier Service in Belagavi</li>
                            <li>Courier Service in Hubballi</li>
                            <li>Courier Service in Dharwad</li>
                            <li>Courier Service in Gadag</li>
                            <li>Courier Service in Koppal</li>
                            <li>Courier Service in Madikeri</li>
                            <li>Courier Service in Haveri</li>
                            <li>Courier Service in Kalaburagi</li>
                            <li>Courier Service in Yadgir</li>
                            <li>Courier Service in Uttara Kannada</li>
                            <li>Courier Service in Raichur</li>
                            <li>Courier Service in Bijapur (Kar)</li>
                            <li>Courier Service in Bijapur</li>
                            <li>Courier Service in Chickmagalur</li>
                            <li>Courier Service in Chitradurga</li>
                            <li>Courier Service in Hassan</li>
                            <li>Courier Service in Kodagu</li>
                            <li>Courier Service in Athani</li>
                            <li>Courier Service in Chikkaballapur</li>
                            <li>Courier Service in Kolar</li>
                            <li>Courier Service in Mandya</li>
                            <li>Courier Service in Dakshina Kannada</li>
                            <li>Courier Service in Udupi</li>
                            <li>Courier Service in Mysuru</li>
                            <li>Courier Service in Saraswathipuram</li>
                            <li>Courier Service in Chamrajnagar</li>
                            <li>Courier Service in Bailhongal</li>
                            <li>Courier Service in Nanjangud</li>
                            <li>Courier Service in Shivamogga</li>
                            <li>Courier Service in Tumakuru</li>
                          </ul>
                        </div>
                        <hr />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="mb-2">Serving Key Regions Across India</h3>
                        <div className="table-container">
                          <ul>
                            <li>Courier Service in Andhra Pradesh</li>
                            <li>Courier Service in Assam</li>
                            <li>Courier Service in Bihar</li>
                            <li>Courier Service in Chhattisgarh</li>
                            <li>Courier Service in Delhi</li>
                            <li>Courier Service in Gujarat</li>
                            <li>Courier Service in Daman and Diu</li>
                            <li>Courier Service in Haryana</li>
                            <li>Courier Service in Himachal Pradesh</li>
                            <li>Courier Service in Jammu and Kashmir</li>
                            <li>Courier Service in Jharkhand</li>
                            <li>Courier Service in Karnataka</li>
                            <li>Courier Service in Kerala</li>
                            <li>Courier Service in Madhya Pradesh</li>
                            <li>Courier Service in Maharashtra</li>
                            <li>Courier Service in Goa</li>
                            <li>Courier Service in Manipur</li>
                            <li>Courier Service in Mizoram</li>
                            <li>Courier Service in Nagaland</li>
                            <li>Courier Service in Sikkim</li>
                            <li>Courier Service in Tripura</li>
                            <li>Courier Service in Arunachal Pradesh</li>
                            <li>Courier Service in Meghalaya</li>
                            <li>Courier Service in Odisha</li>
                            <li>Courier Service in Chandigarh</li>
                            <li>Courier Service in Punjab</li>
                            <li>Courier Service in Rajasthan</li>
                            <li>Courier Service in Tamil Nadu</li>
                            <li>Courier Service in Telangana</li>
                            <li>Courier Service in Uttar Pradesh</li>
                            <li>Courier Service in Uttarakhand</li>
                            <li>Courier Service in West Bengal</li>
                            <li>Courier Service in Andaman and Nicobar Islands</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h2>Courier Services at Velocity Express</h2>

                    <div className="row">
                      <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="hyperlocal-courier" style={{ scrollMarginTop: '140px' }}>
                        <div className="inner-box">
                          <div className="icon-box">
                            <img src="/imgs/location-backgroun-img/affordable-shipping.svg" alt="Affordable Shipping Solutions" />
                            <span className="count">01</span>
                          </div>
                          <div className="content-box">
                            <h3>Hyperlocal Courier Service</h3>
                            <div className="text">
                              <p className="text" style={{ fontSize: '0.83em' }}>
                                Velocity Express provides fast and efficient hyperlocal delivery services within Bangalore. Whether it’s a document or a product for a local customer, our team ensures timely delivery. We pick up from your doorstep and deliver directly to the destination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="domestic-courier" style={{ scrollMarginTop: '140px' }}>
                        <div className="inner-box">
                          <div className="icon-box">
                            <img src="/imgs/location-backgroun-img/easy-booking.svg" alt="Affordable Shipping Solutions" />
                            <span className="count">02</span>
                          </div>
                          <div className="content-box">
                            <h3>Domestic Courier Service</h3>
                            <div className="text">
                              <p className="text" style={{ fontSize: '0.83em' }}>
                                For domestic shipping within India, Velocity Express offers affordable and reliable solutions. We ensure that your parcels and documents are delivered securely and on time, no matter the destination. Our user-friendly booking and real-time tracking options make shipping stress-free. Get Started Today with our{' '}
                                <a href="https://velexp.com/domestic">Domestic Courier Service</a> – Ship Your Parcel with Velocity Express!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="feature-block col-lg-4 col-md-4 col-sm-12" id="international-courier" style={{ scrollMarginTop: '140px' }}>
                        <div className="inner-box">
                          <div className="icon-box">
                            <img src="/imgs/location-backgroun-img/network-coverage.svg" alt="Extensive Network Coverage" />
                            <span className="count">03</span>
                          </div>
                          <div className="content-box">
                            <h3>International Courier Service</h3>
                            <div className="text">
                              <p className="text" style={{ fontSize: '0.83em' }}>
                                Velocity Express also offers international shipping services to over 150 countries. Whether you're sending personal items or business packages, we ensure timely, secure, and cost-effective delivery to destinations worldwide. With our strong global network and customs support, we make international shipping easy. Get Started Now –{' '}
                                <a href="https://velexp.com/international">Ship Internationally</a> with Velocity Express!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section className="services-details pt-0 bg-light">
          <div className="container pt-5">
            <div className="row">
              {/* Start Services Details Content */}
              <div className="col-xl-12 col-lg-12">
                <div className="services-details__content">
                  <h2 className="mt-0">Freight Services at Velocity Express</h2>

                  <div className="content">
                    <div className="feature-list">
                      <div className="row clearfix">
                        <div className="col-lg-3 col-md-6 col-sm-12 column" id="air-freight" style={{ scrollMarginTop: '140px' }}>
                          <div className="single-item setheight">
                            <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                            <h3 className="title">Air Freight Service</h3>
                            <p>Need urgent delivery? Our air freight service ensures your shipments reach their destination quickly and safely. From documents to specialized cargo, we offer flexible air shipping solutions with real-time tracking and customs support.</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 column" id="ocean-freight" style={{ scrollMarginTop: '140px' }}>
                          <div className="single-item setheight">
                            <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                            <h3 className="title">Ocean Freight Service</h3>
                            <p>For large-volume shipments, our ocean freight service provides cost-effective delivery to major ports around the world. Whether you're shipping Full Container Loads (FCL) or Less than Container Loads (LCL), we offer flexible and reliable solutions.</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 column" id="ecommerce-shipment" style={{ scrollMarginTop: '140px' }}>
                          <div className="single-item setheight">
                            <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                            <h3 className="title">E-Commerce Shipment Delivery</h3>
                            <p>Velocity Express is a trusted partner for e-commerce businesses in Bangalore, offering quick and efficient shipping solutions for domestic and international orders. Our same-day and next-day delivery services ensure your customers receive their orders on time, with real-time tracking for transparency.</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 column" id="bulk-shipment" style={{ scrollMarginTop: '140px' }}>
                          <div className="single-item setheight">
                            <div className="icon-box"><i className="fas fa-check-circle"></i></div>
                            <h3 className="title">Bulk Shipment Delivery</h3>
                            <p>For businesses needing bulk shipment solutions, Velocity Express provides efficient, safe, and cost-effective delivery services. Whether you’re shipping materials, equipment, or products, our bulk shipping options ensure timely and reliable delivery.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Services Details Content */}
            </div>
          </div>
        </section>



        <section className="services-details pt-0 bg-gray">
          <div className="container pt-5">
            <div className="row">
              <div className="">
                <h2>Frequently Asked Questions</h2>
                <ul className="accordion-box">
                  {/* Block 1 */}
                  <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                    <div className="acc-btn" onClick={() => toggleAccordion(0)}>
                      What types of courier services does Velocity Express offer in Bangalore?
                      <div className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`}></div>
                    </div>
                    <div className={`acc-content ${activeIndex === 0 ? 'current' : ''}`}>
                      <div className="content">
                        <div className="text">
                          We offer a range of services including domestic and international deliveries, door-to-door service, temperature-controlled shipping, and bulk shipments.
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* Block 2 */}
                  <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                    <div className="acc-btn" onClick={() => toggleAccordion(1)}>
                      How long does it take for Velocity Express to deliver packages in Bangalore?
                      <div className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`}></div>
                    </div>
                    <div className={`acc-content ${activeIndex === 1 ? 'current' : ''}`}>
                      <div className="content">
                        <div className="text">
                          Delivery times depend on the service and destination. Local deliveries within Bangalore are typically fast, while international shipments may take longer.
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* Block 3 */}
                  <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                    <div className="acc-btn" onClick={() => toggleAccordion(2)}>
                      Does Velocity Express offer door-to-door courier service in Bangalore?
                      <div className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`}></div>
                    </div>
                    <div className={`acc-content ${activeIndex === 2 ? 'current' : ''}`}>
                      <div className="content">
                        <div className="text">
                          Yes, we provide convenient door-to-door delivery services in Bangalore, picking up and delivering packages directly to the recipient's address.
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* Block 4 */}
                  <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                    <div className="acc-btn" onClick={() => toggleAccordion(3)}>
                      Can I send perishable items through Velocity Express in Bangalore?
                      <div className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`}></div>
                    </div>
                    <div className={`acc-content ${activeIndex === 3 ? 'current' : ''}`}>
                      <div className="content">
                        <div className="text">
                          Yes, we offer temperature-controlled shipping for perishable items, ensuring they arrive fresh and in perfect condition.
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* Block 5 */}
                  <li className={`accordion block ${activeIndex === 4 ? 'active-block' : ''}`}>
                    <div className="acc-btn" onClick={() => toggleAccordion(4)}>
                      How can I track my shipment with Velocity Express?
                      <div className={`icon fa ${activeIndex === 4 ? 'fa-minus' : 'fa-plus'}`}></div>
                    </div>
                    <div className={`acc-content ${activeIndex === 4 ? 'current' : ''}`}>
                      <div className="content">
                        <div className="text">
                          You can track your shipment in real-time using our easy-to-use tracking platform. This allows you to monitor your package every step of the way.
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer/>

        <script src="{% static 'js/phonecode/js/intlTelInput-jquery.min.js' %}" ></script>
      </>
    </div>
  )
}

export default TestPage
