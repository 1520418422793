import React , { useState, useEffect } from 'react'
import axios from 'axios';

export default function useEstimate ({ setIsFlipped }) {
    const [isVisible, setIsVisible] = useState(false);
    const [pincodes, setPincodes] = useState([]);
    const [origin, setOrigin] = useState("-1"); //{ district: '', pincode: '' }
    const [destination, setDestination] = useState("-1");
    const [weight, setWeight] = useState("");
    const [service, setService] = useState("Regular");
    const [weightunit, setWeightunit] = useState("kg");
    const [producttype, setProducttype] = useState("parcels");
    const [errors, setErrors] = useState({ common: "" });
    const [loading, setLoading] = useState(false);
    const [fromCity, setFromCity] = useState('');
    const [fromPincode, setFromPincode] = useState('');
    const [toCity, setToCity] = useState('');
    const [toPincode, setToPincode] = useState('');
    const [serviceLevel, setServiceLevel] = useState('');
    const [amount, setAmount] = useState('');
    const [displayWeight, setDisplayWeight] = useState("");

    // Helper function to clear errors after a delay
    const clearErrorAfterTimeout = (field) => {
        setTimeout(() => {
            setErrors(prev => ({ ...prev, [field]: "" }));
        }, 3000); // Clears the error after 3 seconds
    };

    // Function to toggle visibility
    const handleEstimateClick = async (event) => {
        event.preventDefault(); // Prevents the default anchor behavior (e.g., navigation)      

        // Regular expression to match valid integers or float numbers   
        const isValidNumber = /^[0-9]*\.?[0-9]+$/;
        let isValid = true;

        if (origin === "-1") {
            setErrors(prev => ({ ...prev, origin: "Select City / Pincode" }));
            clearErrorAfterTimeout('origin');
            isValid = false;
        }

        if (destination === "-1") {
            setErrors(prev => ({ ...prev, destination: "Select City / Pincode" }));
            clearErrorAfterTimeout('destination');
            isValid = false;
        }

        if (weight === "") {
            setErrors(prev => ({ ...prev, weight: "Enter weight." }));
            clearErrorAfterTimeout('weight');
            isValid = false;
        } else if (!isValidNumber.test(weight)) {
            setErrors(prev => ({ ...prev, weight: "Please enter a valid number (integer or float)." }));
            clearErrorAfterTimeout('weight');
            isValid = false;
        }

        if (producttype !== "parcels") {
            setErrors(prev => ({ ...prev, producttype: "Product type is not serviceable" }));
            clearErrorAfterTimeout('producttype');
            isValid = false;
        }

        // If all validations pass, set visibility to true       
        if (isValid) {
            setLoading(true);           

            try {
                const convertedWeight = weightunit === 'kg' ? parseFloat(weight) * 1000 : parseFloat(weight);

                // API call
                const response = await axios.post('http://192.168.1.222:8000/get_rate/', {
                    first_mile_city: origin.pincode,
                    last_mile_pincode: destination.pincode,
                    service_level: service,
                    weight : convertedWeight,
                    product_type : producttype
                });
                setIsFlipped(true);

                // Setting state with the API response
                setFromCity(response.data.FM_CITY);
                setFromPincode(response.data.FM_PINCODE);
                setToCity(response.data.LM_CITY);
                setToPincode(response.data.LM_PINCODE);
                setAmount(response.data.shipping_rate); // Assuming `amount` is a field in the response
                setDisplayWeight(parseFloat(response.data.weight/1000));
                setServiceLevel(response.data.service_level);
                setIsVisible(true);

            } catch (error) {
                console.error('Error fetching estimate:', error);
                setErrors(prev => ({ ...prev, common: "Failed to fetch estimate. Please try again." }));
            } finally {
                setLoading(false);
            }
        }
    };

    return {
        isVisible,
        setIsVisible,
        pincodes,
        setPincodes,
        origin,
        setOrigin,
        destination,
        setDestination,
        weight,
        setWeight,
        service,
        setService,
        weightunit,
        setWeightunit,
        producttype,
        setProducttype,
        errors,
        setErrors,
        handleEstimateClick,
        fromCity,
        fromPincode,
        toCity,
        toPincode,
        amount,
        serviceLevel,
        displayWeight,
    };
}
